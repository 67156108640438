import { addAllSlotHighlights, addSlotHighlight } from 'ad-framework/highlight/slots';
import DataObjectStore from 'state/data-object-store';
import { ApiMachineEventService } from 'api/events.service';
import { Slot } from 'ad-framework/slot/index.types';

export default async (service: ApiMachineEventService, names?: Array<string>): Promise<void> => {
  const slots = await service.getData<DataObjectStore<Slot>>('slots');
  if (names === undefined) {
    addAllSlotHighlights(slots);
    return;
  }

  names.forEach(name => {
    addSlotHighlight(slots, name);
  });
};
