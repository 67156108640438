import { UserSyncElement } from 'user-sync/index.types';
import { getUserSyncURL, getCacheBuster } from './utils';
import { UserSyncConfig } from '../../state/types/context.types';

const id = '2';
const casalemedia: UserSyncConfig = {
  id,
  bidderName: 'castlemedia',
  iabIdList: [10],
  element: UserSyncElement.IFRAME,
  url: 'https://ssum-sec.casalemedia.com/usermatch',
  params: {
    r: getCacheBuster(),
    s: '181869',
    cb: getUserSyncURL({ id }),
  },
};
export default casalemedia;
