import createSlot from 'ad-framework/slot';
import { SlotDefinition } from 'ad-framework/slot/index.types';
import {
  EVENTS,
  SlotHookFailedEvent,
  SlotHookedEvent,
  StaticSlotsDoneEvent,
} from 'slotify/index.types';
import selectSlotHooks from 'slotify/selection';
import { EventObject, fromCallback } from 'xstate';

const staticSlotGenerator = fromCallback<
  EventObject,
  {
    slotDefinitions: Array<SlotDefinition>;
  }
>(({ input: { slotDefinitions }, sendBack }) => {
  slotDefinitions.forEach((slotDefinition, slotDefinitionIndex) => {
    const hookElements = selectSlotHooks(slotDefinition);
    if (hookElements.length === 0) {
      sendBack({
        type: EVENTS.SLOT_HOOK_FAILED,
        data: slotDefinition,
      } as SlotHookFailedEvent);
    } else {
      hookElements.forEach((hookElement, hookElementIndex) => {
        const id = `bordeaux-slot_static_${slotDefinitionIndex}-hook_${hookElementIndex}`;
        const slot = createSlot(slotDefinition, {
          id,
          hookElement,
          ...(slotDefinition.multiple
            ? {
                name: `${slotDefinition.name}-${hookElementIndex}`,
              }
            : {}),
        });
        sendBack({
          type: EVENTS.SLOT_CREATED,
          data: slot,
        } as SlotHookedEvent);
      });
    }
  });
  sendBack({
    type: EVENTS.STATIC_SLOTS_DONE,
  } as StaticSlotsDoneEvent);
});

export default staticSlotGenerator;
