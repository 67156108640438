/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import { hasUserConsentedVendorGDPR } from 'utils/cmp';
import TimeoutError from 'utils/error/timeout';
import log from 'log';
import { assign, fromPromise, sendParent, setup } from 'xstate';
import PartialPick from 'utils/partial-pick.types';
import { ThirdPartyAPIMachineContext } from 'state/types/context.types';
import { timeout, timeoutMessage } from './utils/timeout';
import { ThirdParty } from './config.types';
import { ACTIONS, APISetupResult, GUARDS, STATES } from './index.types';
import { ThirdPartyEvent } from './events.names';
import {
  ThirdPartyFailureEvent,
  ThirdPartyRequestEvent,
  ThirdPartySuccessEvent,
  ThirdPartyTimeoutEvent,
} from './events.types';

const timeoutScript = (thirdparty: ThirdParty): Promise<void> =>
  new Promise((_resolve, reject) => {
    setTimeout(() => {
      reject(new TimeoutError(`${thirdparty}: ${timeoutMessage} [${timeout}ms]`));
    }, timeout);
  });

const loadScript = fromPromise<void, { context: ThirdPartyAPIMachineContext }>(
  ({ input }): Promise<void> =>
    Promise.race([
      input.context.data.methods.loadScript &&
        (input.context.data.methods.loadScript as Function)(
          input.context.data.scriptLocation,
          input.context,
        ),
      timeoutScript(input.context.data.thirdParty),
    ]),
);

export const setupThirdPartyAPI = setup({
  types: {} as {
    context: ThirdPartyAPIMachineContext;
    input: {
      thirdPartyMethods: PartialPick<ThirdPartyAPIMachineContext['data'], 'thirdParty'>;
      bordeaux: ThirdPartyAPIMachineContext['bordeaux'];
    };
    output: APISetupResult;
  },
  guards: {
    [GUARDS.NOT_ENABLED]: ({
      context: {
        data: { config },
      },
    }) => !config.enabled,
    [GUARDS.NO_CONSENT]: ({ context: { consent } }) => !consent,
    [GUARDS.NO_SCRIPT]: ({
      context: {
        data: { methods, scriptLocation },
      },
    }) => !scriptLocation || !methods.loadScript,
  },
  actions: {
    [ACTIONS.GET_CONFIG]: assign({
      data: ({ context }) => ({
        ...context.data,
        config: context.data.methods.getConfig
          ? (context.data.methods.getConfig as Function)(context)
          : context.bordeaux.thirdPartyApiConfig[context.data.thirdParty],
      }),
    }),
    [ACTIONS.GET_CONSENT]: assign({
      consent: ({
        context: {
          bordeaux,
          data: { config },
        },
      }) =>
        !('consentVendor' in config) ||
        config.consentVendor === undefined ||
        hasUserConsentedVendorGDPR(bordeaux.gdprConsent, config.consentVendor),
    }),
    [ACTIONS.GET_SCRIPT_LOCATION]: assign({
      data: ({ context }) => ({
        ...context.data,
        scriptLocation: context.data.methods.getScriptLocation
          ? (context.data.methods.getScriptLocation as Function)(context)
          : context.data.scriptLocation,
      }),
    }),
    [ACTIONS.MARK_SUCCESS]: assign({
      success: true,
    }),
    [ACTIONS.SEND_REQUEST_EVENT]: sendParent(
      ({
        context: {
          data: { thirdParty },
        },
      }): ThirdPartyRequestEvent => ({
        type: ThirdPartyEvent.THIRD_PARTY_REQUEST,
        data: thirdParty,
      }),
    ),
    [ACTIONS.SEND_SUCCESS_EVENT]: sendParent(
      ({
        context: {
          data: { thirdParty },
        },
      }): ThirdPartySuccessEvent => ({
        type: ThirdPartyEvent.THIRD_PARTY_SUCCESS,
        data: thirdParty,
      }),
    ),
    [ACTIONS.SEND_FAILURE_EVENT]: sendParent(
      ({
        context: {
          data: { thirdParty },
        },
        event,
      }): ThirdPartyFailureEvent | ThirdPartyTimeoutEvent => ({
        type:
          event.data instanceof TimeoutError
            ? ThirdPartyEvent.THIRD_PARTY_TIMEOUT
            : ThirdPartyEvent.THIRD_PARTY_FAILURE,
        data: thirdParty,
      }),
    ),
  },
  actors: {
    loadScript,
  },
}).createMachine({
  initial: STATES.START,
  context: ({ input }) => ({
    data: {
      config: {},
      methods: {},
      ...input.thirdPartyMethods,
    } as ThirdPartyAPIMachineContext['data'],
    consent: false,
    scriptLocation: undefined,
    success: false,
    bordeaux: input.bordeaux,
  }),
  output: ({
    context: {
      data: { thirdParty, config, scriptLocation },
      consent,
      success,
    },
  }): APISetupResult => ({
    thirdParty,
    config,
    consent,
    scriptLocation,
    success,
  }),
  states: {
    [STATES.START]: {
      entry: [ACTIONS.GET_CONFIG, ACTIONS.GET_CONSENT, ACTIONS.GET_SCRIPT_LOCATION],
      always: [
        {
          guard: GUARDS.NOT_ENABLED,
          target: STATES.DONE,
        },
        {
          guard: GUARDS.NO_CONSENT,
          target: STATES.DONE,
        },
        {
          guard: GUARDS.NO_SCRIPT,
          actions: ACTIONS.MARK_SUCCESS,
          target: STATES.DONE,
        },
        {
          target: STATES.LOAD,
        },
      ],
    },
    [STATES.LOAD]: {
      entry: ACTIONS.SEND_REQUEST_EVENT,
      invoke: {
        src: 'loadScript',
        input: ({ context }) => ({ context }),
        onDone: {
          actions: [ACTIONS.SEND_SUCCESS_EVENT, ACTIONS.MARK_SUCCESS],
          target: STATES.DONE,
        },
        onError: {
          actions: [({ event }) => log.warn(event.error), ACTIONS.SEND_FAILURE_EVENT],
          target: STATES.DONE,
        },
      },
    },
    [STATES.DONE]: {
      type: 'final',
    },
  },
});
