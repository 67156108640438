import { BordeauxMachineContext, UserSyncConfig } from '../../state/types/context.types';
import { UserSyncElement } from '../index.types';
import compileUrl from './compile-url';
import { createElement } from './create-element';

const elements = {
  [UserSyncElement.IFRAME]: 'iframe',
  [UserSyncElement.IMAGE]: 'img',
};

/**
 * Creates multiple elements for each UserSyncPixelConfig as required
 *
 * May create:
 *   A script element
 *   A pixel element
 *   A container element
 *
 * @param context
 * @param userSyncPixel
 * @returns [ scriptELement?, (container | element)? ]
 */
const createElements = (
  context: BordeauxMachineContext,
  userSyncPixel: UserSyncConfig,
): Array<HTMLElement> => {
  if (userSyncPixel.condition) {
    if (!userSyncPixel.condition(context)) {
      return [];
    }
  }
  const returnElements: Array<HTMLElement> = [];
  if ('script' in userSyncPixel && userSyncPixel.script) {
    returnElements.push(createElement('script', userSyncPixel.script));
  }

  if ('element' in userSyncPixel) {
    const element = createElement(elements[userSyncPixel.element], {
      src: compileUrl(context, userSyncPixel),
      ...(userSyncPixel.element === UserSyncElement.IFRAME &&
      'iframe' in userSyncPixel &&
      userSyncPixel.iframe
        ? userSyncPixel.iframe
        : {}),
    });
    returnElements.push(element);
  }
  return returnElements;
};
export default createElements;
