import { SommelierResponseSlot } from 'ad-framework/slot/sommelier-response.types';

import { SlotLabelPosition, SlotPosition, SlotType } from 'ad-framework/slot/index.types';
import { BACKGROUND, MOBILE_MPU_HEIGHT, MPU_WIDTH, SINGLE_MPU_HEIGHT } from '../../constants';

export const paragraphMPUSlots: SommelierResponseSlot = {
  name: `paragraph`,
  type: SlotType.BLOCK,
  hook: '#article-body > p, .article__body > p',
  position: SlotPosition.AFTER,
  size: { w: MPU_WIDTH, h: MOBILE_MPU_HEIGHT },
  properties: {
    label: {
      position: SlotLabelPosition.ABOVE,
      text: 'Advertisement',
    },
    style: {
      marginBottom: '20px',
      backgroundColor: BACKGROUND,
    },
    config: {
      multiple: true,
    },
  },
};

export const headingMPUSlots: SommelierResponseSlot = {
  name: `mpu-heading`,
  type: SlotType.BLOCK,
  hook: '#article-body > h3, #article-body > h2, .article__body > h3, .article__body > h2',
  position: SlotPosition.BEFORE,
  size: { w: MPU_WIDTH, h: MOBILE_MPU_HEIGHT },
  properties: {
    label: {
      position: SlotLabelPosition.ABOVE,
      text: 'Advertisement',
    },
    style: {
      marginBottom: '20px',
      backgroundColor: BACKGROUND,
    },
    config: {
      multiple: true,
    },
  },
};

export const mpu1Slot: SommelierResponseSlot = {
  name: 'mpu1',
  hook: '#ad-unit-1',
  type: SlotType.BLOCK,
  position: SlotPosition.PREPEND,
  size: {
    w: MPU_WIDTH,
    h: MOBILE_MPU_HEIGHT,
  },
  properties: {
    label: {
      position: SlotLabelPosition.ABOVE,
      text: 'Advertisement',
    },
    style: {
      backgroundColor: BACKGROUND,
    },
  },
};

export const mpu2Slot: SommelierResponseSlot = {
  name: 'mpu2',
  hook: '#ad-unit-2',
  type: SlotType.BLOCK,
  position: SlotPosition.PREPEND,
  size: {
    w: MPU_WIDTH,
    h: MOBILE_MPU_HEIGHT,
  },
  properties: {
    label: {
      text: 'Advertisement',
      position: SlotLabelPosition.ABOVE,
    },
    style: {
      backgroundColor: BACKGROUND,
    },
  },
};

export const staticMPUSlots: SommelierResponseSlot = {
  name: `static-mpu`,
  type: SlotType.BLOCK,
  hook: '.static-leaderboard',
  position: SlotPosition.APPEND,
  size: { w: MPU_WIDTH, h: SINGLE_MPU_HEIGHT },
  properties: {
    label: {
      position: SlotLabelPosition.ABOVE,
      text: 'Advertisement',
    },
    style: {
      backgroundColor: BACKGROUND,
    },
    config: {
      multiple: true,
    },
  },
};
