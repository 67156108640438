// Test via a getter in the options object to see if the passive property is accessed
import { getEnv } from 'utils/env';

const env = getEnv();

let supportsPassive = false;
try {
  const opts: Record<string, boolean> = Object.defineProperty({}, 'passive', {
    get() {
      supportsPassive = true;
    },
  });
  env.addEventListener('test', null as unknown as EventListenerOrEventListenerObject, opts);
} catch (_error) {
  // continue regardless of error
}

const output = supportsPassive;

export default output;
