import log from 'log';
import { HybridBaseRequest } from 'utils/ad-server/request.types';
import sommelierConfigRequest from 'utils/ad-server/sommelier-request';
import { getEnv } from 'utils/env';
import { getScreenResolution } from 'utils/ad-server/utils';
import getParam from 'utils/get-param';
import { BordeauxMachineContext } from 'state/types/context.types';
import { DeviceOptions } from 'parameters/index.types';
import ActionArgs from 'state/proxy/action-args.types';
import { fromPromise } from 'xstate';
import { SommelierResponse } from 'utils/ad-server/sommelier-request/sommelier-response.types';
import { BordeauxConfig, configSchema } from './zod-schema';

const SOMMELIER_URL = getParam('sommelier_url');
const SOMMELIER_URL_LIVE = 'live';
const env = getEnv();

const createSommelierConfigRequest = (
  context: Pick<BordeauxMachineContext, 'pageTemplate'>,
): HybridBaseRequest => {
  const pageTemplate = context.pageTemplate ?? '';
  let pageUrl = env.location.href;
  if (SOMMELIER_URL === SOMMELIER_URL_LIVE) {
    pageUrl = pageUrl
      .replace(/\.[a-z0-9-]+\.van-qa\.futureplc\.engineering/, '')
      .replace(/(www\.)?local\.responsive\./, 'www.')
      .replace(/(www\.)?local\.progressive\./, 'www.');
  } else if (SOMMELIER_URL) {
    pageUrl = SOMMELIER_URL;
  }
  return sommelierConfigRequest()
    .setTemplateName(pageTemplate)
    .setScreenWidth(getScreenResolution().width)
    .setLocation(encodeURIComponent(pageUrl));
};

const fallback = (context: BordeauxMachineContext): SommelierResponse => {
  let fallbackResponse: SommelierResponse;
  switch (context.pageParameters.device) {
    case DeviceOptions.DESKTOP:
      fallbackResponse = context.fallbackResponses.desktop();
      break;
    case DeviceOptions.TABLET:
      fallbackResponse = context.fallbackResponses.tablet();
      break;
    case DeviceOptions.MOBILE:
      fallbackResponse = context.fallbackResponses.mobile();
      break;
    default:
      log.error(
        `No config fallback available for device: ${context.pageParameters.device}. Using desktop config.`,
      );
      fallbackResponse = context.fallbackResponses.desktop();
      break;
  }

  log.warn(`Using fallback config: ${fallbackResponse?.placement?.id || '(No ID)'}`);
  return fallbackResponse;
};

export const fallbackAction = ({ context }: ActionArgs): SommelierResponse => {
  return fallback(context);
};

export const parsedFallback = ({ context }: ActionArgs): BordeauxConfig => {
  const parsedFallback = configSchema.safeParse(fallback(context));
  return parsedFallback.success ? parsedFallback.data : ({} as BordeauxConfig);
};

export const fetchConfig = fromPromise<
  SommelierResponse,
  Pick<BordeauxMachineContext, 'pageTemplate'>
>(({ input }): Promise<SommelierResponse> => createSommelierConfigRequest(input).execute());

export const parseConfig = fromPromise<
  BordeauxConfig,
  Pick<BordeauxMachineContext, 'sommelierResponse'>
>(async ({ input }): Promise<BordeauxConfig> => {
  const parsedConfig = configSchema.safeParse(input.sommelierResponse);

  if (parsedConfig.success !== true) {
    throw new Error(parsedConfig.error.message);
  }

  Object.freeze(parsedConfig.data);
  return parsedConfig.data;
});

export const checkConfig = fromPromise<
  void,
  {
    sommelierResponse: BordeauxMachineContext['sommelierResponse'];
  }
>(async ({ input }): Promise<void> => {
  if (!input.sommelierResponse.placement) {
    throw new Error('Sommelier config empty');
  }
});
