import { AdUnitList } from 'ad-framework/ad-list-group/index.types';

import { SlotListGroup } from 'ad-framework/slot-list-group/index.types';
import { AdditionalAvoidance, Slot } from 'ad-framework/slot/index.types';
import log from 'log/index';
import { PAGE_STYLE_CONSTANTS } from 'state/types/index.types';
import querySelectorAll from 'utils/query-selector-all';
import {
  AnyActorRef,
  EventObject,
  assign,
  enqueueActions,
  fromCallback,
  fromPromise,
  raise,
  sendParent,
  sendTo,
  setup,
} from 'xstate';
import positionElement from 'ad-framework/slot/position-element';
import fastdom from 'utils/fastdom';
import { FEATURE } from 'api/feature';
import DataObject from 'state/data-object';
import { EVENTS as BDX_EVENTS } from 'state/types/events.names';
import replaceConstantsInSlot from 'ad-framework/slot/replace-constants';
import { BATCH_FIRST } from 'ad-framework/ad/index.types';
import {
  ACTIONS,
  AD_AFFINITY_FAILED_REASONS,
  AdBatchEvent,
  AdMatchesEvent,
  AnySlotifyEvent,
  EVENTS,
  GUARDS,
  INCREMENTAL_ADS_EVENTS_IN,
  SLOTIFY_EVENTS_OUT,
  STANDARD_ADS_EVENTS_IN,
  STATES,
  SlotInViewEvent,
  SlotifyMachineContext,
} from './index.types';
import { watchExclusionZones } from './exclusion';
import SlotObserver from './observer';
import staticSlotGenerator from './generator/static-slots';
import dynamicSlotGenerator from './generator/dynamic-slots';
import affinityAdGenerator from './generator/affinity-ads';

const addAdditionalWatchers = (slot: DataObject<Slot>): void => {
  const additionalAvoidance = slot.getProperty('additionalAvoidance');

  additionalAvoidance.forEach((additionalAvoidanceConfig: AdditionalAvoidance) => {
    const elementsToAvoid = querySelectorAll<HTMLElement>(additionalAvoidanceConfig.hook);
    elementsToAvoid.forEach(element => {
      additionalAvoidanceConfig.elements.push(element);
    });
  });
};

const slotWatcher = fromCallback<
  EventObject,
  {
    slot: DataObject<Slot>;
    activationDistance: number;
  }
>(({ input: { slot, activationDistance }, sendBack }) => {
  const element = slot.getProperty('element');

  const slotBuffer =
    slot.getProperty('genericName') === 'sponsored'
      ? slot.getProperty('sponsoredSlotActivationDistanceOverride') || activationDistance
      : activationDistance;

  const slotName = slot.getProperty('name');

  const slotObserver = new SlotObserver(element, slotName, slotBuffer);

  slotObserver.observe(event => {
    if (event.isIntersecting && event.scrollPosition !== -1) {
      sendBack({
        type: EVENTS.SLOT_IN_VIEW,
        data: slot,
      } as SlotInViewEvent);
    }
  });
});

const waitForSlotsReady = fromPromise<void, { slots: Array<DataObject<Slot>> }>(
  async ({ input }) => {
    await Promise.all(input.slots.map(slot => slot.getProperty('readyPromise')));
  },
);

export default setup({
  types: {} as {
    context: SlotifyMachineContext;
    events: AnySlotifyEvent;
    input: {
      slotDefinitions: SlotListGroup;
      adDefinitions: AdUnitList;
      pageStyleConstants: Record<PAGE_STYLE_CONSTANTS, string>;
      features: Record<FEATURE, boolean>;
      pageAdUnitPath: string;
      automaticDynamic: boolean;
      activationDistance: number;
      avoidanceDistance: number;
      isRoadblock: boolean | null;
    };
  },
  actors: {
    staticSlotGenerator,
    dynamicSlotGenerator,
    affinityAdGenerator,
    slotWatcher,
    waitForSlotsReady,
  },
  guards: {
    [GUARDS.STANDARD_ADS_ENABLED]: ({ context: { features } }) => features[FEATURE.ADS_STANDARD],
    [GUARDS.INCREMENTAL_ADS_ENABLED]: ({ context: { features, isRoadblock } }) =>
      features[FEATURE.ADS_INCREMENTAL] && isRoadblock !== null,
  },
  actions: {
    [ACTIONS.REPORT_AD_AFFINITY_FAILED]: ({ event, context }) => {
      if (event.type !== EVENTS.AD_AFFINITY_FAILED) return;
      const { adDefinition, reason } = event.data;
      switch (reason) {
        case AD_AFFINITY_FAILED_REASONS.ABSENT:
          log.error(
            `Slotify standard handling error - Affinity undefined for ${adDefinition.name}`,
          );
          break;
        case AD_AFFINITY_FAILED_REASONS.NO_SLOT: {
          const slotDefinition = context.slotDefinitions.static.find(
            searchSlot => searchSlot.name === adDefinition.affinitySlotID,
          );
          if (slotDefinition) {
            if (!slotDefinition.ignoreErrors) {
              log.error(
                `The ad unit '${adDefinition.name}' has a slot affinity '${adDefinition.affinitySlotID}' but the slot could not be created.`,
              );
            }
          } else {
            log.error(
              `The ad unit '${adDefinition.name}' has a slot affinity '${adDefinition.affinitySlotID}' but no matching slot was configured.`,
            );
          }
          break;
        }
        case AD_AFFINITY_FAILED_REASONS.SLOT_FILLED:
          log.error(
            `Slotify standard handling error - Slot already filled for ${adDefinition.name}`,
          );
          break;
        default:
          log.error(
            `The ad unit '${adDefinition.name}' has a slot affinity '${adDefinition.affinitySlotID}' but something went wrong.`,
          );
      }
    },
    [ACTIONS.RAISE_ENABLE_INCREMENTAL_ADS]: raise({ type: EVENTS.INCREMENTAL_ADS_ENABLED }),
    [ACTIONS.RAISE_ENABLE_STANDARD_ADS]: raise({ type: STANDARD_ADS_EVENTS_IN.ENABLED }),
    [ACTIONS.ENABLE_INCREMENTAL_ADS]: assign({
      features: ({ context: { features } }) => ({
        ...features,
        [FEATURE.ADS_INCREMENTAL]: true,
      }),
    }),
    [ACTIONS.ENABLE_STANDARD_ADS]: assign({
      features: ({ context: { features } }) => ({
        ...features,
        [FEATURE.ADS_STANDARD]: true,
      }),
    }),
    [ACTIONS.CHECK_ROADBLOCK_STATUS]: sendParent({ type: BDX_EVENTS.CHECK_ROADBLOCK_STATUS }),
    [ACTIONS.UPDATE_ROADBLOCK_STATUS]: assign({
      isRoadblock: ({ event, context }) =>
        event.type === INCREMENTAL_ADS_EVENTS_IN.ROADBLOCK_STATUS
          ? event.data
          : context.isRoadblock,
    }),

    [ACTIONS.POSITION_SLOT_ELEMENT]: ({ event }) => {
      if (event.type !== EVENTS.SLOT_CREATED) return;
      event.data.update({
        readyPromise: fastdom.mutate(() => {
          positionElement(
            event.data.getProperty('element'),
            event.data.getProperty('position'),
            event.data.getProperty('hookElement'),
          );
        }),
      });
    },
    [ACTIONS.ADD_SLOT]: assign({
      slots: ({ context: { slots }, event }) =>
        event.type === EVENTS.SLOT_CREATED ? [...slots, event.data] : slots,
    }),
    [ACTIONS.CREATE_ADDITIONAL_SLOT_WATCHERS]: ({ event }) => {
      if (event.type !== EVENTS.SLOT_CREATED) return;
      addAdditionalWatchers(event.data);
    },
    [ACTIONS.CREATE_SLOT_WATCHER]: assign({
      slotWatchers: ({ context, spawn, event }) =>
        event.type === EVENTS.SLOT_CREATED
          ? [
              ...context.slotWatchers,
              spawn('slotWatcher', {
                input: {
                  slot: event.data,
                  activationDistance: context.activationDistance,
                },
              }),
            ]
          : context.slotWatchers,
    }),
    [ACTIONS.REPORT_NEW_SLOT]: sendParent(({ event }) =>
      event.type === EVENTS.SLOT_CREATED
        ? {
            type: SLOTIFY_EVENTS_OUT.NEW_SLOT,
            data: event.data,
          }
        : {
            type: SLOTIFY_EVENTS_OUT.CONTEXT_ERROR,
            data: ACTIONS.REPORT_NEW_SLOT,
          },
    ),
    [ACTIONS.REPORT_SLOT_HOOK_FAILED]: ({ event }) => {
      if (event.type !== EVENTS.SLOT_HOOK_FAILED) return;
      const slotDefinition = event.data;
      if (slotDefinition.ignoreErrors) return;
      if (slotDefinition.multiple) {
        log.error(
          `Static slot ${slotDefinition.name} could not find any elements with hook '${slotDefinition.hook}'.`,
        );
      } else {
        log.error(
          `Static slot ${slotDefinition.name} could not find an element with hook '${slotDefinition.hook}'.`,
        );
      }
    },
    [ACTIONS.MARK_INCREMENTALS_STARTED]: assign({
      incrementalsStarted: true,
    }),
    [ACTIONS.CREATE_DYNAMIC_SLOT_GENERATOR]: assign({
      slotGenerator: ({ spawn, context }) =>
        spawn('dynamicSlotGenerator', {
          id: 'dynamicSlotMachine',
          input: {
            generatedSlotDefinitions: context.slotDefinitions.generated.map(
              replaceConstantsInSlot(context.pageStyleConstants),
            ),
            dynamicSlotDefinitions: context.slotDefinitions.dynamic.map(
              replaceConstantsInSlot(context.pageStyleConstants),
            ),
            automaticDynamic: context.automaticDynamic,
          },
        }),
    }),
    [ACTIONS.WATCH_EXCLUSION_ZONES]: watchExclusionZones,
  },
}).createMachine({
  id: 'slotify',
  context: ({ input }) => ({
    slotDefinitions: input.slotDefinitions,
    adDefinitions: input.adDefinitions,
    pageStyleConstants: input.pageStyleConstants,
    pageAdUnitPath: input.pageAdUnitPath,
    features: input.features,
    automaticDynamic: input.automaticDynamic,
    activationDistance: input.activationDistance,
    avoidanceDistance: input.avoidanceDistance,
    isRoadblock: input.isRoadblock,
    incrementalsStarted: false,
    slots: [],
    slotWatchers: [],
    slotBatch: [],
    slotGenerator: {} as AnyActorRef,
  }),
  initial: STATES.CREATING_STATIC_SLOTS,
  states: {
    [STATES.CREATING_STATIC_SLOTS]: {
      invoke: {
        src: 'staticSlotGenerator',
        input: ({ context }) => ({
          slotDefinitions: context.slotDefinitions.static.map(
            replaceConstantsInSlot(context.pageStyleConstants),
          ),
        }),
      },
      on: {
        [EVENTS.STATIC_SLOTS_DONE]: {
          target: STATES.WAIT_FOR_STATIC_SLOTS_READY,
        },
      },
    },
    [STATES.WAIT_FOR_STATIC_SLOTS_READY]: {
      invoke: {
        src: 'waitForSlotsReady',
        input: ({ context }) => ({ slots: context.slots }),
        onDone: {
          target: STATES.WAIT_FOR_STANDARD_ADS_ENABLED,
        },
      },
    },
    [STATES.WAIT_FOR_STANDARD_ADS_ENABLED]: {
      entry: enqueueActions(({ enqueue, check }) => {
        if (check(GUARDS.STANDARD_ADS_ENABLED)) {
          enqueue(ACTIONS.RAISE_ENABLE_STANDARD_ADS);
        }
      }),
      on: {
        [STANDARD_ADS_EVENTS_IN.ENABLED]: {
          target: STATES.YIELDING_STATIC_AFFINITY_ADS,
        },
      },
    },
    [STATES.YIELDING_STATIC_AFFINITY_ADS]: {
      invoke: {
        src: 'affinityAdGenerator',
        input: ({ context }) => ({
          adDefinitions: context.adDefinitions,
          slots: context.slots,
        }),
      },
      on: {
        [EVENTS.ADS_MATCH]: {
          actions: [
            sendParent(
              ({ event: { data } }) =>
                ({
                  type: SLOTIFY_EVENTS_OUT.AD_MATCHES,
                  data: data.map(({ adDefinition, slot }) => ({
                    slot,
                    adDefinition,
                    batch: BATCH_FIRST,
                  })),
                }) as AdMatchesEvent,
            ),
            sendParent({
              type: SLOTIFY_EVENTS_OUT.AD_BATCH,
            } as AdBatchEvent),
          ],
          target: STATES.WAIT_FOR_INCREMENTAL_ADS_ENABLED,
        },
      },
    },
    [STATES.WAIT_FOR_INCREMENTAL_ADS_ENABLED]: {
      entry: enqueueActions(({ enqueue, check }) => {
        if (check(GUARDS.INCREMENTAL_ADS_ENABLED)) {
          enqueue(ACTIONS.RAISE_ENABLE_INCREMENTAL_ADS);
        } else {
          enqueue(ACTIONS.CHECK_ROADBLOCK_STATUS);
        }
      }),
      on: {
        [INCREMENTAL_ADS_EVENTS_IN.ENABLED]: {
          actions: [
            ACTIONS.ENABLE_INCREMENTAL_ADS,
            enqueueActions(({ enqueue, check }) => {
              if (check(GUARDS.INCREMENTAL_ADS_ENABLED)) {
                enqueue(ACTIONS.RAISE_ENABLE_INCREMENTAL_ADS);
              } else {
                enqueue(ACTIONS.CHECK_ROADBLOCK_STATUS);
              }
            }),
          ],
        },
        [INCREMENTAL_ADS_EVENTS_IN.ROADBLOCK_STATUS]: {
          actions: [
            ACTIONS.UPDATE_ROADBLOCK_STATUS,
            enqueueActions(({ enqueue, check }) => {
              if (check(GUARDS.INCREMENTAL_ADS_ENABLED)) {
                enqueue(ACTIONS.RAISE_ENABLE_INCREMENTAL_ADS);
              } else {
                enqueue(ACTIONS.CHECK_ROADBLOCK_STATUS);
              }
            }),
          ],
        },
        [EVENTS.INCREMENTAL_ADS_ENABLED]: [
          // ADP-13056 Incrementals should continue when roadblocked to allow a limited number to be generated
          // {
          //   guard: ({ context: { isRoadblock } }) => Boolean(isRoadblock),
          //   target: STATES.DONE,
          // },
          {
            target: STATES.WATCHING_INCREMENTAL_SLOTS,
          },
        ],
      },
    },
    [STATES.WATCHING_INCREMENTAL_SLOTS]: {
      entry: [
        ACTIONS.MARK_INCREMENTALS_STARTED,
        ACTIONS.CREATE_DYNAMIC_SLOT_GENERATOR,
        ACTIONS.WATCH_EXCLUSION_ZONES,
      ],
    },
    [STATES.DONE]: {},
  },
  on: {
    [EVENTS.SLOT_HOOK_FAILED]: {
      actions: ACTIONS.REPORT_SLOT_HOOK_FAILED,
    },
    [EVENTS.SLOT_CREATED]: {
      actions: [
        ACTIONS.ADD_SLOT,
        ACTIONS.POSITION_SLOT_ELEMENT,
        ACTIONS.CREATE_ADDITIONAL_SLOT_WATCHERS,
        ACTIONS.CREATE_SLOT_WATCHER,
        ACTIONS.REPORT_NEW_SLOT,
      ],
    },
    [STANDARD_ADS_EVENTS_IN.ENABLED]: {
      actions: ACTIONS.ENABLE_STANDARD_ADS,
    },
    [INCREMENTAL_ADS_EVENTS_IN.ENABLED]: {
      actions: ACTIONS.ENABLE_INCREMENTAL_ADS,
    },
    [EVENTS.AD_AFFINITY_FAILED]: {
      actions: ACTIONS.REPORT_AD_AFFINITY_FAILED,
    },
    [EVENTS.SLOT_IN_VIEW]: {
      actions: sendParent(({ event: { data: slot } }) => ({
        type: SLOTIFY_EVENTS_OUT.SLOT_IN_VIEW,
        data: slot,
      })),
    },
    [EVENTS.FIND_NEW_DYNAMIC_SLOTS]: {
      actions: sendTo('dynamicSlotMachine', {
        type: 'refresh',
      }),
    },
  },
});
