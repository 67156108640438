import { getEnv } from 'utils/env';
import triggerError from 'utils/trigger-error';

const env = getEnv();

export default (message = 'Triggered from the console'): void => {
  env.setTimeout(() => {
    triggerError(message);
  });
};
