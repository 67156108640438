import { BrowserOptions } from '../parameters/index.types';

export const PROMETHEUS_PUSH_INTERVAL = 10000;
export const PROMETHEUS_SAMPLE_RATE = 0.01;
export const KIBANA_SAMPLE_RATE = 0.01;

export const ACCEPTABLE_BROWSERS: BrowserOptions[] = [
  BrowserOptions.IE,
  BrowserOptions.EDGE,
  BrowserOptions.SAFARI,
  BrowserOptions.FIREFOX,
  BrowserOptions.CHROME,
];

export const MARK = 'mark';
export const MARK_PREFIX = 'Bordeaux - ';
