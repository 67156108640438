import { isEmpty } from 'ramda';
import { getOnMountSlotStyles, applyStyles, stringToStyle } from 'utils/style';
import { LABEL_IDENTIFIER, LABEL_STYLE } from 'ad-framework/ad/create-ad-label';
import { Ad } from 'ad-framework/ad/index.types';
import DataObject from 'state/data-object';
import createStyledDiv from './create-styled-div';
import { Slot, SlotLabelPosition } from './index.types';

const LABEL_TEXT = 'Advertisement';

const inlineLeftRightSlotsStore: Map<string, number> = new Map();

const computeAndStoreNbOccurencesOfCurrentSlot = (genericSlotName: string | undefined): number => {
  let nbSlotOccurences = 0;
  if (genericSlotName) {
    if (inlineLeftRightSlotsStore.get(genericSlotName) !== undefined) {
      nbSlotOccurences = (inlineLeftRightSlotsStore.get(genericSlotName) || 0) + 1;
    }
    inlineLeftRightSlotsStore.set(genericSlotName, nbSlotOccurences);
  }
  return nbSlotOccurences;
};

const onSlotMount = (slot: DataObject<Slot>, ad: DataObject<Ad>): void => {
  const adElement = ad.getProperty('element');
  if (adElement === undefined || adElement === null) {
    throw new Error('Error in add slot on mount mutate handler, ad data is undefined');
  }

  const label = slot.getProperty('label');
  const element = slot.getProperty('element');
  const genericName = slot.getProperty('genericName');
  const id = slot.getProperty('id');

  const applyLabelToAds = label.applyLabelToAds && label.applyLabelToAds === 'true';
  if (applyLabelToAds) {
    ad.update({ label });
  }

  const labelElement =
    !label || isEmpty(label) || applyLabelToAds
      ? null
      : createStyledDiv(stringToStyle(label?.style || LABEL_STYLE));

  applyStyles(
    element,
    getOnMountSlotStyles(
      slot,
      labelElement?.style.height || '0',
      computeAndStoreNbOccurencesOfCurrentSlot(genericName),
    ),
  );

  element.classList.add('bordeaux-filled-slot');
  element.ariaHidden = 'true';

  element.appendChild(adElement);

  if (labelElement && !applyLabelToAds) {
    labelElement.id = `${id}${LABEL_IDENTIFIER}`;
    labelElement.textContent = label?.text || LABEL_TEXT;
    if (label?.position === SlotLabelPosition.ABOVE) {
      element.insertBefore(labelElement, element.firstChild);
    }
    if (label?.position === SlotLabelPosition.BELOW) {
      labelElement.style.zIndex = '0';
      element.style.justifyContent = 'space-between';
      element.lastChild?.after(labelElement);
    }
  }
};

export default onSlotMount;
