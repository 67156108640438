import { DeviceOptions } from 'parameters/index.types';
import DataObject from 'state/data-object';
import BordeauxMachineService from 'state/types/service.types';
import { ThirdParty } from 'third-party-apis/config.types';
import { Targeting } from 'ad-framework/targeting/index.types';
import { BordeauxMachineContext } from 'state/types/context.types';
import { Ad } from 'ad-framework/ad/index.types';
import adServerProcess from './ad-server';
import amazonProcess from './amazon';
import iasProcess from './ias';
import preBidProcess from './prebid';

const getPreProcessTimeout = (context: BordeauxMachineContext, auctionId: number): number => {
  const defaultTimeout = 3000;
  const timeouts = {
    [DeviceOptions.DESKTOP]: 3000,
    [DeviceOptions.TABLET]: 3000,
    [DeviceOptions.MOBILE]: 3000,
  };
  const { device } = context.pageParameters;
  const overrideTimeouts = context.auctionTimeouts;

  if (auctionId > 1 && overrideTimeouts && overrideTimeouts[device]) {
    return overrideTimeouts[device];
  }

  if (timeouts[device]) {
    return timeouts[device];
  }

  return defaultTimeout;
};

const preProcessAds = async (
  service: BordeauxMachineService,
  context: BordeauxMachineContext,
  ads: Array<DataObject<Ad>>,
  id: number,
  pageTargeting: Targeting,
): Promise<string[]> => {
  const apiSetupResults = context.thirdPartyResults;
  const PRE_PROCESS_TIMEOUT = getPreProcessTimeout(context, id);

  const processors: Array<Promise<string>> = [];
  if (
    apiSetupResults[ThirdParty.PREBID]?.config.enabled &&
    apiSetupResults[ThirdParty.PREBID]?.success
  ) {
    const { config } = apiSetupResults[ThirdParty.PREBID];
    processors.push(
      preBidProcess(
        service,
        context.config.bidFloors.floorPrices,
        context.config.bidFloors.useForPrebid,
        config.banner,
        ads,
        PRE_PROCESS_TIMEOUT,
        id,
        pageTargeting,
      ),
    );
  }
  if (
    apiSetupResults[ThirdParty.AD_SERVER].config.enabled &&
    apiSetupResults[ThirdParty.AD_SERVER].success
  ) {
    processors.push(
      adServerProcess(
        service,
        context,
        apiSetupResults[ThirdParty.AD_SERVER].config.ab,
        ads,
        PRE_PROCESS_TIMEOUT,
        id,
      ),
    );
  }
  if (
    apiSetupResults[ThirdParty.AMAZON]?.config.enabled &&
    apiSetupResults[ThirdParty.AMAZON].success
  ) {
    processors.push(amazonProcess(service, ads, PRE_PROCESS_TIMEOUT, id));
  }
  if (apiSetupResults[ThirdParty.IAS]?.config.enabled && apiSetupResults[ThirdParty.IAS]?.success) {
    processors.push(iasProcess(service, ads, PRE_PROCESS_TIMEOUT, id));
  }

  return Promise.all(processors);
};

export default preProcessAds;
