export enum UserSyncElement {
  IMAGE = 'IMAGE',
  IFRAME = 'IFRAME',
}

export interface UserSyncScriptDefinition {
  src?: string;
  async?: true;
  type?: string;
  onload?: () => void;
}
export interface UserSyncIframeDefinition {
  name?: string;
  id?: string;
  style?: Record<string, string>;
  width?: string;
  height?: string;
}
