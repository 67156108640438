import { AdUnitMode } from 'ad-framework/ad/index.types';
import { SommelierResponseAdUnit } from 'ad-framework/ad/sommelier-response.types';

export default {
  type: AdUnitMode.OOP,
  name: 'skin',
  sizes: [{ w: 1, h: 1 }],
  targeting: {
    oop: ['skin'],
  },
  properties: {},
} as SommelierResponseAdUnit;
