import log from 'log';
import { DeviceOptions } from 'parameters/index.types';
import { API_EVENTS_OUT } from './events.names';
import { DeviceAvoidanceDistance } from '../bordeaux.types';
import { ApiMachineEventService } from './events.service';

export default (
  service: ApiMachineEventService,
  avoidanceDistance: DeviceAvoidanceDistance,
): void => {
  if (typeof avoidanceDistance !== 'object') {
    log.error(
      `Argument passed to setSlotAvoidanceDistance must be an object, ${typeof avoidanceDistance} given`,
    );
    return;
  }

  if (
    !(
      DeviceOptions.MOBILE in avoidanceDistance &&
      typeof avoidanceDistance.mobile === 'number' &&
      DeviceOptions.TABLET in avoidanceDistance &&
      typeof avoidanceDistance.tablet === 'number' &&
      DeviceOptions.DESKTOP in avoidanceDistance &&
      typeof avoidanceDistance.desktop === 'number'
    )
  ) {
    log.error(
      `Argument passed to setSlotAvoidanceDistance must be an object, and must contain the keys "${DeviceOptions.MOBILE}", "${DeviceOptions.TABLET}" and "${DeviceOptions.DESKTOP}" with number values`,
    );
    return;
  }

  service.sendEvent({
    type: API_EVENTS_OUT.SET_AVOIDANCE_DISTANCE,
    data: avoidanceDistance,
  });
};
