import { UserSyncElement } from 'user-sync/index.types';
import { UserSyncConfig } from '../../state/types/context.types';

const verizon: UserSyncConfig = {
  id: '61',
  bidderName: 'verizon',
  iabIdList: [25],
  element: UserSyncElement.IFRAME,
  url: 'https://ups.analytics.yahoo.com/ups/58290/sync',
  params: {
    redir: 'true',
  },
};
export default verizon;
