import { SommelierResponseSlot } from 'ad-framework/slot/sommelier-response.types';

import { SlotPosition, SlotType } from 'ad-framework/slot/index.types';
import { MPU_WIDTH, DOUBLE_MPU_HEIGHT } from '../../constants';

export const topOfSidebarSlot: SommelierResponseSlot = {
  name: 'topOfSidebar',
  hook: '#sidebar-top',
  type: SlotType.BLOCK,
  position: SlotPosition.PREPEND,
  size: {
    w: MPU_WIDTH,
    h: DOUBLE_MPU_HEIGHT,
  },
  properties: {
    config: {
      ignoreExclusion: true,
    },
  },
};

export const beforePopularBoxSlot: SommelierResponseSlot = {
  name: 'beforePopularBox',
  hook: '#sidebar-popular-top',
  type: SlotType.BLOCK,
  position: SlotPosition.PREPEND,
  size: {
    w: MPU_WIDTH,
    h: DOUBLE_MPU_HEIGHT,
  },
  properties: {
    config: {
      ignoreExclusion: true,
    },
  },
};

export const afterPopularBoxSlot: SommelierResponseSlot = {
  name: 'afterPopularBox',
  hook: '#sidebar-popular-bottom',
  type: SlotType.BLOCK,
  position: SlotPosition.PREPEND,
  size: {
    w: MPU_WIDTH,
    h: DOUBLE_MPU_HEIGHT,
  },
  properties: {
    config: {
      ignoreExclusion: true,
    },
  },
};
