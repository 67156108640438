import log from 'log';
import { z } from 'zod';
import { TargetingValue } from 'ad-framework/targeting/index.types';
import { targetingSchema } from 'ad-framework/targeting/zod-schema';

export const transformToArrayIfNecessary = (
  value: TargetingValue,
  separator: string | RegExp,
): Array<string> => {
  return typeof value === 'string' ? value.split(separator).filter(item => item !== '') : value;
};

export const normaliseTargetingArray = (
  value: string | Array<string>,
  separator: string | RegExp,
): Array<string> => {
  if (!value) {
    return [];
  }
  if (Array.isArray(value)) {
    return value;
  }
  if (typeof value === 'string') {
    return value.split(separator).filter(item => item !== '');
  }

  log.error(`The targeting value ${String(value)} could not be converted into an array.`);
  return [];
};

export const exSeparator = '|';
const customParsing = {
  _ex: (value: TargetingValue): Array<string> => transformToArrayIfNecessary(value, exSeparator),
  kw: (value: TargetingValue): Array<string> => transformToArrayIfNecessary(value, ' '),
  format: (value: TargetingValue): Array<string> => transformToArrayIfNecessary(value, /,\s*/gim),
};

function transformTarget(key: string, originalValue: TargetingValue): TargetingValue {
  if (Object.keys(customParsing).includes(key)) {
    return customParsing[key](originalValue);
  }

  return originalValue;
}
const transformTargetEntries = ([key, value]: [string, TargetingValue]): [
  string,
  TargetingValue,
] => [key, transformTarget(key, value)];

const adaptTargetingConfig = (
  targeting: z.output<typeof targetingSchema>,
): Record<string, TargetingValue> =>
  Object.fromEntries(Object.entries(targeting).map(transformTargetEntries));

export default adaptTargetingConfig;
