import { ThirdPartyAPIMachineConfigs } from 'state/types/context.types';
import { ThirdParty } from 'third-party-apis/config.types';
import loadScript from 'third-party-apis/utils/load-script';
import PartialPick from 'utils/partial-pick.types';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.EUID], 'thirdParty'> = {
  thirdParty: ThirdParty.EUID,
  methods: {
    getScriptLocation: () => `https://prod.euid.eu/static/js/euid-sdk-1.0.0.js`,
    loadScript: scriptLocation =>
      loadScript(scriptLocation, 'euid-script', { async: true, defer: true }),
  },
};
export default config;
