import { SommelierResponseSlot } from 'ad-framework/slot/sommelier-response.types';

import { SlotLabelPosition, SlotPosition, SlotType } from 'ad-framework/slot/index.types';
import { MPU_WIDTH, SINGLE_MPU_HEIGHT, BACKGROUND } from '../../constants';

export default {
  name: `mpu-paragraph`,
  type: SlotType.BLOCK,
  hook: '#article-body > p, .article__body > p',
  position: SlotPosition.BEFORE,
  size: { w: MPU_WIDTH, h: SINGLE_MPU_HEIGHT },
  properties: {
    label: {
      position: SlotLabelPosition.ABOVE,
      backgroundColour: BACKGROUND,
    },
    style: {
      marginBottom: '20px',
      backgroundColor: BACKGROUND,
    },
    config: {
      multiple: true,
    },
  },
} as SommelierResponseSlot;
