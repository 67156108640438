import findValue from 'utils/find-param';
import { Targeting } from './index.types';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/match#Description
export const paramsReg = /(&(.*?)=)/g; // Regex must be global
export const removeFirstandLastLetters = (param: string): string =>
  param.substring(1, param.length - 1);
export const applyRegex = (str: string, regex: RegExp): null | string[] => str.match(regex);

const applyNewValues =
  getValue =>
  (customParams: Targeting, newParam: string): Targeting => {
    const value: string | null = getValue(newParam);
    return value ? { ...customParams, [newParam]: value } : customParams;
  };

const customTargeting = (targeting: Targeting, newValues: string): Targeting => {
  const extractNewTargeting: string[] = applyRegex(newValues, paramsReg) || [];
  const newTargetingKeys: string[] = extractNewTargeting.map(removeFirstandLastLetters);
  const getValue = (param: string): string | null => findValue(param, newValues);
  return newTargetingKeys.reduce(applyNewValues(getValue), targeting);
};

export default customTargeting;
