export interface Bordeaux {
  version: string;
  experiment_id: string;
}

export interface HybridABTest {
  id: string;
  variant_id: string;
}

export interface Browser {
  type: string;
  device_type: string;
  connection_type: string;
  cft_label_name: string;
}

export interface User {
  country: string;
  hybrid_id: string | null;
  unload_time: string | null;
}

export interface CMP {
  start_time: string | null;
  pending_time: string | null;
  load_time: string | null;
  fail_time: string | null;
  status: RequestStatus;
}

export interface Session {
  url: string;
  id: number;
}

export interface Script {
  name: string;
  start_time: string;
  end_time: string | null;
  status: RequestStatus;
}

export interface ThirdParties {
  start_time: string | null;
  end_time: string | null;
  scripts: Script[];
}

export interface PartnerRequest {
  name: string;
  start_time: string;
  end_time: string | null;
  status: RequestStatus;
}

export interface ConfigRequest {
  start_time: string | null;
  end_time: string | null;
  status: RequestStatus;
}

export interface Payload {
  bordeaux: Bordeaux;
  hybrid_ab_test: HybridABTest[];
  browser: Browser;
  user: User;
  session: Session;
  third_parties: ThirdParties;
  config: ConfigRequest;
  cmp: CMP;
}
export interface AuctionPayload {
  id: number;
  start_time: string | null;
  end_time: string | null;
  adload_time: string | null;
  partner_requests: PartnerRequest[];
}

export enum RequestStatus {
  UNSPECIFIED = 'REQUEST_STATUS_UNSPECIFIED',
  PENDING = 'REQUEST_STATUS_PENDING',
  SUCCESSFUL = 'REQUEST_STATUS_SUCCESSFUL',
  TIMEOUT = 'REQUEST_STATUS_TIMEOUT',
  FAILED = 'REQUEST_STATUS_FAILED',
}
