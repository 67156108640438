import { refresh as adManagerRefresh } from 'ad-framework/ad-manager/index';
import { Ad } from 'ad-framework/ad/index.types';
import DataObject from 'state/data-object';
import { getRefreshTargetingValues } from 'utils/get-gpt-targeting';
import { BordeauxMachineContext } from 'state/types/context.types';
import ActionArgs from 'state/proxy/action-args.types';
import { adCanRefresh, tandemAdsPresent } from '../logic';

const getAdsToRefresh = (context: BordeauxMachineContext): Array<DataObject<Ad>> => {
  const ads = context.ads.getValues();
  ads.forEach(ad => {
    if (ad.getProperty('inView')) {
      const viewedTime = ad.getProperty('viewedTime') || 0;
      ad.update({ viewedTime: viewedTime + 1 });
    }
  });

  if (!context.config.placement) {
    return [];
  }

  const refreshTime =
    context.refreshTime ||
    context.config.placement.settings.refreshTime ||
    Number.POSITIVE_INFINITY;

  const adsCanRefresh = ads
    .filter(adCanRefresh(context))
    .filter(ad => (ad.getProperty('viewedTime') || 0) >= refreshTime);

  const adsToRefresh = tandemAdsPresent(adsCanRefresh, context.slots.getValues());

  adsToRefresh.forEach(ad => {
    const targeting = ad.getProperty('targeting');
    ad.update({
      targeting: {
        ...targeting,
        ...getRefreshTargetingValues(ad, false),
      },
      inView: false,
      viewedTime: 0,
    });
  });
  return adsToRefresh;
};

const triggerAutomaticRefresh = ({ context }: ActionArgs): void => {
  const adsToRefresh = getAdsToRefresh(context);
  if (adsToRefresh.length > 0) {
    adManagerRefresh(adsToRefresh);
  }
};

export default triggerAutomaticRefresh;
