import { FallbackAdServerResponses } from 'config/fallback/index.types';
import log from 'log';
import { ApiMachineEventService } from './events.service';
import { API_EVENTS_OUT } from './events.names';

export default (
  service: ApiMachineEventService,
  fallbackRespones: Partial<FallbackAdServerResponses>,
): void => {
  if (typeof fallbackRespones !== 'object') {
    log.error(
      `Argument passed to setFallbackConfig must be an object, ${typeof fallbackRespones} given`,
    );
    return;
  }

  service.sendEvent({
    type: API_EVENTS_OUT.SET_FALLBACK_RESPONSES,
    data: fallbackRespones,
  });
};
