import { getEnv } from 'utils/env';
import { disableFeature, enableFeature } from 'api/features';
import { FEATURE } from 'api/feature';
import log, { api as logAPI } from 'log';
import metrics from 'metrics';
import { SiteConfig, ThirdParty } from 'third-party-apis/config.types';
import { Targeting } from 'ad-framework/targeting/index.types';
import logError from 'api/error/log-error';
import reportError from 'api/error/report-error';
import triggerError from 'api/error/trigger-error';
import addAdHighlights from 'api/highlight/add-ad-highlights';
import addSlotHighlights from 'api/highlight/add-slot-highlights';
import removeAdHighlights from 'api/highlight/remove-ad-highlights';
import removeSlotHighlights from 'api/highlight/remove-slot-highlights';
import hybridId from 'api/hybrid-id';
import init from 'api/init';
import mobileBigtop from 'api/mobile-bigtop';
import openTool from 'api/open-tool';
import refresh from 'api/refresh';
import getAdBlock from 'api/get-ad-block';
import getAdInfo from 'api/get-ad-info';
import getAdToolPage from 'api/get-ad-tool-page';
import getBranchName from 'api/get-branch-name';
import getPlacementId from 'api/get-placement-id';
import getPlatformId from 'api/get-platform-id';
import getSlotAvoidanceDistance from 'api/get-slot-avoidance-distance';
import getSlotInfo from 'api/get-slot-info';
import getSommelierResponse from 'api/get-sommelier-response';
import getTargeting from 'api/get-targeting';
import getThirdPartyAPIInfo from 'api/get-third-party-api-info';
import getVideoBehaviourStatus from 'api/get-video-behaviour-status';
import setAuctionTimeouts from 'api/set-auction-timeouts';
import setFallbackConfig from 'api/set-fallback-config';
import setPageCategory, { getPageCategory } from 'api/set-page-category';
import setPageTemplate, { getPageTemplate } from 'api/set-page-template';
import setRefreshTime from 'api/set-refresh-time';
import setSlotActivationDistance from 'api/set-slot-activation-distance';
import setSlotAvoidanceDistance from 'api/set-slot-avoidance-distance';
import setThirdPartyAPIConfig from 'api/set-third-party-api-config';
import setToggleAdsRefresh from 'api/set-toggle-ads-refresh';
import setUnrefreshableAds from 'api/set-unrefreshable-ads';
import { FallbackAdServerResponses } from 'config/fallback/index.types';
import { DeviceOptions } from 'parameters/index.types';
import prebidVideo from 'ad-framework/ad-manager/pre-process-ads/prebid/video';
import setAdToolVersion from 'api/set-ad-tool-version';
import getPrebidAnalytics, {
  setPrebidAnalyticsEnabled,
  setPrebidAnalyticsDisabled,
} from 'api/get-prebid-analytics';
import getStandardAdsLoaded from 'api/get-standard-ads-loaded';
import setAutomaticDynamic from 'api/set-automatic-dynamic';
import getPageParameters from 'api/get-page-parameters';
import { EVENTS } from 'state/types/events.names';
import { FullAPISetupResults } from 'third-party-apis/index.types';
import unique from 'utils/unique';
import { fromCallback } from 'xstate';
import {
  BordeauxMachineContext,
  ProvideBordeauxContextEvent,
  ProvideBordeauxDataEvent,
} from 'state/types/context.types';
import { RoadblockIncrementalChooser } from 'ad-framework/roadblock/incremental.types';
import { AD_FEATURE_NAMES } from 'ad-features/index.names';
import requestHandleDynamicSlots from 'api/handle-dynamic-slots';
import commands from 'utils/command-queue';
import { CompanionBounds } from 'ad-framework/slot/index.types';
import getAnchoredBehaviourStatus from './get-anchored-behaviour-status';

import { AuctionTimeouts, DeviceAvoidanceDistance } from '../bordeaux.types';
import { BordeauxAPI } from './index.types';
import { ApiMachineEventService } from './events.service';
import { getExperimentId, setExperimentId } from './set-experiment-id';
import setRoadblockIncrementalCaps from './set-roadblock-incremental-caps';
import setRoadblockIncrementalChooser from './set-roadblock-incremental-chooser';
import updateDynamicSlots from './update-dynamic-slots';
import gptLoaded, { setLoadGptExternally } from './gpt-loaded';
import getThirdPartyApiResults from './get-third-party-api-results';
import setTargeting from './set-targeting';
import { ApiInEvents } from './events.types';
import { API_EVENTS_IN, API_EVENTS_OUT } from './events.names';
import setCompanionBounds from './set-companion-bounds';

export default fromCallback<ApiInEvents | ProvideBordeauxDataEvent | ProvideBordeauxContextEvent>(
  ({ sendBack, receive }): void => {
    const env = getEnv();

    const dataRequests: Record<number, (event: ProvideBordeauxDataEvent) => void> = {};
    const contextRequests: Record<number, (event: ProvideBordeauxContextEvent) => void> = {};
    const waitFors: Partial<Record<API_EVENTS_IN, () => void>> = {};
    receive(receivedEvent => {
      if (receivedEvent.type in waitFors) {
        waitFors[receivedEvent.type]();
        return;
      }
      const event = receivedEvent as ProvideBordeauxDataEvent | ProvideBordeauxContextEvent;
      if (!event.data.requestId) return;

      switch (event.type) {
        case EVENTS.PROVIDE_BORDEAUX_DATA: {
          const request = dataRequests[event.data.requestId];
          if (!request) return;
          delete dataRequests[event.data.requestId];

          request(event);
          break;
        }
        case EVENTS.PROVIDE_BORDEAUX_CONTEXT: {
          const request = contextRequests[event.data.requestId];
          if (!request) return;
          delete contextRequests[event.data.requestId];

          request(event);
          break;
        }
        default:
      }
    });
    const getData = <T>(...property: Array<string | number>): Promise<T> =>
      new Promise<T>(resolve => {
        const requestId = unique();
        dataRequests[requestId] = (event: ProvideBordeauxDataEvent): void =>
          resolve(event.data.value as T);
        sendBack({
          type: EVENTS.REQUEST_BORDEAUX_DATA,
          data: {
            property,
            requestId,
          },
        });
      });
    const getContext = (): Promise<BordeauxMachineContext> =>
      new Promise<BordeauxMachineContext>(resolve => {
        const requestId = unique();
        contextRequests[requestId] = (event: ProvideBordeauxContextEvent): void =>
          resolve(event.data.value);
        sendBack({
          type: EVENTS.REQUEST_BORDEAUX_CONTEXT,
          data: {
            requestId,
          },
        });
      });
    const waitFor = (eventType: API_EVENTS_IN): Promise<void> =>
      new Promise<void>(resolve => {
        waitFors[eventType] = resolve;
      });

    const apiMachineEventService: ApiMachineEventService = {
      sendEvent: sendBack,
      receiveEvent: receive,
      getData,
      getContext,
      waitFor: {
        config: waitFor(API_EVENTS_IN.CONFIG_READY),
        thirdParties: waitFor(API_EVENTS_IN.THIRD_PARTIES_READY),
        hybridId: waitFor(API_EVENTS_IN.HYBRID_ID_READY),
        roadblock: waitFor(API_EVENTS_IN.ROADBLOCK_READY),
      },
    };
    let initialised = false;
    let commandsRun = false;
    const commandQueue = ((env as any).bordeaux && (env as any).bordeaux.cmd) || []; // eslint-disable-line @typescript-eslint/no-explicit-any

    const bordeauxAPI: BordeauxAPI = {
      cmd: commandQueue,
      adFeatures: Object.values(AD_FEATURE_NAMES),
      disableFeature: (feature: FEATURE) => {
        disableFeature(apiMachineEventService, feature);
        return bordeauxAPI;
      },
      enableFeature: (feature: FEATURE) => {
        enableFeature(apiMachineEventService, feature);
        return bordeauxAPI;
      },
      error: {
        logError,
        reportError,
        triggerError,
      },
      features: FEATURE,
      highlight: {
        addAdHighlights: (names?: Array<string>) => addAdHighlights(apiMachineEventService, names),
        addSlotHighlights: (names?: Array<string>) =>
          addSlotHighlights(apiMachineEventService, names),
        removeAdHighlights,
        removeSlotHighlights,
      },
      hybridId: hybridId(apiMachineEventService),
      init: () => {
        if (commandsRun) {
          init(apiMachineEventService);
        } else {
          initialised = true;
        }
      },
      log: logAPI,
      metrics,
      mobileBigtop: mobileBigtop(apiMachineEventService),
      onNotification: () => bordeauxAPI,
      openTool: () => openTool(apiMachineEventService),
      refresh: (adUnitNames: Array<string>, inView: boolean) => {
        refresh(apiMachineEventService, adUnitNames, inView);
        return bordeauxAPI;
      },
      roadblock: apiMachineEventService.waitFor.roadblock.then(() =>
        apiMachineEventService.getData<boolean>('isRoadblock'),
      ),
      getContext: () => apiMachineEventService.getContext(),
      getAdBlock: () => getAdBlock(apiMachineEventService),
      getAdInfo: () => getAdInfo(apiMachineEventService),
      getAdToolPage,
      getAnchoredBehaviourStatus: () => getAnchoredBehaviourStatus(apiMachineEventService),
      getBranchName,
      getExperimentId: () => getExperimentId(apiMachineEventService),
      getPageCategory: () => getPageCategory(apiMachineEventService),
      getPageParameters: () => getPageParameters(apiMachineEventService),
      getPageTemplate: () => getPageTemplate(apiMachineEventService),
      getPlacementId: () => getPlacementId(apiMachineEventService),
      getPlatformId: () => getPlatformId(apiMachineEventService),
      getPrebidAnalytics,
      getStandardAdsLoaded: getStandardAdsLoaded(apiMachineEventService),
      getSlotAvoidanceDistance: () => getSlotAvoidanceDistance(apiMachineEventService),
      getSlotInfo: () => getSlotInfo(apiMachineEventService),
      getSommelierResponse: () => getSommelierResponse(apiMachineEventService),
      getTargeting: () => getTargeting(apiMachineEventService),
      getThirdPartyAPIInfo: () => getThirdPartyAPIInfo(apiMachineEventService),
      getThirdPartyAPIResults: () => getThirdPartyApiResults(apiMachineEventService),
      gptLoaded: (flag: boolean) => {
        gptLoaded(apiMachineEventService, flag);
        return bordeauxAPI;
      },
      getVideoBehaviourStatus: getVideoBehaviourStatus(apiMachineEventService),
      requestHandleDynamicSlots: () => {
        requestHandleDynamicSlots(apiMachineEventService);
        return bordeauxAPI;
      },
      setLoadGptExternally: () => {
        setLoadGptExternally(apiMachineEventService);
        return bordeauxAPI;
      },
      setPrebidAnalyticsDisabled: () => {
        setPrebidAnalyticsDisabled(apiMachineEventService);
        return bordeauxAPI;
      },
      setPrebidAnalyticsEnabled: () => {
        setPrebidAnalyticsEnabled(apiMachineEventService);
        return bordeauxAPI;
      },
      setAdToolVersion: (version: string) => {
        setAdToolVersion(apiMachineEventService, version);
        return bordeauxAPI;
      },
      setAuctionTimeouts: (auctionTimeouts: AuctionTimeouts) => {
        setAuctionTimeouts(apiMachineEventService, auctionTimeouts);
        return bordeauxAPI;
      },
      setAutomaticDynamic: (automaticDynamic: boolean) => {
        setAutomaticDynamic(apiMachineEventService, automaticDynamic);
        return bordeauxAPI;
      },
      setCompanionBounds: (companionBounds: CompanionBounds) => {
        setCompanionBounds(apiMachineEventService, companionBounds);
        return bordeauxAPI;
      },
      setExperimentId: (id: string) => {
        setExperimentId(apiMachineEventService, id);
        return bordeauxAPI;
      },
      setFallbackConfig: (config: Partial<FallbackAdServerResponses>) => {
        setFallbackConfig(apiMachineEventService, config);
        return bordeauxAPI;
      },
      setPageCategory: (pageCategory: string) => {
        setPageCategory(apiMachineEventService, pageCategory);
        return bordeauxAPI;
      },
      setPageTemplate: (pageTemplate: string) => {
        if (commandsRun && initialised) {
          log.error(
            `setPageTemplate cannot be called after the request has been sent to the AdServer`,
          );
          return bordeauxAPI;
        }

        setPageTemplate(apiMachineEventService, pageTemplate);
        return bordeauxAPI;
      },
      setRefreshTime: (refreshTime: number) => {
        setRefreshTime(apiMachineEventService, refreshTime);
        return bordeauxAPI;
      },
      setRoadblockIncrementalCaps: (roadblockIncrementalCaps: Record<DeviceOptions, number>) => {
        setRoadblockIncrementalCaps(apiMachineEventService, roadblockIncrementalCaps);
        return bordeauxAPI;
      },
      setRoadblockIncrementalChooser: (
        roadblockIncrementalChooser: RoadblockIncrementalChooser,
      ) => {
        setRoadblockIncrementalChooser(apiMachineEventService, roadblockIncrementalChooser);
        return bordeauxAPI;
      },
      setSlotActivationDistance: (activationDistance: number) => {
        setSlotActivationDistance(apiMachineEventService, activationDistance);
        return bordeauxAPI;
      },
      setSlotAvoidanceDistance: (avoidanceDistance: DeviceAvoidanceDistance) => {
        setSlotAvoidanceDistance(apiMachineEventService, avoidanceDistance);
        return bordeauxAPI;
      },
      setTargeting: (targetingData: Targeting) => {
        setTargeting(apiMachineEventService, targetingData);
        return bordeauxAPI;
      },
      setThirdPartyAPIConfig: (config: Partial<SiteConfig>) => {
        setThirdPartyAPIConfig(apiMachineEventService, config);
        return bordeauxAPI;
      },
      setToggleAdsRefresh: () => {
        setToggleAdsRefresh(apiMachineEventService);
        return bordeauxAPI;
      },
      setUnrefreshableAds: (unrefreshableAds: Array<string>) => {
        setUnrefreshableAds(apiMachineEventService, unrefreshableAds);
        return bordeauxAPI;
      },
      updateDynamicSlots: () => {
        updateDynamicSlots(apiMachineEventService);
        return bordeauxAPI;
      },
      version: process.env.npm_package_version,
      prebidVideo: async (
        tag: string,
        playerSize: [number, number],
        videoCode: string,
      ): Promise<string> => {
        await apiMachineEventService.waitFor.thirdParties;
        const [device, thirdPartyResults] = await Promise.all([
          apiMachineEventService.getData<DeviceOptions>('pageParameters', 'device'),
          apiMachineEventService.getData<FullAPISetupResults[ThirdParty.PREBID]>(
            'thirdPartyResults',
            ThirdParty.PREBID,
          ),
        ]);
        return prebidVideo(device, thirdPartyResults, tag, playerSize, videoCode);
      },

      // Deprecated
      setSimpleRefresh: () => {
        log.warn(
          `setSimpleRefresh has been deprecated. This api call should be removed in the future.`,
        );
        return bordeauxAPI;
      },
      setAssignSpaceOnLoad: () => {
        log.warn(
          `setAssignSpaceOnLoad has been deprecated. This api call should be removed in the future.`,
        );
        return bordeauxAPI;
      },
      setDelayedIncrementalAds: () => {
        log.warn(
          `setDelayedIncrementalAds has been deprecated. This api call should be removed in the future.`,
        );
        return bordeauxAPI;
      },
      setEagerSpaceAllocation: () => {
        log.warn(
          `setEagerSpaceAllocation has been deprecated. This api call should be removed in the future.`,
        );
        return bordeauxAPI;
      },
      setFlyingCarpetAds: () => {
        log.warn(
          `setFlyingCarpetAds has been deprecated. This api call should be removed in the future.`,
        );
        return bordeauxAPI;
      },
      setHiddenScrollAds: () => {
        log.warn(
          `setHiddenScrollAds has been deprecated. This api call should be removed in the future.`,
        );
        return bordeauxAPI;
      },
      setIndividualAdUnits: () => {
        log.warn(
          `setIndividualAdUnits has been deprecated. This api call should be removed in the future.`,
        );
        return bordeauxAPI;
      },
      setLazyloadIncremental: () => {
        log.warn(
          `setLazyloadIncremental has been deprecated. This api call should be removed in the future.`,
        );
        return bordeauxAPI;
      },
      setPlacement: () => {
        log.warn(
          `setPlacement has been deprecated. This api call should be removed in the future.`,
        );
        return bordeauxAPI;
      },
      enablePrebidVideoTest: () => {
        log.warn(
          `enablePrebidVideoTest has been deprecated. This api call should be removed in the future.`,
        );
      },
    };
    sendBack({
      type: EVENTS.SET_EXTERNAL_API,
      data: bordeauxAPI,
    });
    waitFor(API_EVENTS_IN.API_READY).then(() => {
      commands(commandQueue);
      commandsRun = true;
      sendBack({ type: API_EVENTS_OUT.COMMANDS_RUN, data: { initialised } });
    });
  },
);
