import fastdom from 'utils/fastdom';
import { getEnv } from 'utils/env';

const cssObjToString = (cssObj: Record<string, string>): string =>
  Object.entries(cssObj)
    .map(([cssProp, cssValue]) => {
      const snakeProp = cssProp.replace(
        /[a-z][A-Z]/g,
        camel => `${camel[0]}-${camel[1].toLowerCase()}`,
      );
      return `${snakeProp}: ${cssValue};`;
    })
    .join('\n');

export default (
  element: Element | null,
  id: string,
  store: Record<string, HTMLElement>,
  config: {
    name: string;
    id: string;
    highlight: Record<string, string>;
    information: Record<string, string>;
  },
): void => {
  const env = getEnv();

  const elementStyle = env.document.createElement('style');
  elementStyle.style.display = 'none';
  store[id] = elementStyle;

  fastdom
    .measure(function isElementStatic(): boolean {
      if (element === null) {
        return true;
      }
      return env.getComputedStyle(element).getPropertyValue('position') === 'static';
    })
    .then(isStatic => {
      fastdom.mutate(function addHighlight() {
        if (element === null) {
          return;
        }
        const stylePositionOverride = isStatic
          ? `#${element.id} {
              position: relative;
            }`
          : '';
        elementStyle.innerHTML = `${stylePositionOverride}
        #${element.id}:before {
          content: " ";
          ${cssObjToString(config.highlight)}
        }
        #${element.id}:after {
          content: "${id}${config.name}";
          ${cssObjToString(config.information)}
        }`;
        if (element.parentElement) {
          element.parentElement.insertBefore(elementStyle, element);
        }
      });
    });
};
