import adaptAdUnitListGroupConfig from 'ad-framework/ad-list-group/adapt-config';
import { adUnitListGroupSchema } from 'ad-framework/ad-list-group/zod-schema';
import { settingsSchema } from 'ad-framework/settings/zod-schema';
import adaptSlotListGroupConfig from 'ad-framework/slot-list-group/adapt-config';
import { slotListGroupSchema } from 'ad-framework/slot-list-group/zod-schema';
import { z } from 'zod';

export const placementSchema = z
  .object({
    id: z.number().default(-1),
    platformId: z.number().default(-1),
    abTest: z
      .array(
        z
          .object({
            origin: z.string(),
            t_name: z.string(),
            t_id: z.string(),
            v_id: z.string(),
            v_name: z.string(),
          })
          .strip(),
      )
      .optional(),
    linkedIds: z.array(z.number()).default([]),
    siteAdUnitPath: z.string().default(''),
    placementAdUnitPath: z.string().default(''),
    slots: slotListGroupSchema.transform(adaptSlotListGroupConfig),
    adunits: adUnitListGroupSchema.transform(adaptAdUnitListGroupConfig),
    settings: settingsSchema,
  })
  .default({});
