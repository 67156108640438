import { UserSyncConfig } from '../../state/types/context.types';
import { UserSyncElement } from '../index.types';
import { getUserSyncURL } from './utils';

const id = '23';
const appnexus: UserSyncConfig = {
  element: UserSyncElement.IMAGE,
  id,
  bidderName: 'appnexus',
  iabIdList: [32],
  url: `https://ib.adnxs.com/getuid?${encodeURIComponent(getUserSyncURL({ id, uid: '$UID' }))}`,
};
export default appnexus;
