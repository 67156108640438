export enum REPORT {
  MULTIPLE_SCRIPTS = 'MULTIPLE_SCRIPTS',
  FRAMEWORK_REQUEST = 'FRAMEWORK_REQUEST',
  AD_BLOCKED = 'AD_BLOCKED',
  CONTENT_LOAD = 'CONTENT_LOAD',

  CONFIG_REQUEST = 'REPORT_CONFIG_REQUEST',
  CONFIG_FAILURE = 'REPORT_CONFIG_FAILURE',
  CONFIG_LOAD = 'REPORT_CONFIG_LOAD',
  CONFIG_EMPTY = 'REPORT_CONFIG_EMPTY',
  CONFIG_PARSE_FAILURE = 'REPORT_CONFIG_PARSE_FAILURE',
  CONFIG_SUCCESS = 'REPORT_CONFIG_SUCCESS',
  // CONFIG_TIMEOUT = 'REPORT_CONFIG_TIMEOUT',

  THIRD_PARTY_REQUEST = 'REPORT_THIRD_PARTY_REQUEST',
  THIRD_PARTY_SUCCESS = 'REPORT_THIRD_PARTY_SUCCESS',

  CONSENT_REQUEST = 'REPORT_CONSENT_REQUEST',
  CONSENT_FAILURE = 'REPORT_CONSENT_FAILURE',
  CONSENT_SUCCESS = 'REPORT_CONSENT_SUCCESS',
  CONSENT_PENDING = 'REPORT_CONSENT_PENDING',
  CONSENT_LOADED = 'REPORT_CONSENT_LOADED',
  CONSENT_MOCKED = 'REPORT_CONSENT_MOCKED',
}

export enum REPORT_AUCTION {
  START = 'REPORT_AUCTION_START',
  END = 'REPORT_AUCTION_END',
  AD_LOAD = 'REPORT_AUCTION_AD_LOAD',
}

export enum REPORT_AUCTION_PARTNER {
  REQUEST = 'REPORT_AUCTION_PARTNER_REQUEST',
  SUCCESS = 'REPORT_AUCTION_PARTNER_SUCCESS',
  TIMEOUT = 'REPORT_AUCTION_PARTNER_TIMEOUT',
}
export enum REPORT_THIRD_PARTY_SCRIPT {
  REQUEST = 'REPORT_THIRD_PARTY_SCRIPT_REQUEST',
  TIMEOUT = 'REPORT_THIRD_PARTY_SCRIPT_TIMEOUT',
  SUCCESS = 'REPORT_THIRD_PARTY_SCRIPT_SUCCESS',
  FAILURE = 'REPORT_THIRD_PARTY_SCRIPT_FAILURE',
}

export enum EVENTS {
  SET_HYBRID_ABTEST_TARGETING = 'SET_HYBRID_ABTEST_TARGETING',
  SET_HYBRID_ID = 'SET_HYBRID_ID',
  SET_CFT_PARAMETERS = 'SET_CFT_PARAMETERS',
  SET_ROADBLOCK_STATUS = 'SET_ROADBLOCK_STATUS',
  PAGE_UNLOAD = 'PAGE_UNLOAD',
  PAGE_LOAD = 'PAGE_LOAD',
  OPEN_AD_TOOL = 'OPEN_AD_TOOL',
  AUTOMATIC_REFRESH = 'AUTOMATIC_REFRESH',
  CHECK_ROADBLOCK_STATUS = 'CHECK_ROADBLOCK_STATUS',
  SET_EXTERNAL_API = 'SET_EXTERNAL_API',

  REQUEST_BORDEAUX_DATA = 'REQUEST_BORDEAUX_DATA',
  PROVIDE_BORDEAUX_DATA = 'PROVIDE_BORDEAUX_DATA',

  REQUEST_BORDEAUX_CONTEXT = 'REQUEST_BORDEAUX_CONTEXT',
  PROVIDE_BORDEAUX_CONTEXT = 'PROVIDE_BORDEAUX_CONTEXT',

  CONSENT_DONE = 'CONSENT_DONE',

  HIDE_ANCHORED_ADS = 'HIDE_ANCHORED_ADS',
  SHOW_ANCHORED_ADS = 'SHOW_ANCHORED_ADS',

  CONTEXT_ERROR = 'CONTEXT_ERROR',
  REQUEST_BATCH = 'REQUEST_BATCH',
  PROCESS_NEXT_SLOT = 'PROCESS_NEXT_SLOT',

  REQUEST_AUTOMATIC_BATCH = 'REQUEST_AUTOMATIC_BATCH',
  ADS_CREATED = 'ADS_CREATED',
  PROCESS_NEXT_BATCH = 'PROCESS_NEXT_BATCH',
  AD_MATCH = 'AD_MATCH',
}
