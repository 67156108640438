import getTargeting from 'ad-framework/targeting';
import { Targeting } from 'ad-framework/targeting/index.types';
import { ApiMachineEventService } from './events.service';

export default async (service: ApiMachineEventService): Promise<Targeting> => {
  await service.waitFor.hybridId;
  await service.waitFor.config;
  const context = await service.getContext();
  return getTargeting(context);
};
