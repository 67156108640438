import { getEnv } from 'utils/env';
import log from 'log';
import fastdom from 'utils/fastdom';
import { DeviceOptions } from 'parameters/index.types';
import DataObject from 'state/data-object';
import createElement from 'utils/create-element';
import getDeviceSize from '../../../parameters/device';
import { Ad } from '../index.types';

const createAnchoredAd = (ad: DataObject<Ad>): HTMLElement => {
  const isNotMobile = getDeviceSize() !== DeviceOptions.MOBILE;
  const hasCloseButton = isNotMobile || ad.getProperty('closeButton');

  return createElement(
    'div',
    {
      class: 'bordeaux-anchored-container',
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'fixed',
        bottom: '0',
        left: '0',
        width: '100%',
        minHeight: isNotMobile ? '90px' : '50px',
        zIndex: 9995,
      },
    },
    createElement(
      'div',
      {
        class: 'bordeaux-anchored-inner mobile-leaderboard-320-50',
        style: {
          background: 'rgb(247, 247, 247)',
          minHeight: isNotMobile ? '90px' : '50px',
          display: 'flex',
          justifyContent: 'center',
        },
      },
      createElement('div', {
        id: ad.getProperty('id'),
        ariaHidden: 'true',
      }),
    ),
    hasCloseButton
      ? createElement(
          'div',
          {
            class: 'bordeaux-anchored-close',
            style: {
              position: 'absolute',
              width: '14px',
              height: '14px',
              top: '4px',
              right: '4px',
            },
            onClick: () => {
              const element = ad.getProperty('element');
              if (element?.parentNode) {
                element.parentNode.removeChild(element);
              }
            },
          },
          `
          <svg fill="rgb(178, 178, 178)" width="14" height="14" viewBox="341 8 14 14" xmlns="http://www.w3.org/2000/svg"><path fill="%234F4F4F" d="M354 9.31 352.69 8l-5.19 5.19L342.31 8 341 9.31l5.19 5.19-5.19 5.19 1.31 1.31 5.19-5.19 5.19 5.19 1.31-1.31-5.19-5.19z" fill-rule="evenodd"/></svg>
        `,
        )
      : null,
  );
};

export default async (ad: DataObject<Ad>): Promise<void> => {
  try {
    const element = createAnchoredAd(ad);
    ad.update({ element });
    await fastdom.mutate(() => {
      const env = getEnv();
      env.document.body.appendChild(element);
    });
  } catch (error) {
    if (error instanceof Error) {
      log.error(error.message);
    }
  }
};
