import { RoadblockIncrementalCaps } from 'ad-framework/roadblock/incremental.types';
import log from 'log';
import { DeviceOptions } from 'parameters/index.types';
import { API_EVENTS_OUT } from './events.names';
import { ApiMachineEventService } from './events.service';

export default (
  service: ApiMachineEventService,
  roadblockIncrementalCaps: RoadblockIncrementalCaps,
): void => {
  if (typeof roadblockIncrementalCaps !== 'object') {
    log.error(
      `Argument passed to setRoadblockIncrementalCaps must be an object, ${typeof roadblockIncrementalCaps} given`,
    );
    return;
  }

  if (
    (DeviceOptions.MOBILE in roadblockIncrementalCaps &&
      typeof roadblockIncrementalCaps[DeviceOptions.MOBILE] !== 'number') ||
    (DeviceOptions.TABLET in roadblockIncrementalCaps &&
      typeof roadblockIncrementalCaps[DeviceOptions.TABLET] !== 'number') ||
    (DeviceOptions.DESKTOP in roadblockIncrementalCaps &&
      typeof roadblockIncrementalCaps[DeviceOptions.DESKTOP] !== 'number')
  ) {
    log.error(
      `Argument passed to setRoadblockIncrementalCaps must be an object, and must contain the keys "${DeviceOptions.MOBILE}", "${DeviceOptions.TABLET}" and/or "${DeviceOptions.DESKTOP}" with number values`,
    );
    return;
  }

  service.sendEvent({
    type: API_EVENTS_OUT.SET_ROADBLOCK_INCREMENTAL_CAPS,
    data: roadblockIncrementalCaps,
  });
};
