import setupMarks from './marks';
import setupPerformance from './performance';
import setupPrometheus from './prometheus';
import setupResources from './resources';
import { MarksAPI, PerformanceAPI, PrometheusAPI, ResourcesAPI, MetricsAPI } from './index.types';

const init = (): MetricsAPI => {
  const marks: MarksAPI = setupMarks();
  const performance: PerformanceAPI = setupPerformance();
  const prometheus: PrometheusAPI = setupPrometheus();
  const resources: ResourcesAPI = setupResources();

  const metricsApiObject: MetricsAPI = {
    ...marks,
    ...performance,
    ...prometheus,
    ...resources,
  };

  return metricsApiObject;
};
const metricsApi = init();

export default metricsApi;
