import log from 'log';

const createBait = (): HTMLDivElement => {
  const baitClass =
    'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads text-ad-links';
  const baitStyle =
    'width: 1px !important; height: 1px !important; position: absolute !important; left: -10000px !important; top: -1000px !important;';
  const bait = document.createElement('div');
  bait.setAttribute('class', baitClass);
  bait.setAttribute('style', baitStyle);
  document.body.appendChild(bait);

  return bait;
};

const adBlockTest = (bait: HTMLDivElement): boolean => {
  return (
    document.body.getAttribute('abp') !== null ||
    bait.offsetParent === null ||
    bait.offsetHeight === 0 ||
    bait.offsetLeft === 0 ||
    bait.offsetTop === 0 ||
    bait.offsetWidth === 0 ||
    bait.clientHeight === 0 ||
    bait.clientWidth === 0
  );
};

const performAdBlockTest = (): boolean => {
  try {
    const bait = createBait();
    if (!(bait instanceof HTMLDivElement)) {
      return false;
    }

    const testForBlock = adBlockTest(bait);
    bait.remove();

    return testForBlock;
  } catch (error) {
    if (error instanceof Error) {
      log.warn(`Adblock test failed: ${error.message}`);
    }
    return false;
  }
};

export default performAdBlockTest;
