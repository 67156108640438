import {
  REFRESH_EVENTS,
  SetRefreshPausedEvent as SetExternalRefreshPausedEvent,
} from 'ad-framework/refresh/automatic/index.types';
import { FEATURE } from 'api/feature';
import log from 'log';
import {
  EnableFeatureEvent,
  SetLoadGptExternallyEvent,
  SetAuctionTimeoutsEvent,
  SetAutomaticDynamicEvent,
  SetPageCategoryEvent,
  SetPageTemplateEvent,
  SetRefreshTimeEvent,
  SetRoadblockIncrementalCapsEvent,
  SetRoadblockIncrementalChooserEvent,
  SetActivationDistanceEvent,
  SetThirdPartyApiConfigEvent,
  SetDeviceAvoidanceDistanceEvent,
  SetPageTargetingEvent,
  SetExperimentIdEvent,
  SetAdToolVersionEvent,
  SetPrebidAnalyticsEnabledEvent,
  DisableFeatureEvent,
  SetRefreshPausedEvent,
  GptLoadedExternallyEvent,
  SetCompanionBoundsEvent,
  SetFallbackResponsesEvent,
  AddUnrefreshableNamesEvent,
} from 'api/events.types';
import { API_EVENTS_OUT } from 'api/events.names';
import { ACTIONS_RECORD } from './report';
import {
  EVENTS as SLOTIFY_EVENTS,
  INCREMENTAL_ADS_EVENTS_IN,
  STANDARD_ADS_EVENTS_IN,
} from '../slotify/index.types';
import assign from './proxy/assign';
import ActionArgs from './proxy/action-args.types';
import enqueueActions from './proxy/enqueueActions';
import GuardArgs from './proxy/guard-args.types';
import sendTo from './proxy/send-to';
import { BordeauxMachineContext } from './types/context.types';
// import * as actions from './actions';

export default {
  [API_EVENTS_OUT.SET_ADTOOL_VERSION]: {
    actions: assign<SetAdToolVersionEvent>({
      adToolVersion: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_LOAD_GPT_EXTERNALLY]: {
    actions: assign<SetLoadGptExternallyEvent>({
      loadGptExternally: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.GPT_LOADED_EXTERNALLY]: {
    actions: ({ context, event }: ActionArgs<GptLoadedExternallyEvent>): void => {
      context.loadGptExternallyPromise.resolve(event.data);
    },
  },
  [API_EVENTS_OUT.SET_PREBID_ANALYTICS_ENABLED]: {
    actions: assign<SetPrebidAnalyticsEnabledEvent>({
      prebidAnalyticsEnabled: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_AUCTION_TIMEOUTS]: {
    actions: assign<SetAuctionTimeoutsEvent>({
      auctionTimeouts: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_AUTOMATIC_DYNAMIC]: {
    actions: assign<SetAutomaticDynamicEvent>({
      automaticDynamic: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_COMPANION_BOUNDS]: {
    actions: assign<SetCompanionBoundsEvent>({
      overrideCompanionBounds: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_EXPERIMENT_ID]: {
    actions: enqueueActions<SetExperimentIdEvent>(({ check, enqueue }) => {
      if (
        check(
          ({ context }: GuardArgs<SetExperimentIdEvent>): boolean =>
            context.experimentId.length === 0,
        )
      ) {
        enqueue(
          assign<SetExperimentIdEvent>({
            experimentId: ({ event }) => event.data,
          }),
        );
        enqueue(
          assign<SetExperimentIdEvent>({
            timing: ({ context }) => ({
              ...context.timing,
              enabled: true,
            }),
          }),
        );
        enqueue(ACTIONS_RECORD.EXPERIMENT_ID);
      } else {
        enqueue(({ context, event }: ActionArgs<SetExperimentIdEvent>): void => {
          log.warn(
            `Bordeaux experiment id has already been set to ${context.experimentId}, not setting ${event.data}`,
          );
        });
      }
    }),
  },
  [API_EVENTS_OUT.SET_PAGE_CATEGORY]: {
    actions: assign<SetPageCategoryEvent>({
      pageCategory: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_PAGE_TEMPLATE]: {
    actions: assign<SetPageTemplateEvent>({
      pageTemplate: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_REFRESH_TIME]: {
    actions: assign<SetRefreshTimeEvent>({
      refreshTime: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_ROADBLOCK_INCREMENTAL_CAPS]: {
    actions: assign<SetRoadblockIncrementalCapsEvent>({
      roadblockIncrementalCaps: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_ROADBLOCK_INCREMENTAL_CHOOSER]: {
    actions: assign<SetRoadblockIncrementalChooserEvent>({
      roadblockIncrementalChooser: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_ACTIVATION_DISTANCE]: {
    actions: assign<SetActivationDistanceEvent>({
      activationDistance: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_AVOIDANCE_DISTANCE]: {
    actions: assign<SetDeviceAvoidanceDistanceEvent>({
      deviceAvoidanceDistance: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_THIRD_PARTY_API_CONFIG]: {
    actions: assign<SetThirdPartyApiConfigEvent>({
      thirdPartyApiConfigOverrides: ({ event }) => event.data,
    }),
  },
  [API_EVENTS_OUT.SET_PAGE_TARGETING]: {
    actions: assign<SetPageTargetingEvent>({
      pageTargeting: ({ context, event }) => ({
        ...context.pageTargeting,
        ...event.data,
      }),
    }),
  },
  [API_EVENTS_OUT.ENABLE_FEATURE]: {
    actions: enqueueActions<EnableFeatureEvent>(({ check, enqueue }) => {
      if (
        check(
          ({ context, event }: GuardArgs<EnableFeatureEvent>): boolean =>
            !context.features[event.data],
        )
      ) {
        enqueue(
          assign<EnableFeatureEvent>({
            features: ({ context, event }) => ({
              ...context.features,
              [event.data]: true,
            }),
          }),
        );
        if (
          check(
            ({ event }: GuardArgs<EnableFeatureEvent>): boolean =>
              event.data === FEATURE.ADS_REFRESH,
          )
        ) {
          enqueue(
            sendTo<BordeauxMachineContext['automaticRefreshMachine'], EnableFeatureEvent>(
              ({ context }) => context.automaticRefreshMachine,
              {
                type: REFRESH_EVENTS.SET_FEATURE_ENABLED,
                data: true,
              },
            ),
          );
        } else if (
          check(
            ({ event }: GuardArgs<EnableFeatureEvent>): boolean =>
              event.data === FEATURE.ADS_STANDARD,
          )
        ) {
          enqueue(
            sendTo('slotify', {
              type: STANDARD_ADS_EVENTS_IN.ENABLED,
            }),
          );
        } else if (
          check(
            ({ event }: GuardArgs<EnableFeatureEvent>): boolean =>
              event.data === FEATURE.ADS_INCREMENTAL,
          )
        ) {
          enqueue(
            sendTo('slotify', {
              type: INCREMENTAL_ADS_EVENTS_IN.ENABLED,
            }),
          );
        }
      }
    }),
  },
  [API_EVENTS_OUT.DISABLE_FEATURE]: {
    actions: enqueueActions<DisableFeatureEvent>(({ check, enqueue }) => {
      if (
        check(
          ({ context }: GuardArgs<DisableFeatureEvent>): boolean => !context.featuresInitialised,
        )
      ) {
        enqueue(
          assign<DisableFeatureEvent>({
            features: ({ context, event }) => ({
              ...context.features,
              [event.data]: false,
            }),
          }),
        );
        if (
          check(
            ({ event }: GuardArgs<DisableFeatureEvent>): boolean =>
              event.data === FEATURE.ADS_REFRESH,
          )
        ) {
          enqueue(
            sendTo<BordeauxMachineContext['automaticRefreshMachine'], DisableFeatureEvent>(
              ({ context }) => context.automaticRefreshMachine,
              {
                type: REFRESH_EVENTS.SET_FEATURE_ENABLED,
                data: false,
              },
            ),
          );
        }
      } else {
        enqueue(({ event }: ActionArgs<DisableFeatureEvent>): void => {
          log.warn(`Cannot disable the ${event.data} feature after Bordeaux has initialised.`);
        });
      }
    }),
  },
  [API_EVENTS_OUT.SET_REFRESH_PAUSED]: {
    actions: sendTo<BordeauxMachineContext['automaticRefreshMachine'], SetRefreshPausedEvent>(
      ({ context }) => context.automaticRefreshMachine,
      ({ event }): SetExternalRefreshPausedEvent => ({
        type: REFRESH_EVENTS.SET_REFRESH_PAUSED,
        data: event.data,
      }),
    ),
  },
  [API_EVENTS_OUT.SET_FALLBACK_RESPONSES]: {
    actions: assign<SetFallbackResponsesEvent>({
      fallbackResponses: ({ context, event }) => ({
        ...context.fallbackResponses,
        ...event.data,
      }),
    }),
  },
  [API_EVENTS_OUT.REQUEST_HANDLE_DYNAMIC_SLOTS]: {
    actions: sendTo('slotify', {
      type: SLOTIFY_EVENTS.FIND_NEW_DYNAMIC_SLOTS,
    }),
  },
  [API_EVENTS_OUT.ADD_UNREFRESHABLE_NAMES]: {
    actions: assign<AddUnrefreshableNamesEvent>({
      unrefreshableNames: ({ context, event }) => [...context.unrefreshableNames, ...event.data],
    }),
  },
};
