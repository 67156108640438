import prom, { Labels, CounterType, RegistryType } from '@future/promjs/lib';

import hasParam from 'utils/has-param';
import { getEnv } from 'utils/env';
import isDevEnv from 'utils/dev-env';
import sendBeacon from 'utils/send-beacon';
import log from 'log';

import { BrowserOptions } from '../parameters/index.types';
import device from '../parameters/device';
import browser from '../parameters/browser';
import site from '../parameters/site';
import { PrometheusAPI } from './index.types';
import { PROMETHEUS_PUSH_INTERVAL, PROMETHEUS_SAMPLE_RATE, ACCEPTABLE_BROWSERS } from './config';

const env = getEnv();

const labels: { [key: string]: string } = {
  env: '',
  device: '',
  browser: '',
  site: '',
  version: process.env.npm_package_version || '',
};

let metricsUpdated = false;
let metricsEnabled = false;
function shouldPush(): boolean {
  return metricsUpdated && metricsEnabled;
}

let adsLoaded = false;
let configLoaded = false;
let thirdPartiesLoaded = false;

const registry: RegistryType = prom();
const metricRequest: CounterType = registry.create('counter', 'request', 'Request counter');
const metricCrash: CounterType = registry.create('counter', 'crash', 'Crash counter');
const metricBlock: CounterType = registry.create('counter', 'block', 'Block counter');
const metricDisable: CounterType = registry.create('counter', 'disable', 'Disable counter');
const metricLoad: CounterType = registry.create('counter', 'load', 'Load counter');
const metricLoadFail: CounterType = registry.create('counter', 'load_fail', 'Load fail counter');
const metricTimeout: CounterType = registry.create('counter', 'timeout', 'Timeout counter');
const metricUnload: CounterType = registry.create('counter', 'unload', 'Unload counter');

const pushMetrics = (): void => {
  if (!shouldPush()) {
    return;
  }

  const url = 'https://bordeaux-gateway.futureplc.com/push_metrics/';
  const params: string = registry.metrics();
  const sent = sendBeacon(url, params);
  if (sent) {
    metricsUpdated = false;
    registry.reset();
  } else {
    log.error('Error pushing Bordeaux metrics data.');
  }
};

const handleUnload = (): void => {
  if (!configLoaded) {
    const configLabels: Labels = { target: 'config', ...labels };
    metricsUpdated = true;
    metricUnload.inc(configLabels);
  } else if (!thirdPartiesLoaded) {
    const thirdPartyLabels: Labels = { target: 'thirdparties', ...labels };
    metricsUpdated = true;
    metricUnload.inc(thirdPartyLabels);
  } else if (!adsLoaded) {
    const adsLabels: Labels = { target: 'ads', ...labels };
    metricsUpdated = true;
    metricUnload.inc(adsLabels);
  }

  pushMetrics();
};

const recordFrameworkRequest = (): void => {
  const frameworkLabels: Labels = { target: 'bordeaux', ...labels };
  metricsUpdated = true;
  metricRequest.inc(frameworkLabels);
};

const recordConfigLoad = (): void => {
  const configLabels: Labels = { target: 'config', ...labels };
  configLoaded = true;
  metricsUpdated = true;
  metricLoad.inc(configLabels);
};

const recordThirdPartiesLoad = (): void => {
  const thirdPartyLabels: Labels = { target: 'thirdparties', ...labels };
  thirdPartiesLoaded = true;
  metricsUpdated = true;
  metricLoad.inc(thirdPartyLabels);
};

const recordAdsBlock = (): void => {
  const adsLabels: Labels = { target: 'ads', ...labels };
  metricsUpdated = true;
  metricBlock.inc(adsLabels);
};

const recordAdsDisable = (): void => {
  const adsLabels: Labels = { target: 'ads', ...labels };
  metricsUpdated = true;
  metricDisable.inc(adsLabels);
};

const recordConfigLoadFail = (): void => {
  const adsLabels: Labels = { target: 'config', ...labels };
  metricsUpdated = true;
  metricLoadFail.inc(adsLabels);
};

const recordConfigTimeout = (): void => {
  const adsLabels: Labels = { target: 'config', ...labels };
  metricsUpdated = true;
  metricTimeout.inc(adsLabels);
};

const recordConfigEmpty = (): void => {
  const adsLabels: Labels = { target: 'config', ...labels };
  metricsUpdated = true;
  metricBlock.inc(adsLabels);
};

const recordConfigParseError = (): void => {
  const adsLabels: Labels = { target: 'config', ...labels };
  metricsUpdated = true;
  metricCrash.inc(adsLabels);
};

const recordAdsLoad = (): void => {
  const adsLabels: Labels = { target: 'ads', ...labels };
  adsLoaded = true;
  metricsUpdated = true;
  metricLoad.inc(adsLabels);
};

const recordFrameworkCrash = (): void => {
  const frameworkLabels: Labels = { target: 'bordeaux', ...labels };
  metricsUpdated = true;
  metricCrash.inc(frameworkLabels);
};

const acceptBrowserOrUnknown = (browserName: string): BrowserOptions => {
  const name = browserName.toLowerCase() as BrowserOptions;
  if (ACCEPTABLE_BROWSERS.indexOf(name) === -1) {
    return 'unknown browser' as BrowserOptions;
  }
  return name;
};

const setupLabels = (): void => {
  labels.env = isDevEnv ? 'dev' : 'prod';
  labels.device = device();
  labels.browser = acceptBrowserOrUnknown(browser());
  labels.site = site();
};

const setupMetricsEnabled = (): void => {
  const randomlySampled: boolean = Math.random() < PROMETHEUS_SAMPLE_RATE;
  const forced: boolean = hasParam('force_metrics');
  metricsEnabled = randomlySampled || forced;
};

export default (): PrometheusAPI => {
  setupLabels();
  setupMetricsEnabled();

  env.setInterval(pushMetrics, PROMETHEUS_PUSH_INTERVAL);
  env.addEventListener('pagehide', handleUnload);
  // NOTE: visibilityState changing to 'hidden' is not handled correctly by Safari
  env.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      pushMetrics();
    }
  });

  const prometheusAPI: PrometheusAPI = {
    recordFrameworkRequest,
    recordFrameworkCrash,
    recordAdsBlock,
    recordAdsDisable,
    recordAdsLoad,
    recordConfigLoad,
    recordConfigLoadFail,
    recordConfigTimeout,
    recordConfigEmpty,
    recordConfigParseError,
    recordThirdPartiesLoad,
  };

  return prometheusAPI;
};
