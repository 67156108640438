import { Ad, AdUnitSize, AdUnitSizeTuple, AdUnitSizes } from 'ad-framework/ad/index.types';
import getSite from 'parameters/site';
import DataObject from 'state/data-object';
import BordeauxMachineService from 'state/types/service.types';
import { Partner, REPORT_AUCTION_PARTNER } from 'state/types/report.types';
import {
  BidderParamGetter,
  BidderParams,
  BidderParamValue,
  PreBidAdUnit,
  PrebidBidConfig,
  PreBidBidderOptions,
  PreBidResponseUnits,
  SitePrebidConfig,
} from 'third-party-apis/prebid/index.types';
import { Targeting } from 'ad-framework/targeting/index.types';
import { timeoutAdjustmentForPrebid } from 'third-party-apis/utils/timeout';
import { getEnv } from 'utils/env';
import { timeData } from 'utils/timestamp';
import parseIabSegmentIds from 'utils/pre-process-ads/parse-iab-segment-ids';
import { filterInvalidSizes } from 'utils/pre-process-ads/size-filtering';

const env = getEnv();

const defaultParams: Partial<
  Record<keyof SitePrebidConfig, Record<string, BidderParamValue | BidderParamGetter<BidderParams>>>
> = {
  ix: {
    sizes: (_params: BidderParams, ad: DataObject<Ad>) => normalizeSizes(ad.getProperty('sizes')),
  },
};

const isBidderParamGetter = (
  value: BidderParamValue | BidderParamGetter,
): value is BidderParamGetter => typeof value === 'function';

const isPreBidSize = (size: AdUnitSize): size is AdUnitSizeTuple =>
  Array.isArray(size) &&
  size.length === 2 &&
  typeof size[0] === 'number' &&
  typeof size[1] === 'number';

const normalizeSizes = (sizes: AdUnitSizes): Array<AdUnitSizeTuple> =>
  sizes?.filter(isPreBidSize).filter(filterInvalidSizes) || [];

const extraBidTargeting = (
  ad: DataObject<Ad>,
  pageTargeting: Targeting,
  bidder?: string,
): Record<string, any> | void => {
  const keys = ['pos', '_slot_type', '_slot', 'refresh', 'incremental', 'additional'];
  const targeting = ad.getProperty('targeting');

  const pageKeys = [
    'url',
    'category',
    'child_category',
    'grandchild_category',
    'kw',
    'iabCategories',
    'iabCategoryIds',
    'fepCategory',
    'fepGroups',
    'fepPrimaryCompany',
    'fepCompany',
    'fepPrimaryProduct',
    'fepSecondaryProducts',
    'advSegments',
    'advSegmentsArtDists',
    'advSegmentsMaxDists',
  ];

  const combinedTargeting = Object.assign(targeting, pageTargeting);
  const combinedKeys: Array<string> = keys.concat(pageKeys);

  const { prebid } = env;

  if (prebid) {
    if (combinedTargeting.iabCategoryIds) {
      prebid.mergeConfig({
        ortb2: {
          site: {
            content: {
              data: [
                {
                  name: 'textrazor.com', // who resolved the segments
                  ext: { segtax: 7 },
                  segment: parseIabSegmentIds(combinedTargeting.iabCategoryIds) || [],
                },
              ],
            },
          },
        },
      });
    }

    switch (bidder) {
      case 'ix': {
        const ixKeys: Array<string> = pageKeys.concat('cft_label_name', 'cft_enabled_apis');

        const ixTargeting = Object.fromEntries(
          Object.entries(combinedTargeting)
            .filter(([key]) => ixKeys.includes(key))
            .map(([k, v]) => [k, typeof v === 'string' ? v : v.toString()]),
        );

        return prebid.mergeConfig({
          ortb2: {
            user: {
              ext: {
                data: ixTargeting,
              },
            },
          },
        });
      }

      case 'pubmatic': {
        const pubmaticTargeting = Object.entries(combinedTargeting)
          .filter(([key]) => combinedKeys.includes(key))
          .map(([k, v]) => `${k}=${v}`)
          .join('|');

        return prebid.mergeConfig({
          dctr: pubmaticTargeting,
        });
      }

      case 'appnexus': {
        const appNexusTargeting = Object.fromEntries(
          Object.entries(combinedTargeting)
            .filter(([key]) => combinedKeys.includes(key))
            .map(([key, value]) => [key, Array.isArray(value) ? value : [value]]),
        );

        return { keywords: appNexusTargeting };
      }

      default:
        break;
    }
  }
};

const bidderBids =
  (siteConfig: SitePrebidConfig, ad: DataObject<Ad>, pageTargeting: Targeting) =>
  (bidder: string): Array<PreBidBidderOptions> =>
    siteConfig[bidder]
      .filter(
        ({ sizes }: PrebidBidConfig) =>
          sizes === 'all' ||
          normalizeSizes(ad.getProperty('sizes')).some(
            ([width, height]: AdUnitSizeTuple): boolean =>
              sizes.some(
                ([hasWidth, hasHeight]: AdUnitSizeTuple): boolean =>
                  width === hasWidth && height === hasHeight,
              ),
          ),
      )
      .map((bid: PrebidBidConfig) => ({
        bidder,
        params: {
          ...(defaultParams[bidder]
            ? Object.fromEntries(
                Object.entries<BidderParamValue | BidderParamGetter>(
                  defaultParams[bidder] || {},
                ).map(([key, value]): [string, BidderParamValue] => [
                  key,
                  isBidderParamGetter(value) ? value(bid.params, ad) : value,
                ]),
              )
            : {}),
          ...extraBidTargeting(ad, pageTargeting, bidder),
          ...bid.params,
        },
      }));

export default (
  service: BordeauxMachineService,
  floorPrices: Record<string, number> | null,
  useFloorPricesForPrebid: boolean,
  sitePrebidConfig: SitePrebidConfig,
  ads: Array<DataObject<Ad>>,
  timeout: number,
  id: number,
  pageTargeting: Targeting,
): Promise<string> =>
  new Promise(resolveBase => {
    const { prebid } = env;
    if (!prebid) {
      resolveBase('Error, missing prebid');
      return;
    }

    service.send({
      type: REPORT_AUCTION_PARTNER.REQUEST,
      data: {
        time: timeData(),
        auction: id,
        partner: Partner.PREBID,
      },
    });

    let resolved = false;
    const resolve = (value: string): void => {
      resolved = true;
      resolveBase(value);
    };
    const resolveTimedOut = (): void => {
      service.send({
        type: REPORT_AUCTION_PARTNER.TIMEOUT,
        data: {
          time: timeData(),
          auction: id,
          partner: Partner.PREBID,
        },
      });

      resolve('Timeout');
    };

    setTimeout(() => {
      if (resolved) return;
      resolveTimedOut();
    }, timeout);

    const adUnits = buildAdUnits(
      ads,
      sitePrebidConfig,
      pageTargeting,
      floorPrices,
      useFloorPricesForPrebid,
    );

    const newAdUnits = adUnits.filter(
      adUnit => !prebid.adUnits.some((unit: PreBidAdUnit) => unit.code === adUnit.code),
    );

    if (newAdUnits.length > 0) prebid.addAdUnits(newAdUnits);

    const timeoutForPrebid = timeout - timeoutAdjustmentForPrebid;
    prebid.requestBids({
      timeout: timeoutForPrebid,
      adUnits,
      bidsBackHandler: (bids: PreBidResponseUnits, timedOut: boolean) => {
        if (resolved) return;
        const bidUnits = Object.keys(bids);
        if (timedOut && bidUnits.length === 0) {
          resolveTimedOut();
          return;
        }

        prebid.setTargetingForGPTAsync(bidUnits);

        service.send({
          type: REPORT_AUCTION_PARTNER.SUCCESS,
          data: {
            time: timeData(),
            auction: id,
            partner: Partner.PREBID,
            bids,
          },
        });

        resolve('Complete');
      },
    });
  });

export function buildAdUnits(
  ads: DataObject<Ad>[],
  sitePrebidConfig: SitePrebidConfig,
  pageTargeting: Targeting,
  floorPrices: Record<string, number> | null,
  useFloorPricesForPrebid: boolean,
): PreBidAdUnit[] {
  const floors =
    getSite() === 'whathifi' && useFloorPricesForPrebid && floorPrices
      ? {
          floors: {
            currency: 'USD',
            schema: {
              delimiter: '|',
              fields: ['size'],
            },
            values: floorPrices,
          },
        }
      : {};

  return ads.flatMap((ad: DataObject<Ad>): PreBidAdUnit[] => {
    const sizes = normalizeSizes(ad.getProperty('sizes'));

    if (sizes.length === 0) {
      return [];
    }

    const slotTargeting = Object.entries(ad.getProperty('targeting'))
      .filter(([key, _value]) =>
        ['pos', '_slot_type', '_slot', 'refresh', 'incremental', 'additional'].includes(key),
      )
      .map(([key, value]) => [key, Array.isArray(value) ? value : [value]]);

    return [
      {
        code: ad.getProperty('adUnitPath'),
        sizes,
        mediaTypes: {
          banner: {
            sizes,
          },
        },
        ortb2Imp: {
          ext: {
            data: Object.fromEntries(slotTargeting),
            ae: 1,
          },
        },
        ...floors,
        bids: ([] as Array<PreBidBidderOptions>).concat(
          ...Object.keys(sitePrebidConfig).map(bidderBids(sitePrebidConfig, ad, pageTargeting)),
        ),
      },
    ];
  });
}
