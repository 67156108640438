import { BordeauxMachineContext } from 'state/types/context.types';
import AnyBordeauxEvent from 'state/types/any-event.types';
import { ParameterizedObject, raise as genericRaise } from 'xstate';

const raise = <
  E extends AnyBordeauxEvent = AnyBordeauxEvent,
  S extends AnyBordeauxEvent = AnyBordeauxEvent,
>(
  ...args: Parameters<
    typeof genericRaise<BordeauxMachineContext, E, S, ParameterizedObject['params']>
  >
) => genericRaise<BordeauxMachineContext, E, S, ParameterizedObject['params']>(...args);

export default raise;
