import { UserSyncElement } from 'user-sync/index.types';
import { getGDPRData, getUserSyncURL } from './utils';
import { UserSyncConfig } from '../../state/types/context.types';

const id = '22';
const openrtb: UserSyncConfig = {
  element: UserSyncElement.IMAGE,
  id,
  bidderName: 'openrtb',
  iabIdList: [69],
  url: 'https://us-u.openx.net/w/1.0/cm',
  params: {
    id: 'de2d90e5-4d26-4c8c-a342-3edcde51fdb1',
    ph: '25af9286-f23b-4b02-abcd-f2ee3b564dab',
    r: getUserSyncURL({ id }),
  },
  dynamicParams: context => {
    const gdprData = getGDPRData(context.gdprConsent);
    return {
      ...(gdprData
        ? {
            gdpr: '1',
            gdpr_consent: gdprData,
          }
        : {
            gdpr: '0',
            gdpr_consent: '',
          }),
    };
  },
};
export default openrtb;
