import log from 'log';
import DataObjectStore from 'state/data-object-store';
import throttle from 'utils/throttle';
import requestRefresh from 'ad-framework/refresh/api';
import { Ad, AdUnitCategory, AdUnitMode, AdUnitStatus } from 'ad-framework/ad/index.types';
import { Slot } from 'ad-framework/slot/index.types';
import { ApiMachineEventService } from './events.service';

export default throttle(
  async (service: ApiMachineEventService, adUnitNames: Array<string> = [], inView = true) => {
    if (!Array.isArray(adUnitNames)) {
      log.error(`First argument to refresh must be a list of strings, ${typeof adUnitNames} given`);
      return;
    }
    if (typeof inView !== 'boolean') {
      log.error(`Second argument to refresh must be a boolean, ${typeof inView} given`);
      return;
    }
    await service.waitFor.thirdParties;

    const [
      ads,
      slots,
      unrefreshableNames,
      unrefreshableStatuses,
      unrefreshableModes,
      unrefreshableCategories,
      unrefreshableOrders,
      unrefreshableLineItems,
      unrefreshableAdvertisers,
      anchoredRefreshDisabled,
    ] = await Promise.all([
      service.getData<DataObjectStore<Ad>>('ads'),
      service.getData<DataObjectStore<Slot>>('slots'),
      service.getData<Array<string>>('unrefreshableNames'),
      service.getData<Array<AdUnitStatus>>('unrefreshableStatuses'),
      service.getData<Array<AdUnitMode>>('unrefreshableModes'),
      service.getData<Array<AdUnitCategory>>('unrefreshableCategories'),
      service.getData<Array<number>>('unrefreshableOrders'),
      service.getData<Array<number>>('unrefreshableLineItems'),
      service.getData<Array<number>>('unrefreshableAdvertisers'),
      service.getData<boolean>('anchoredRefreshDisabled'),
    ]);

    requestRefresh(
      {
        ads,
        slots,
        unrefreshableNames,
        unrefreshableStatuses,
        unrefreshableModes,
        unrefreshableCategories,
        unrefreshableOrders,
        unrefreshableLineItems,
        unrefreshableAdvertisers,
        anchoredRefreshDisabled,
      },
      adUnitNames,
      inView,
    );
  },
  10000,
);
