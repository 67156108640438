import { Targeting } from 'ad-framework/targeting/index.types';
import log from 'log';
import { API_EVENTS_OUT } from './events.names';
import { ApiMachineEventService } from './events.service';

export default (service: ApiMachineEventService, targetingData: Targeting): void => {
  if (typeof targetingData !== 'object' || Array.isArray(targetingData) || targetingData == null) {
    log.error(`Attempted to set targeting with ${JSON.stringify(targetingData)}`);
    return;
  }

  const cleanTargetingData = Object.fromEntries(
    Object.entries(targetingData).map(([key, value]) => {
      if (value === null || value === undefined || typeof value === 'boolean') {
        return [key, String(value)];
      }
      return [key, value];
    }),
  );

  service.sendEvent({
    type: API_EVENTS_OUT.SET_PAGE_TARGETING,
    data: cleanTargetingData,
  });
};
