import { AdDefinition } from './index.types';

const autoIncrementMap: Record<string, Record<string, number>> = {};
const autoIncrementMarker = '${autoincrement}';
const autoIncrementTargeting = (adUnit: AdDefinition): Record<string, Array<string>> =>
  Object.fromEntries(
    Object.entries(adUnit.targeting)
      .filter((entry): entry is [string, Array<string>] => Array.isArray(entry[1]))
      .map(([key, values]) => [
        key,
        values.map((value: string) => {
          if (value.includes(autoIncrementMarker)) {
            if (!autoIncrementMap[adUnit.name]) {
              autoIncrementMap[adUnit.name] = {};
            }

            if (!autoIncrementMap[adUnit.name][key]) {
              autoIncrementMap[adUnit.name][key] = 1;
            }

            const newValue = value.replace(
              autoIncrementMarker,
              autoIncrementMap[adUnit.name][key].toString(),
            );
            autoIncrementMap[adUnit.name][key]++;

            return newValue;
          }

          return value;
        }),
      ]),
  );

export default autoIncrementTargeting;
