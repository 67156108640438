import { getEnv } from 'utils/env';

export const createElement = (element: string, props: object = {}): HTMLElement => {
  const env = getEnv();
  const elementObj = env.document.createElement(element);
  elementObj.style.display = 'none';
  Object.entries(props).forEach(([prop, value]) => {
    elementObj[prop] = value;
  });
  return elementObj;
};
