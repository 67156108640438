import { Ad } from 'ad-framework/ad/index.types';
import DataObject from 'state/data-object';
import { GPTOutput } from './gpt.types';

export default function isAnchoredHideable(
  ads: Array<DataObject<Ad>>,
  anchoredLineItems: Array<number> | undefined,
): boolean {
  if (anchoredLineItems) {
    return ads
      .map(ad => ad.getProperty('gptOutput'))
      .filter((gptOutput): gptOutput is GPTOutput => !!gptOutput)
      .map(gptOutput => gptOutput.lineItem)
      .some(lineItem => anchoredLineItems.includes(lineItem));
  }
  return false;
}
