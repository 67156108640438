import { stringToStyle } from 'utils/style';
import { getEnv } from 'utils/env';
import { isEmpty } from 'ramda';
import DataObject from 'state/data-object';
import { Ad } from './index.types';

const env = getEnv();

const LABEL_TEXT = 'Advertisement';
export const LABEL_STYLE = `font-size: 12px; width: 100%; height: 30px; text-align: center; line-height: 30px; z-index: 1; background-color: #EDEDED; position: relative;`;
export const LABEL_IDENTIFIER = '-label';

const createDivWithStyles = (
  styles: Record<string, string> | CSSStyleDeclaration,
): HTMLDivElement => {
  const element = env.document.createElement('div');
  Object.assign(element.style, styles);
  return element;
};

export default (ad: DataObject<Ad>): HTMLDivElement | null => {
  const adElement = ad.getProperty('element');
  const label = ad.getProperty('label');
  if (!adElement || !label || isEmpty(label)) {
    return null;
  }
  const labelElement = createDivWithStyles(stringToStyle(label.style || LABEL_STYLE));

  labelElement.id = `${adElement.id}${LABEL_IDENTIFIER}`;
  labelElement.textContent = label.text || LABEL_TEXT;

  return labelElement;
};
