import { SommelierResponseSlot } from 'ad-framework/slot/sommelier-response.types';

import { SlotPosition, SlotType } from 'ad-framework/slot/index.types';
import { MPU_WIDTH, DOUBLE_MPU_HEIGHT } from '../../constants';

export default {
  name: `mpu-paragraph`,
  type: SlotType.BLOCK,
  hook: '#article-body > p',
  position: SlotPosition.BEFORE,
  size: { w: MPU_WIDTH, h: DOUBLE_MPU_HEIGHT },
  properties: {
    style: {
      margin: `5px -325px 10px 25px`,
      position: 'absolute',
      right: '0',
    },
    config: {
      multiple: true,
    },
  },
} as SommelierResponseSlot;
