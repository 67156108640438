import { getEnv } from 'utils/env';

import { PerformanceAPI, PerformanceFilterFunction, PerformanceFilterFactory } from './index.types';

const nameIs: PerformanceFilterFactory =
  (name: string): PerformanceFilterFunction =>
  ({ name: entryName }: PerformanceEntry): boolean =>
    entryName === name;
const typeIs: PerformanceFilterFactory =
  (type: string): PerformanceFilterFunction =>
  ({ entryType }: PerformanceEntry): boolean =>
    entryType === type;

const functionExists = (functionName: string): boolean => {
  const env = getEnv();

  if (!env.performance) {
    return false;
  }

  return performance[functionName] instanceof Function;
};

export const now = (): number => {
  const env = getEnv();

  if (functionExists('now')) {
    return env.performance.now();
  }

  return 0;
};

const getEntries = (): PerformanceEntry[] => {
  const env = getEnv();

  if (functionExists('getEntries')) {
    return env.performance.getEntries();
  }
  return [];
};

export const getEntriesByType = (type: string): PerformanceEntry[] => {
  const env = getEnv();

  if (functionExists('getEntriesByType')) {
    return env.performance.getEntriesByType(type);
  }

  return getEntries().filter(typeIs(type));
};

export const getEntriesByName = (name: string, type: string | undefined): PerformanceEntry[] => {
  const env = getEnv();

  if (functionExists('getEntriesByName')) {
    return env.performance.getEntriesByName(name, type);
  }

  const nameCheck = nameIs(name);
  if (type !== undefined) {
    return getEntriesByType(type).filter(nameCheck);
  }

  return getEntries().filter(nameCheck);
};

export default (): PerformanceAPI => {
  const performanceAPI: PerformanceAPI = {
    now,
    getEntries,
    getEntriesByType,
    getEntriesByName,
  };

  return performanceAPI;
};
