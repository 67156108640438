import cookies from 'utils/cookies';
import log from 'log';
import { BordeauxMachineContext } from 'state/types/context.types';
import { getUuid } from './ad-server/utils';

const getAdServerId = async (): Promise<string | null> => {
  const controller = new AbortController();
  const timeout = setTimeout(() => controller.abort('fetch request timed out'), 500);

  const data = await fetch(`https://ads.servebom.com/hybrid_id`, {
    method: 'GET',
    credentials: 'include',
    signal: controller.signal,
  });

  const deserializedData: { uuid: string } = await data.json();
  if (deserializedData.uuid) {
    clearTimeout(timeout);
    return deserializedData.uuid;
  }

  clearTimeout(timeout);
  return null;
};

const processFallbackId = (cookieDuration = 6408000): string => {
  const firstPartyId = getUuid();
  cookies.set('h_id', firstPartyId, cookieDuration);
  return firstPartyId;
};

export default async (
  context: Pick<BordeauxMachineContext, 'pageParameters'>,
): Promise<string | null> => {
  const idCookie = cookies.get('h_id');
  if (idCookie) return idCookie;
  if (context.pageParameters.browser !== 'chrome') return processFallbackId();

  try {
    const thirdPartyId = await getAdServerId();
    if (!thirdPartyId) return processFallbackId(86400);
    cookies.set('h_id', thirdPartyId, 6408000);
    return thirdPartyId;
  } catch (e) {
    if (e instanceof Error) {
      log.warn(`Error "${e.name}" while fetching hybrid id.`);
    }

    return processFallbackId(86400);
  }
};
