import { ThirdPartyAPIMachineConfigs } from 'state/types/context.types';
import loadScript from 'third-party-apis/utils/load-script';
import PartialPick from 'utils/partial-pick.types';
import { ThirdParty } from '../config.types';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.TMT], 'thirdParty'> = {
  thirdParty: ThirdParty.TMT,
  scriptLocation:
    'https://scripts.webcontentassessor.com/scripts/672c7439746c027624681966fbf9cfc708eb07a0cfd48f4744b2de494216a7ee',
  methods: {
    loadScript: (scriptLocation): Promise<void> =>
      loadScript(scriptLocation, 'bordeaux-tmt', { async: true }),
  },
};
export default config;
