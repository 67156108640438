import log from 'log';
import { SentryException } from './index.types';
import * as Sentry from './minimal'; // minimal implementation of @Sentry/browser
import urlDenyList from './url-denylist';

let reportingEnabled = true;
const dsn = 'https://899032cf20894ca3ac193916e76d1ff5@o362810.ingest.sentry.io/5227073';
const releaseVersion = `bordeaux@${process.env.npm_package_version}`;
const environment = (process.env.NODE_ENV || 'production') as 'production' | 'development' | 'test';
const isDevEnv = environment === 'development';
const enableDebug = isDevEnv;
const disabledDefaultIntegrations: string[] = ['LinkedErrors', 'GlobalHandlers'];

const bordeauxURLs = [/^https?:\/\/bordeaux\.futurecdn\.net\/bordeaux\.js/];

const thirdPartyAPIs = [
  /^https?:\/\/c\.amazon-adsystem\.com\/aax2\/apstag\.js/,
  /^https?:\/\/securepubads\.g\.doubleclick.net\/tag\/js\/gpt\.js/,
  /^https?:\/\/cdn\.adsafeprotected\.com\/iasPET\.1\.js/,
  /^https?:\/\/js-sec\.indexww\.com\/ht\/p/,
];

const validErrorObject = ({ type, stacktrace }): boolean =>
  type.toLowerCase() === 'error' && stacktrace && stacktrace.length !== 0;

const preProcessError = (event: SentryException): SentryException | null => {
  if (!event.exception.values.find(validErrorObject)) {
    return null;
  }

  const { user: _user, ...rest } = event;
  return rest;
};

Sentry.init({
  dsn,
  environment,
  debug: enableDebug,
  release: releaseVersion,
  denyUrls: [...urlDenyList.URLs],
  allowUrls: [...bordeauxURLs, ...thirdPartyAPIs],
  beforeSend: preProcessError,
  integrations: integrations =>
    integrations.filter(({ name }) => !disabledDefaultIntegrations.includes(name)),
});

const extraData = (extraTags: Record<string, string> = {}): void => {
  Sentry.configureScope(scope => {
    Object.entries(extraTags).forEach(([tagName, tagValue]) => {
      scope.setTag(tagName, tagValue);
    });
  });
};

export const reportError = (error: string | Error): void => {
  if (!reportingEnabled) {
    return;
  }
  Sentry.captureException(error);
};

const breadcrumb = ({
  message,
  category = 'ui',
  level = Sentry.Severity.Info,
}: {
  message: string;
  category: string;
  level?: string;
}): void => {
  if (message === undefined) {
    log.error('You must provide a message to a Sentry breadcrumb');
    return;
  }
  Sentry.addBreadcrumb({
    category,
    message,
    level,
  });
};

const disableReporting = (): void => {
  reportingEnabled = false;
};

export default {
  extraData,
  reportError,
  breadcrumb,
  disableReporting,
};
