import unique from 'utils/unique';
import { FilterFunction, ResourcesAPI, ResourceEvent } from './index.types';
import { getEntriesByType } from './performance';

const resource = 'resource';
const createResource = (
  performanceMark: PerformanceResourceTiming,
  origin = 'Bordeaux',
): ResourceEvent => ({
  id: unique(),
  type: resource,
  time: performanceMark.startTime,
  object: performanceMark,
  origin,
  message: performanceMark.name,
  duration: performanceMark.duration,
});

let knownLength = 0;
const knownItems: ResourceEvent[] = [];

const updateResources = (): void => {
  const allMarks: PerformanceResourceTiming[] = getEntriesByType(
    'resource',
  ) as PerformanceResourceTiming[];

  const unknownMarks = allMarks.slice(knownLength);
  if (unknownMarks.length === 0) {
    return;
  }

  const newLength: number = allMarks.length;
  knownLength = newLength;

  unknownMarks.forEach(mark => {
    const eventObject: ResourceEvent = createResource(mark, 'System');
    knownItems.push(eventObject);
  });
};

const getResources = (type?: string, name?: string): ResourceEvent[] => {
  updateResources();
  const filters: FilterFunction[] = [];
  if (type !== undefined) {
    filters.push((resource: ResourceEvent): boolean => resource.object.initiatorType === type);
  }
  if (name !== undefined) {
    filters.push((resource: ResourceEvent): boolean => resource.object.name.indexOf(name) > -1);
  }
  return knownItems.filter(
    (resource: ResourceEvent) => !filters.find(filter => filter(resource) === false),
  );
};

export default (): ResourcesAPI => {
  const resourcesAPI: ResourcesAPI = {
    getResources,
  };

  return resourcesAPI;
};
