import { SommelierResponseSlot } from 'ad-framework/slot/sommelier-response.types';

import { SlotPosition, SlotType } from 'ad-framework/slot/index.types';
import {
  SMALL_LEADERBOARD_WIDTH,
  SMALL_LEADERBOARD_HEIGHT,
  LARGE_LEADERBOARD_WIDTH,
  LARGE_LEADERBOARD_HEIGHT,
} from '../../constants';

export const leaderboardSlot: SommelierResponseSlot = {
  name: 'leaderboard',
  hook: '#top-leaderboard, .dfp-leaderboard-container',
  type: SlotType.BLOCK,
  position: SlotPosition.PREPEND,
  size: {
    w: LARGE_LEADERBOARD_WIDTH,
    h: LARGE_LEADERBOARD_HEIGHT,
  },
  properties: {
    style: {
      marginLeft: 'auto',
      marginRight: 'auto',
      clear: 'both',
      marginTop: '20px',
      marginBottom: '20px',
    },
  },
};

export const titleLeaderboardSlots: SommelierResponseSlot = {
  name: `title-leaderboard`,
  hook: 'h3, h2',
  type: SlotType.BLOCK,
  position: SlotPosition.BEFORE,
  size: {
    w: SMALL_LEADERBOARD_WIDTH,
    h: SMALL_LEADERBOARD_HEIGHT,
  },
  properties: {
    style: {
      clear: 'both',
      marginTop: '20px',
      marginBottom: '20px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    config: {
      multiple: true,
    },
  },
};

export const mediaLeaderboardSlots: SommelierResponseSlot = {
  name: `media-leaderboard`,
  hook: '.article__body > [data-bordeaux-image-check], .article__body > .inlinegallery',
  type: SlotType.BLOCK,
  position: SlotPosition.AFTER,
  size: {
    w: SMALL_LEADERBOARD_WIDTH,
    h: SMALL_LEADERBOARD_HEIGHT,
  },

  properties: {
    style: {
      clear: 'both',
      marginTop: '20px',
      marginBottom: '20px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    config: {
      multiple: true,
    },
  },
};

export const staticLeaderboardSlots: SommelierResponseSlot = {
  name: `leaderboard`,
  hook: '.leaderboard__container, .static-leaderboard',
  type: SlotType.BLOCK,
  position: SlotPosition.APPEND,
  size: {
    w: LARGE_LEADERBOARD_WIDTH,
    h: LARGE_LEADERBOARD_HEIGHT,
  },
  properties: {
    config: {
      multiple: true,
    },
  },
};
