import { z } from 'zod';
import truthyString from 'ad-framework/generic/truthy-string';
import stringyList from 'ad-framework/generic/stringy-list';
import stringyNumber from 'ad-framework/generic/stringy-number';
import { SlotLabelPosition, SlotPosition, SlotType } from './index.types';

export const slotTypeSchema = z.union([
  z.literal(SlotType.BLOCK),
  z.literal(SlotType.INLINE),
  z.literal(SlotType.INLINE_LEFT),
  z.literal(SlotType.INLINE_RIGHT),
  z.literal(SlotType.INLINE_LEFT_RIGHT),
  z.literal(SlotType.GENERATED),
]);

export const slotPositionSchema = z.union([
  z.literal(SlotPosition.BEFORE),
  z.literal(SlotPosition.AFTER),
  z.literal(SlotPosition.PREPEND),
  z.literal(SlotPosition.APPEND),
]);

export const slotLabelPositionSchema = z.union([
  z.literal(SlotLabelPosition.ABOVE),
  z.literal(SlotLabelPosition.BELOW),
]);

export const slotLabelSchema = z
  .object({
    text: z.string().optional(),
    style: z.string().optional(),
    position: slotLabelPositionSchema.optional(),
    backgroundColor: z.string().optional(),
    applyLabelToAds: z.string().optional(),
  })
  .default({});

export const companionBoundSchema = z.number().or(z.literal('screenheight'));
export const companionBoundsSchema = z
  .object({ above: companionBoundSchema.optional(), below: companionBoundSchema.optional() })
  .default({});

export const slotConfigSchema = z
  .object({
    multiple: truthyString,
    ignoreExclusion: truthyString,
    fluid: truthyString,
    allowList: stringyList,
    ignoreErrors: truthyString,
    additionalAvoidance: stringyList.transform(hooks =>
      hooks.map(hook => ({
        hook,
        elements: [],
      })),
    ),
    sponsoredSlotActivationDistanceOverride: stringyNumber.default(0),
    companionBounds: companionBoundsSchema.or(
      z
        .string()
        .default('{}')
        .transform(s => JSON.parse(s))
        .pipe(companionBoundsSchema),
    ),
    dynamicRoot: z.string().optional(),
    generatedMaximumSlots: z.preprocess(n => n || undefined, stringyNumber.default(Infinity)),
    generatedMinimumSlots: stringyNumber.default(0),
    master: z.string().optional(),
    generatedEndSelector: z.string().optional(),
    generatedSlotHeight: z.number().optional(),
    generatedSlotGap: z.number().optional(),
  })
  .default({});

export const slotStyleSchema = z.record(z.string()).default({});

export const slotPropertiesSchema = z
  .object({
    config: slotConfigSchema,
    label: slotLabelSchema,
    style: slotStyleSchema,
    generatedContainerStyle: slotStyleSchema,
    generatedSpacerStyle: slotStyleSchema,
  })
  .default({});

export const slotSchema = z.object({
  name: z.string(),
  hook: z.string(),
  master: z.string().optional(),
  type: slotTypeSchema,
  position: slotPositionSchema,
  size: z
    .object({
      w: z.number().default(0),
      h: z.number().default(0),
    })
    .default({}),
  properties: slotPropertiesSchema,
});
