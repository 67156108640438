import { GaAbTestBean } from 'ad-framework/ab-test/index.types';
import ActionArgs from 'state/proxy/action-args.types';
import { AuctionPartnerReportEvent, REPORT_AUCTION_PARTNER } from 'state/types/report.types';
import { getEnv } from './env';

const PARTNER_BIDS_EVENT_SAMPLE_RATE = 0.1;
const partnerBidsEventEnabled: boolean = Math.random() < PARTNER_BIDS_EVENT_SAMPLE_RATE;

function pushToFreyr(eventName: string, eventData: Record<string, unknown>): void {
  const env = getEnv();
  if (env.freyr === undefined) return;
  env.freyr.cmd.push(() => env.freyr?.pushAndUpdate(eventName, eventData));
}

export function sendABTestToFreyr(gaTestBean: GaAbTestBean): void {
  const env = getEnv();
  if (env.freyr === undefined) return;
  pushToFreyr('hybridAbTestEvent', { ...gaTestBean });
}

export default function sendPartnerBidsToFreyr({
  context,
  event,
}: ActionArgs<AuctionPartnerReportEvent<REPORT_AUCTION_PARTNER.SUCCESS>>): void {
  if (!partnerBidsEventEnabled) {
    return;
  }
  const env = getEnv();
  if (env.freyr === undefined || event.data.bids === undefined) return;
  const flattenBids: Array<Record<string, string | number>> = Object.values(
    event.data.bids,
  ).flatMap(b =>
    b.bids.map(bu => ({
      ad_id: bu.adId?.toString() || '',
      auction_id: bu.auctionId?.toString() || '',
      adunit: bu.adUnitCode,
      bidder: bu.bidder,
      cpm: bu.cpm,
      ad_size: bu.size,
      response_time: bu.timeToRespond,
      deal_id: bu.dealId?.toString() || '',
      creative_id: bu.creativeId?.toString() || '',
      media_type: bu.mediaType,
    })),
  );
  if (flattenBids.length === 0) return;
  pushToFreyr('auctionPartnerEvent', {
    id: event.data.auction,
    partner: event.data.partner,
    bids: flattenBids,
    browser: context.timing.payload.browser,
    user: context.timing.payload.user,
    session: context.timing.payload.session,
  });
}
