import DataObject from 'state/data-object';
import { getEnv } from 'utils/env';
import { Ad } from './index.types';
import { FLUID_SIZE } from './constants';

const onAdMount = (ad: DataObject<Ad>): void => {
  const env = getEnv();

  const element = env.document.createElement('div');
  element.id = ad.getProperty('id');
  element.style.position = 'sticky';
  element.style.top = '0';
  element.style.margin = '0 auto';

  if (ad.getProperty('verticallyCentered')) {
    element.style.margin = 'auto auto';
  }

  const topPosition = ad.getProperty('topPosition');
  if (topPosition > 0) {
    element.style.top = `${topPosition}px`;
  }

  const sizes = ad.getProperty('sizes');
  if (sizes.includes(FLUID_SIZE)) {
    element.style.width = '100%';
    element.style.height = '100%';
  }

  ad.update({ element });
};

export default onAdMount;
