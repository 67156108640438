import { isEmpty } from 'ramda';
import log from 'log';
import fastdom from 'utils/fastdom';
import { getEnv } from 'utils/env';
import { stringToStyle } from 'utils/style';
import { GeneratedSlotDefinition } from './index.types';
import { LABEL_STYLE } from '../ad/create-ad-label';
import positionElement from './position-element';

type CSSValue = `${number}${'%' | 'px'}`;
type MarginValue =
  | CSSValue
  | `${CSSValue} ${CSSValue}`
  | `${CSSValue} ${CSSValue} ${CSSValue}`
  | `${CSSValue} ${CSSValue} ${CSSValue} ${CSSValue}`;
interface ContainerBounds {
  getWidth: () => Promise<number>;
  getHeight: () => Promise<number>;
  getTop: () => Promise<number>;
}

const isPercentageValue = (value: string): boolean => value.slice(-1) === '%';
const isPixelValue = (value: string): boolean => value.slice(-2) === 'px';

const getAvailableContainerHeight = async (
  containerStyle: { [key: string]: string },
  containerBounds: ContainerBounds,
  endSelector?: string,
): Promise<number | null> => {
  const endSelectorIsSet = endSelector !== undefined;
  if (endSelectorIsSet) {
    const env = getEnv();
    let endSelectorElements: NodeListOf<Element> | undefined;
    try {
      endSelectorElements = document.querySelectorAll(endSelector);
    } catch (_error) {
      log.error(`Error executing end selector for generated slot.`);
    }

    const [containerStart, bounds] = await Promise.all([
      containerBounds.getTop(),
      fastdom.measure(() => ({
        stop: [...(endSelectorElements || [])].map(element => element.getBoundingClientRect()),
        documentHeight:
          Math.max(
            env.document.body.clientHeight,
            env.document.body.scrollHeight,
            env.document.body.offsetHeight,
            env.document.documentElement.clientHeight,
            env.document.documentElement.scrollHeight,
            env.document.documentElement.offsetHeight,
          ) - env.scrollY,
      })),
    ]);

    // Only consider stop elements that start after the container
    const stops = bounds.stop.filter(bound => bound.top > containerStart);
    // Find the soonest stop
    const stop = Math.min(bounds.documentHeight, ...stops.map(bound => bound.top));

    return Math.max(0, stop - containerStart);
  }

  const isFixedHeightContainer = isPixelValue(containerStyle.height || '');
  if (isFixedHeightContainer) {
    return Number((containerStyle.height || '').slice(0, -2) || 0);
  }

  return containerBounds.getHeight();
};

const extractMarginsFromCSS = (style: {
  [key: string]: string;
}): { top: CSSValue; bottom: CSSValue } => {
  const marginTop = (style.marginTop || style['margin-top'] || '') as CSSValue;
  const marginBottom = (style.marginBottom || style['margin-bottom'] || '') as CSSValue;
  const margin = (style.margin || '') as MarginValue;
  const parts = margin.split(/\s+/) as Array<CSSValue>;

  return {
    top: marginTop || parts[0] || '',
    bottom: marginBottom || (parts.length > 2 ? parts[2] : parts[0]) || '',
  };
};

const getMarginValue = async (
  containerBounds: ContainerBounds,
  stringValue: CSSValue,
): Promise<number> => {
  const fallbackMargin = 0;

  const marginIsPixels = isPixelValue(stringValue);
  const marginIsPercentage = isPercentageValue(stringValue);

  if (marginIsPixels) {
    return Number(stringValue.slice(0, -2) || 0);
  }

  if (marginIsPercentage) {
    const conatinerWidth = await containerBounds.getWidth();
    return Math.round((Number(stringValue.slice(0, -1)) * conatinerWidth) / 100);
  }

  return fallbackMargin;
};

const getSpacerMargin = async (
  generatedSpacerStyle: { [key: string]: string },
  containerBounds: ContainerBounds,
  slotGap?: number,
): Promise<{ top: number; bottom: number }> => {
  if (slotGap !== undefined) {
    return { top: 0, bottom: slotGap };
  }

  const spacerMarginCSSData = extractMarginsFromCSS(generatedSpacerStyle || {});

  return {
    top: await getMarginValue(containerBounds, spacerMarginCSSData.top),
    bottom: await getMarginValue(containerBounds, spacerMarginCSSData.bottom),
  };
};

const getSpacerHeight = async (
  generatedSpacerStyle: { [key: string]: string },
  containerBounds: ContainerBounds,
  labelStyle: { [key: string]: string } | CSSStyleDeclaration,
  coreSlotHeight?: number,
  slotHeight?: number,
): Promise<number> => {
  if (slotHeight !== undefined) {
    return slotHeight;
  }

  const fallbackSpacerHeight = 0;

  const generatedSpacerStyleHeight = generatedSpacerStyle.height || '';

  if (isPixelValue(generatedSpacerStyleHeight)) {
    return Number(generatedSpacerStyleHeight.slice(0, -2) || 0);
  }

  if (isPercentageValue(generatedSpacerStyleHeight)) {
    const conatinerHeight = await containerBounds.getHeight();
    const spacerHeightPercentage = Number(generatedSpacerStyleHeight.slice(0, -1) || 0);
    return Math.round((conatinerHeight * spacerHeightPercentage) / 100);
  }
  const labelStyleHeight = labelStyle.height || '';
  if (coreSlotHeight) {
    if (isPixelValue(labelStyleHeight)) {
      return coreSlotHeight + Number(labelStyle.height?.slice(0, -2) || 0);
    }
    if (isPercentageValue(labelStyleHeight)) {
      return (coreSlotHeight * 100) / (100 - Number(labelStyleHeight.slice(0, -1) || 0));
    }
    return coreSlotHeight;
  }

  return fallbackSpacerHeight;
};

const getContainerBounds = (
  container: Element,
  containerStyle: Record<string, string>,
): ContainerBounds => {
  let containerDomRec: DOMRect | null = null;
  const getBounds = async (): Promise<DOMRect> => {
    if (!containerDomRec) {
      containerDomRec = await fastdom.measure(() => container.getBoundingClientRect());
    }
    return containerDomRec;
  };

  return {
    getWidth: async (): Promise<number> => {
      const containerWidthIsPixels = isPixelValue(containerStyle.width || '');
      if (containerWidthIsPixels) {
        return Number((containerStyle.width || '').slice(0, -2) || 0);
      }

      return (await getBounds()).width;
    },
    getHeight: async (): Promise<number> => (await getBounds()).height,
    getTop: async (): Promise<number> => (await getBounds()).top,
  };
};

const calculateSlotParameters = async (
  slot: GeneratedSlotDefinition,
  container: Element,
): Promise<{ count: number; margin?: { top: number; bottom: number }; height?: number }> => {
  const fallbackSlotCount = 1;
  const { generatedConfig } = slot;

  if (generatedConfig.minimumSlots === generatedConfig.maximumSlots) {
    return {
      count: generatedConfig.maximumSlots,
    };
  }

  const containerBounds = getContainerBounds(container, generatedConfig.containerStyle);
  const availableContainerHeight = await getAvailableContainerHeight(
    generatedConfig.containerStyle,
    containerBounds,
    generatedConfig.endSelector,
  );
  const spacerMargin = await getSpacerMargin(
    generatedConfig.spacerStyle,
    containerBounds,
    generatedConfig.slotGap,
  );
  const slotLabel = slot.label;
  const spacerHeight = await getSpacerHeight(
    generatedConfig.spacerStyle,
    containerBounds,
    isEmpty(slotLabel) ? {} : stringToStyle(slotLabel?.style || LABEL_STYLE),
    slot.height,
    generatedConfig.slotHeight,
  );

  if (availableContainerHeight === null) {
    log.error(`Attempted to calculate slot count for container with no height.`);
    return {
      count: fallbackSlotCount,
      margin: spacerMargin,
      height: spacerHeight,
    };
  }

  return {
    count: Math.min(
      generatedConfig.maximumSlots || Infinity,
      Math.max(
        generatedConfig.minimumSlots || 0,
        Math.floor(
          (availableContainerHeight - Math.min(spacerMargin.top, spacerMargin.bottom)) /
            (spacerHeight + Math.max(spacerMargin.top, spacerMargin.bottom)),
        ),
      ),
    ),
    margin: spacerMargin,
    height: spacerHeight,
  };
};

const generateSpacers = async (
  slot: GeneratedSlotDefinition,
  container: HTMLElement,
): Promise<Array<HTMLElement>> => {
  const { count, ...slotParameters } = await calculateSlotParameters(slot, container);

  const generatedSpacerStyle = {
    ...slot.generatedConfig.spacerStyle,
    ...(slotParameters.height ? { height: `${slotParameters.height}px` } : {}),
    ...(slotParameters.margin
      ? {
          marginBottom: `${slotParameters.margin.bottom}px`,
          marginTop: `${slotParameters.margin.top}px`,
        }
      : {}),
  };
  const spacers = Array(count).fill(0).map(generateSpacer(generatedSpacerStyle));

  return spacers;
};

const generateSpacer = (generatedSpacerStyle: Record<string, string>) => (): HTMLElement => {
  const env = getEnv();
  const spacer = env.document.createElement('div');
  spacer.classList.add('bordeaux-generated-spacer');
  Object.assign(spacer.style, generatedSpacerStyle);
  return spacer;
};

const handleGenerateContainer = async (
  slot: GeneratedSlotDefinition,
  hookElement: HTMLElement,
): Promise<HTMLElement> => {
  const env = getEnv();
  const container = env.document.createElement('div');
  container.classList.add('bordeaux-generated-container');
  const {
    generatedConfig: { containerStyle },
  } = slot;
  if (containerStyle) {
    Object.assign(container.style, containerStyle);
  }
  await fastdom.mutate(() => positionElement(container, slot.position, hookElement));
  return container;
};

export default async (
  slot: GeneratedSlotDefinition,
  hookElements: Array<HTMLElement>,
): Promise<Array<HTMLElement>> => {
  const spacerGroups = await Promise.all(
    hookElements.map(async hookElement => {
      const container = await handleGenerateContainer(slot, hookElement);
      const spacers = await generateSpacers(slot, container);
      await fastdom.mutate(() => {
        spacers.forEach(spacer => {
          container.appendChild(spacer);
        });
      });
      return spacers;
    }),
  );
  return ([] as Array<HTMLElement>).concat(...spacerGroups);
};
