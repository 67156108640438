import { getEnv } from 'utils/env';
import log from 'log';

const env = getEnv();

interface BordeauxSelectedElement extends Element {
  bordeauxHookSelectorPosition: number;
}

type BordeauxSelector = {
  selector: string;
  position: number;
};

export default <T extends Element>(...queries: unknown[]): T | null => {
  try {
    for (const querySelector of queries) {
      const selector = querySelector as BordeauxSelector;
      // selector is a CSS query string
      if (typeof selector === 'string') {
        const result = env.document.querySelector<T>(selector);
        if (result) {
          return result;
        }
      }
      // OR an object with the keys "selector" and "position"
      else if (
        typeof selector === 'object' &&
        selector.selector &&
        typeof selector.position === 'number' &&
        selector.position > 0
      ) {
        const results: NodeListOf<T> = env.document.querySelectorAll<T>(selector.selector);
        const result = results[selector.position - 1];
        if (result) {
          (result as unknown as BordeauxSelectedElement).bordeauxHookSelectorPosition =
            selector.position;
          return result;
        }
      }
    }
    return null;
  } catch (error) {
    log.error('Error while selecting DOM element', error);
    return null;
  }
};
