import { isNil } from 'ramda';
import { Placement } from 'ad-framework/placement/index.types';
import { getEnv } from 'utils/env';
import { Env } from 'utils/env.types';
import { GaAbTestBean } from 'ad-framework/ab-test/index.types';

const eventAction = 'ab test initiated';
const eventLabel = 'ads placement';

const computeFieldsObject = (gaAbtestBean: GaAbTestBean, env: Env): object => {
  if (!isNil(env.analytics_ga_data)) {
    return {
      ...env.analytics_ga_data,
      dimension26: JSON.stringify(gaAbtestBean),
      nonInteraction: true,
    };
  }
  return {
    dimension26: JSON.stringify(gaAbtestBean),
    nonInteraction: true,
  };
};

export default async function pushToGoogleAnalytics(placement?: Placement): Promise<void> {
  const env = getEnv();
  if (placement?.abTest && env.ga) {
    placement.abTest.forEach(gaBean => {
      const eventValue = computeFieldsObject(gaBean, env);
      env.ga!('send', 'event', gaBean.origin, eventAction, eventLabel, eventValue);
    });
  }
}
