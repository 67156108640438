import { SitePrebidConfig } from './prebid/index.types';

export enum ThirdParty {
  AD_SERVER = 'adServer',
  AM_CLIO = 'amClio',
  AMAZON = 'amazon',
  GPT = 'gpt',
  GLOBAL = 'global',
  IAS = 'ias',
  INDEX_EXCHANGE = 'indexExchange',
  LIVE_RAMP = 'liveRamp',
  PUBMATIC = 'pubmatic',
  PREBID = 'prebid',
  EUID = 'euid',
  PUBX = 'pubx',
  TMT = 'tmt',
  LIVE_INTENT = 'liveIntent',
  UID2 = 'uid2',
}

export interface GlobalConfig {
  enabled: boolean;
  sellerID: string | null;
}

export interface AdServerConfig {
  enabled: boolean;
  ab?: number[];
}

export interface AmazonConfig {
  enabled: boolean;
  consentVendor: string;
  apiConfig: {
    pubID: string;
    adServer: string;
    deals: boolean;
    useSafeFrames: boolean;
    schain: {
      complete: 1 | 0; // Integer 1 or 0 indicating if all preceding nodes are complete
      ver: string; // Version of the spec used
      nodes: Array<{
        // Can be empty if the owner of the site is creating this request
        asi: string; // Populate with the canonical domain of the advertising system where the seller.JSON file is hosted
        sid: string; // The identifier associated with the seller or reseller account within your advertising system
        hp: 1 | 0; // 1 or 0, whether this node is involved in the payment flow
      }>;
    };
    signals: {
      ortb2: {
        site: {
          cattax: number;
          cat: Array<string>;
          keywords: Array<string>;
        };
      };
    };
  };
}

export interface AMClioConfig {
  enabled: boolean;
}

export type IASConfig = {
  enabled: boolean;
  pubId: string;
};

export type GPTConfig = {
  enabled: boolean;
};

export interface IndexExchangeConfig {
  enabled: boolean;
  consentVendor: string;
  id: string;
  mode: 'standard';
}

export interface LiveRampConfig {
  enabled: boolean;
  consentVendor: string;
  configurationId: string;
}
export interface PrebidConfig {
  enabled: boolean;
  banner: SitePrebidConfig;
  video: SitePrebidConfig;
}
export interface PubmaticConfig {
  enabled: boolean;
  consentVendor: string;
  profileId: string;
  pubId: string;
}

export interface EUIDConfig {
  enabled: boolean;
}

export interface PubXConfig {
  enabled: boolean;
}

export interface TMTConfig {
  enabled: boolean;
}

export interface LiveIntentConfig {
  enabled: boolean;
}

export interface Uid2Config {
  enabled: boolean;
}

export interface SiteConfig {
  [ThirdParty.GLOBAL]: GlobalConfig;
  [ThirdParty.GPT]: GPTConfig;
  [ThirdParty.AD_SERVER]: AdServerConfig;
  [ThirdParty.AMAZON]: AmazonConfig;
  [ThirdParty.AM_CLIO]: AMClioConfig;
  [ThirdParty.EUID]: EUIDConfig;
  [ThirdParty.IAS]: IASConfig;
  [ThirdParty.INDEX_EXCHANGE]: IndexExchangeConfig;
  [ThirdParty.LIVE_RAMP]: LiveRampConfig;
  [ThirdParty.PUBMATIC]: PubmaticConfig;
  [ThirdParty.PUBX]: PubXConfig;
  [ThirdParty.PREBID]: PrebidConfig;
  [ThirdParty.TMT]: TMTConfig;
  [ThirdParty.LIVE_INTENT]: LiveIntentConfig;
  [ThirdParty.UID2]: Uid2Config;
}
