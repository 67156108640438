import { UserSyncElement } from 'user-sync/index.types';
import { getGDPRData } from './utils';
import { UserSyncConfig } from '../../state/types/context.types';

const beachfront: UserSyncConfig = {
  id: '42',
  bidderName: 'beachfront',
  iabIdList: [335],
  element: UserSyncElement.IFRAME,
  url: 'https://sync.bfmio.com/sync_iframe',
  params: {
    ifpl: '5',
    ifg: '4',
    id: 'Purch',
    gce: '1',
  },
  dynamicParams: context => {
    const gdprData = getGDPRData(context.gdprConsent);
    return gdprData
      ? {
          gdpr: '1',
          gc: gdprData,
        }
      : {
          gdpr: '0',
          gc: '',
        };
  },
};
export default beachfront;
