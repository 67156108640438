import { RoadblockIncrementalChooser } from 'ad-framework/roadblock/incremental.types';
import log from 'log';
import { API_EVENTS_OUT } from './events.names';
import { ApiMachineEventService } from './events.service';

export default (
  service: ApiMachineEventService,
  roadblockIncrementalChooser: RoadblockIncrementalChooser,
): void => {
  if (typeof roadblockIncrementalChooser !== 'function') {
    log.error(
      `Argument passed to setRoadblockIncrementalChooser must be a function, ${typeof roadblockIncrementalChooser} given`,
    );
    return;
  }
  service.sendEvent({
    type: API_EVENTS_OUT.SET_ROADBLOCK_INCREMENTAL_CHOOSER,
    data: roadblockIncrementalChooser,
  });
};
