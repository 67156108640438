import { AdUnitSizeTuple } from 'ad-framework/ad/index.types';

export const validSizes: Array<Array<number>> = [
  [970, 250],
  [970, 90],
  [728, 90],
  [300, 600],
  [300, 250],
  [320, 50],
  [300, 50],
  [300, 31],
  [120, 600],
  [160, 600],
];

export const filterInvalidSizes = (size: AdUnitSizeTuple): size is AdUnitSizeTuple => {
  return validSizes.some(([a, b]) => a === size[0] && b === size[1]);
};
