import log from 'log';
import { getEnv } from 'utils/env';
import processEUID from './euid';
import { GDPRConsent } from '../utils/cmp/index.types';
import { hasUserConsentedVendorGDPR } from '../utils/cmp';
import cookies from '../utils/cookies';
import createElements from './transformers/create-elements';
import { BordeauxMachineContext, UserSyncConfig } from '../state/types/context.types';
import { ThirdParty } from '../third-party-apis/config.types';

const userSyncPixelHasConsent =
  (gdprConsent: GDPRConsent) =>
  (userSyncPixel: UserSyncConfig): boolean => {
    if (userSyncPixel.iabIdList.length > 0) {
      return userSyncPixel.iabIdList.some(iabId => hasUserConsentedVendorGDPR(gdprConsent, iabId));
    }
    return hasUserConsentedVendorGDPR(gdprConsent);
  };
export default (context: BordeauxMachineContext): void => {
  if (context.thirdPartyApiConfig[ThirdParty.AD_SERVER].enabled) {
    const cookiesMap = cookies.getAll();
    const unsyncedPixels = context.userSyncPixels.filter(
      (userSyncPixel: UserSyncConfig): boolean => {
        const cookieName = `usp.${userSyncPixel.id}`;
        return !cookiesMap[cookieName];
      },
    );
    unsyncedPixels.forEach(userSyncPixel => {
      const cookieName = `usp.${userSyncPixel.id}`;
      cookies.set(cookieName, '1', 604800);
      cookiesMap[cookieName] = '1';
    });

    const consentedPixels = unsyncedPixels.filter(userSyncPixelHasConsent(context.gdprConsent));
    const userSyncElements = ([] as Array<HTMLElement>).concat(
      ...consentedPixels.map(userSyncPixel => {
        try {
          return createElements(context, userSyncPixel);
        } catch (error) {
          log.error(error);
          return [];
        }
      }),
    );
    const env = getEnv();
    userSyncElements.forEach(element => {
      env.document.body.appendChild(element);
    });
  }
  processEUID(context.gdprConsent);
};
