import { SommelierResponseSlot } from 'ad-framework/slot/sommelier-response.types';

import { SlotPosition, SlotType } from 'ad-framework/slot/index.types';
import { SMALL_LEADERBOARD_WIDTH, SMALL_LEADERBOARD_HEIGHT } from '../../constants';

export const leaderboardSlot: SommelierResponseSlot = {
  name: 'leaderboard',
  hook: '.dfp-leaderboard-container, #top-leaderboard',
  type: SlotType.BLOCK,
  position: SlotPosition.PREPEND,
  size: {
    w: SMALL_LEADERBOARD_WIDTH,
    h: SMALL_LEADERBOARD_HEIGHT,
  },
  properties: {},
};

export const staticLeaderboardSlots: SommelierResponseSlot = {
  name: `leaderboard`,
  hook: '.leaderboard__container, .static-leaderboard',
  type: SlotType.BLOCK,
  position: SlotPosition.APPEND,
  size: {
    w: SMALL_LEADERBOARD_WIDTH,
    h: SMALL_LEADERBOARD_HEIGHT,
  },
  properties: {
    config: {
      multiple: true,
    },
  },
};
