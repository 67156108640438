import log from 'log';
import { API_EVENTS_OUT } from './events.names';
import { FEATURE } from './feature';
import { ApiMachineEventService } from './events.service';

const features = Object.values(FEATURE);

export const enableFeature = (service: ApiMachineEventService, feature: FEATURE): void => {
  if (typeof feature !== 'string') {
    log.error(`Argument passed to enableFeature must be a string, ${typeof feature} given`);
    return;
  }
  if (!features.includes(feature)) {
    log.warn(`Unable to enable feature ${feature}, no handler specified.`);
    return;
  }
  service.sendEvent({
    type: API_EVENTS_OUT.ENABLE_FEATURE,
    data: feature,
  });
};

export const disableFeature = (service: ApiMachineEventService, feature: FEATURE): void => {
  if (typeof feature !== 'string') {
    log.error(`Argument passed to disableFeature must be a string, ${typeof feature} given`);
    return;
  }
  if (!features.includes(feature)) {
    log.warn(`Unable to disable feature ${feature}, no handler specified.`);
    return;
  }
  service.sendEvent({
    type: API_EVENTS_OUT.DISABLE_FEATURE,
    data: feature,
  });
};
