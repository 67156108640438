import log from 'log';
import { API_EVENTS_OUT } from './events.names';
import { ApiMachineEventService } from './events.service';

export const getExperimentId = (service: ApiMachineEventService): Promise<string> =>
  service.getData<string>('experimentId');

export const setExperimentId = (service: ApiMachineEventService, id: string): void => {
  if (typeof id !== 'string') {
    log.error(`Argument passed to setExperimentId must be a string, ${typeof id} given`);
    return;
  }
  service.sendEvent({
    type: API_EVENTS_OUT.SET_EXPERIMENT_ID,
    data: id,
  });
};
