import log from 'log';
import { API_EVENTS_OUT } from './events.names';
import { ApiMachineEventService } from './events.service';

export const getPageCategory = (service: ApiMachineEventService): Promise<string | undefined> =>
  service.getData<string | undefined>('pageCategory');

export default (service: ApiMachineEventService, pageCategory: string): void => {
  if (typeof pageCategory !== 'string') {
    log.error(`Argument passed to setPageCategory must be a string, ${typeof pageCategory} given`);
    return;
  }

  service.sendEvent({
    type: API_EVENTS_OUT.SET_PAGE_CATEGORY,
    data: pageCategory.replace(/[^a-zA-Z0-9-_]/g, ''),
  });
};
