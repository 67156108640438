import { UserSyncElement } from 'user-sync/index.types';
import { getUserSyncURL } from './utils';
import { UserSyncConfig } from '../../state/types/context.types';

const id = '62';
const mediagrid: UserSyncConfig = {
  id,
  bidderName: 'mediagrid',
  iabIdList: [686, 128, 129],
  element: UserSyncElement.IFRAME,
  url: `https://x.bidswitch.net/check_uuid/${encodeURIComponent(
    getUserSyncURL({
      id,
      uid: '${BSW_UUID}&cookie_age=${COOKIE_AGE}',
    }),
  )}`,
};
export default mediagrid;
