import log from 'log';
import { ObjectTypes } from './index.types';

export const findPointer = <T = unknown>(
  list: Array<T>,
  predicate: (item: T, index: number, list: Array<T>) => boolean,
): number => {
  const index = list.findIndex(predicate);
  return index === -1 ? list.length : index;
};

export const accessObjectPath = <T = unknown>(
  object: ObjectTypes,
  path: Array<string | number>,
): T | undefined =>
  path.reduce((object: ObjectTypes, key) => object?.[key], object) as T | undefined;

export const updateObjectPath = <O extends ObjectTypes = ObjectTypes>(
  object: O,
  path: Array<string | number>,
  newValue: unknown,
): O => {
  if (path.length === 0 || !object) {
    return object;
  }
  const key = path[0];
  const value =
    path.length === 1 ? newValue : updateObjectPath(object[key], path.slice(1), newValue);
  return Array.isArray(object) && typeof key === 'number'
    ? ([...object.slice(0, key), value, ...object.slice(1 + key)] as O)
    : {
        ...object,
        [key]: value,
      };
};

export const mergeListItem = <T, O extends ObjectTypes>(
  rootObject: O,
  path: Array<string | number>,
  findStrategy: (item: T) => boolean,
  mergeStrategy: (item: T | undefined) => T,
): O => {
  const list = accessObjectPath<Array<T>>(rootObject, path);
  if (list === undefined) {
    log.warn(`Could not access path of object, path: (${path.join(' > ')})`);
    return rootObject;
  }
  const insertPointer = findPointer<T>(list, findStrategy);
  const existingItem = list[insertPointer];
  const newItem = mergeStrategy(existingItem);
  return updateObjectPath(rootObject, [...path, insertPointer], newItem);
};
