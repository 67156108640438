import { BordeauxMachineContext } from 'state/types/context.types';
import { enqueueActions as genericEnqueueActions, ParameterizedObject } from 'xstate';
import AnyBordeauxEvent from '../types/any-event.types';

const enqueueActions = <R extends AnyBordeauxEvent>(
  ...args: Parameters<
    typeof genericEnqueueActions<
      BordeauxMachineContext,
      R,
      ParameterizedObject['params'],
      AnyBordeauxEvent,
      any,
      any,
      any
    >
  >
) =>
  genericEnqueueActions<
    BordeauxMachineContext,
    R,
    ParameterizedObject['params'],
    AnyBordeauxEvent,
    any,
    any,
    any
  >(...args);

export default enqueueActions;
