import { SiteConfig } from 'third-party-apis/config.types';
import { API_EVENTS_OUT } from './events.names';
import { ApiMachineEventService } from './events.service';

export default (service: ApiMachineEventService, config: Partial<SiteConfig>): void => {
  service.sendEvent({
    type: API_EVENTS_OUT.SET_THIRD_PARTY_API_CONFIG,
    data: config,
  });
};
