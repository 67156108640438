import { AnyEventObject, fromCallback } from 'xstate';
import { Ad } from 'ad-framework/ad/index.types';
import { fetch as adManagerFetch } from 'ad-framework/ad-manager/index';
import DataObject from './data-object';
import { EVENTS } from './types/events.names';

const adBatchMachine = fromCallback<AnyEventObject, Array<DataObject<Ad>>>(
  ({ input: ads, sendBack }) => {
    Promise.all(ads.map(ad => ad.getProperty('readyPromise'))).then(() => {
      adManagerFetch(
        ads.sort((a, b) => a.getProperty('requestOrder') - b.getProperty('requestOrder')),
      );
      sendBack({
        type: EVENTS.PROCESS_NEXT_BATCH,
      });
    });
  },
);
export default adBatchMachine;
