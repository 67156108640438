import { UserSyncConfig } from '../../state/types/context.types';
import { UserSyncElement } from '../index.types';
import { getGDPRData, getUserSyncURL } from './utils';

const trackingIds = {
  bikeperfect: 'o6xrqnpf',
  countrylife: 'go3ow8jf',
  creativebloq: '7absczm5',
  cyclingnews: 't9t3tck9',
  decanter: 'i7y80eeq',
  digitalcameraworld: 'cimuwtf2',
  fourfourtwo: '4husjo0l',
  gamesradar: 'llgebarm',
  'golfmonthly-progressive': 'yzessgyz',
  'goodtoknow-progressive': 'dcbmxwwc',
  guitarplayer: 'wrprmyks',
  guitarworld: '16qp2ovt',
  'homesandgardens-progressive': 'mzuhnqc0',
  horseandhound: 'twzzzdla',
  howtogeek: '2551ab8e',
  idealhome: '7fq0zygv',
  'idealhome-progressive-qa': '7fq0zygv',
  laptopmag: '400b75d3',
  livescience: 'fe79cc6d',
  'livingetc-progressive': 'sqsvp0jm',
  look: '8xwmmp2t',
  loudersound: 'giyrkkmt',
  marieclaire: 'e1zhtjh8',
  'marieclairecom-progressive': '6sech3oz',
  mby: 'xuqub111',
  pcgamer: 'agyog4oo',
  reviewgeek: '4l4crive',
  sciencealert: 'gwpevyec',
  space: 'fb728bb0',
  't3-progressive': 'gcicurrf',
  techradar: 'njqsk6ke',
  techspot: 'lh17jalp',
  thecaribou: '4xtfxjy1',
  tomsguide: '5b923816',
  tomshardware: 'e5ffa56e',
  toptenreviews: 'i0ohgxfh',
  whatsontv: 'tduro6vk',
  whattowatch: 'sscfxm61',
  windowscentral: 'b3a52dd3',
  'windowscentral-qa': 'b3a52dd3',
  'womanandhome-progressive': 'fm0mpqhm',
};
const id = '48';
const gumgum: UserSyncConfig = {
  id,
  bidderName: 'gumgum',
  iabIdList: [61],
  condition: context => context.pageParameters.site in trackingIds,
  dynamicUrl: context => `https://g2.gumgum.com/usync/${trackingIds[context.pageParameters.site]}`,
  element: UserSyncElement.IFRAME,
  params: {
    r: getUserSyncURL({ id }),
  },
  dynamicParams: context => {
    const gdprData = getGDPRData(context.gdprConsent);
    return {
      ...(gdprData
        ? {
            gdpr: '1',
            gdpr_consent: gdprData,
          }
        : {
            gdpr: '0',
          }),
      ...(context.uspConsent && context.uspConsent.consent
        ? {
            us_privacy: context.uspConsent.consent.uspString,
          }
        : {}),
    };
  },
};
export default gumgum;
