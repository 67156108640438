import { mergeAll } from 'ramda';
import { getEnv } from 'utils/env';
import escapeUnsafeValue from 'utils/escape';
import { BordeauxMachineContext } from 'state/types/context.types';
import cookies from 'utils/cookies';
import customTargeting from './custom-targeting';
import { Targeting, SkinSizes } from './index.types';
import { HybridABTest } from '../../timing-collection/payload.types';

const env = getEnv();

const userIDsTargeting = (): Record<string, string> => {
  const { prebid } = env;

  const ids = {
    uid2_id_present: prebid && prebid.getUserIds().uid2 ? 'true' : 'false',
    euid_id_present: prebid && prebid.getUserIds().euid ? 'true' : 'false',
    liveint_id_present:
      cookies.get('_li_ss') || env.localStorage.getItem('_li_ss') ? 'true' : 'false',
    liveramp_id_present:
      cookies.get('idl_env') || env.localStorage.getItem('idl_env') ? 'true' : 'false',
  };

  return {
    ...ids,
    ...{ any_id_present: Object.values(ids).some(value => value === 'true') ? 'true' : 'false' },
  };
};

export function getSkinSize(width: number): SkinSizes {
  if (width === undefined) {
    throw new Error('Width should not be undefined');
  }

  const advertBreakpoints: Record<SkinSizes, Record<'min' | 'max', number>> = {
    small: {
      min: 0,
      max: 1279,
    },
    large: {
      min: 1280,
      max: 12000,
    },
  };

  let skinSize: SkinSizes = 'small';
  (Object.keys(advertBreakpoints) as SkinSizes[]).forEach((key: SkinSizes): void => {
    if (width >= advertBreakpoints[key].min && width <= advertBreakpoints[key].max) {
      skinSize = key;
    }
  });

  return skinSize;
}

function tiberiusMessageDisplayed(): boolean {
  return Boolean(env.localStorage && env.localStorage.getItem('adblock'));
}

const tiberius = (): Partial<{ adblock: 'disabled' }> => {
  const messageDisplayed = tiberiusMessageDisplayed();
  return messageDisplayed ? { adblock: 'disabled' } : {};
};

const extractAbTestData = (rawAbTestData: string): HybridABTest => {
  const abTestTab = rawAbTestData.split('|');
  return {
    id: abTestTab[0],
    variant_id: abTestTab[1],
  };
};

const checkPrivacySandboxEnabledAPis = (): Array<string> => {
  const supportedAPIs: Array<string> = [];
  if (
    'joinAdInterestGroup' in env.navigator &&
    env.document.featurePolicy?.allowsFeature('join-ad-interest-group') &&
    env.document.featurePolicy.allowsFeature('run-ad-auction')
  ) {
    supportedAPIs.push('Protected Audience');
  }

  if (
    'browsingTopics' in document &&
    env.document.featurePolicy?.allowsFeature('browsing-topics')
  ) {
    supportedAPIs.push('Topics');
  }
  return supportedAPIs;
};

export const getHybridAbTestTargeting = (context: BordeauxMachineContext): HybridABTest[] => {
  const result: HybridABTest[] = [];
  const combinedTargeting: Targeting = mergeAll([context.config.targeting, context.pageTargeting]);

  if (combinedTargeting.hybridTestID) {
    if (Array.isArray(combinedTargeting.hybridTestID)) {
      combinedTargeting.hybridTestID.forEach(oneHybridTestId => {
        if (oneHybridTestId.indexOf('|') > -1) {
          result.push(extractAbTestData(oneHybridTestId));
        }
      });
    } else if (combinedTargeting.hybridTestID.indexOf('|') > -1) {
      result.push(extractAbTestData(combinedTargeting.hybridTestID));
    }
  }
  return result;
};

export default async function targeting(context: BordeauxMachineContext): Promise<Targeting> {
  const { hybridId } = context;
  const defaultTargeting: Targeting = {
    site: context.pageParameters.site,
    url: env.location.href,
    referrer: env.document.referrer,
    test: Math.random() >= 0.5 ? 'A' : 'B',
    screen: getSkinSize(env.screen.width),

    ...(hybridId ? { h_id: hybridId } : {}),
    ...(context.experimentId ? { experimentId: context.experimentId } : {}),
    ...tiberius(),
    ...{ cft_label_name: context.cftParameters.labelName },
    ...{ cft_enabled_apis: checkPrivacySandboxEnabledAPis() },
    ...userIDsTargeting(),
  };
  const combinedTargeting: Targeting = mergeAll([
    defaultTargeting,
    context.config.targeting,
    context.pageTargeting,
  ]);

  const targetingObject: Targeting =
    context.queryParameters.forceTargeting !== null
      ? customTargeting(combinedTargeting, env.location.href)
      : combinedTargeting;

  Object.keys(targetingObject).forEach(key => {
    targetingObject[key] = escapeUnsafeValue(targetingObject[key]);
  });

  if (context.config.bidFloors) {
    targetingObject._ex = [
      ...((targetingObject._ex as Array<string>) || []),
      context.config.bidFloors.exp.toString(10),
    ].filter(Boolean);
  }

  return targetingObject;
}
