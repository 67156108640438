import { AdDefinition, BATCH_ALONE, BATCH_FIRST } from 'ad-framework/ad/index.types';
import { AdUnitList } from 'ad-framework/ad-list-group/index.types';
import { SlotListGroup } from 'ad-framework/slot-list-group/index.types';
import { Slot, SlotDefinition } from 'ad-framework/slot/index.types';
import { FEATURE } from 'api/feature';
import DataObject from 'state/data-object';
import { PAGE_STYLE_CONSTANTS } from 'state/types/index.types';
import { AnyActorRef, EventObject } from 'xstate';

export enum INCREMENTAL_ADS_EVENTS_OUT {
  FAILURE = 'INCREMENTAL_ADS_FAILURE',
  SUCCESS = 'INCREMENTAL_ADS_SUCCES',
}
export enum INCREMENTAL_ADS_EVENTS_IN {
  ENABLED = 'INCREMENTAL_ADS_ENABLED',
  ROADBLOCK_STATUS = 'INCREMENTAL_ADS_ROADBLOCK_STATUS',
}
export interface IncrementalAdsFailureEvent extends EventObject {
  type: INCREMENTAL_ADS_EVENTS_OUT.FAILURE;
}
export interface IncrementalAdsSuccessEvent extends EventObject {
  type: INCREMENTAL_ADS_EVENTS_OUT.SUCCESS;
}
export interface IncrementalAdsEnabledEvent extends EventObject {
  type: INCREMENTAL_ADS_EVENTS_IN.ENABLED;
}
export interface IncrementalAdsRoadblockStatusEvent extends EventObject {
  type: INCREMENTAL_ADS_EVENTS_IN.ROADBLOCK_STATUS;
  data: boolean;
}
export type IncrementalAdsOutEvents = IncrementalAdsFailureEvent | IncrementalAdsSuccessEvent;
export type IncrementalAdsInEvents =
  | IncrementalAdsEnabledEvent
  | IncrementalAdsRoadblockStatusEvent;

export enum STANDARD_ADS_EVENTS_OUT {
  FAILURE = 'STANDARD_ADS_FAILURE',
  SUCCESS = 'STANDARD_ADS_SUCCES',
}
export enum STANDARD_ADS_EVENTS_IN {
  ENABLED = 'STANDARD_ADS_ENABLED',
}
export interface StandardAdsFailureEvent extends EventObject {
  type: STANDARD_ADS_EVENTS_OUT.FAILURE;
}
export interface StandardAdsSuccessEvent extends EventObject {
  type: STANDARD_ADS_EVENTS_OUT.SUCCESS;
}
export interface StandardAdsEnabledEvent extends EventObject {
  type: STANDARD_ADS_EVENTS_IN.ENABLED;
}
export type StandardAdsOutEvents = StandardAdsFailureEvent | StandardAdsSuccessEvent;
export type StandardAdsInEvents = StandardAdsEnabledEvent;

export enum SLOTIFY_EVENTS_OUT {
  AD_BATCH = 'SLOTIFY_EVENTS_OUT:AD_BATCH',
  NEW_SLOT = 'SLOTIFY_EVENTS_OUT:NEW_SLOT',
  CONTEXT_ERROR = 'SLOTIFY_EVENTS_OUT:CONTEXT_ERROR',
  AD_MATCHES = 'SLOTIFY_EVENTS_OUT:AD_MATCHES',
  SLOT_IN_VIEW = 'SLOTIFY_EVENTS_OUT:SLOT_IN_VIEW',
}

export interface ExternalSlotInViewEvent extends EventObject {
  type: SLOTIFY_EVENTS_OUT.SLOT_IN_VIEW;
  data: DataObject<Slot>;
}

export interface AdBatchEvent extends EventObject {
  type: SLOTIFY_EVENTS_OUT.AD_BATCH;
}

export interface NewSlotEvent extends EventObject {
  type: SLOTIFY_EVENTS_OUT.NEW_SLOT;
  data: DataObject<Slot>;
}

export interface AdMatchesEvent extends EventObject {
  type: SLOTIFY_EVENTS_OUT.AD_MATCHES;
  data: Array<{
    adDefinition: AdDefinition;
    slot?: DataObject<Slot>;
    batch: string | typeof BATCH_ALONE | typeof BATCH_FIRST;
  }>;
}

export interface ContextErrorEvent extends EventObject {
  type: SLOTIFY_EVENTS_OUT.CONTEXT_ERROR;
  data: string;
}

export type SloifyOutEvents =
  | AdBatchEvent
  | NewSlotEvent
  | AdMatchesEvent
  | ExternalSlotInViewEvent;

export enum AD_AFFINITY_FAILED_REASONS {
  ABSENT = 'ABSENT',
  NO_SLOT = 'NO_SLOT',
  SLOT_FILLED = 'SLOT_FILLED',
}

export enum STATES {
  CREATING_STATIC_SLOTS = 'CREATING_STATIC_SLOTS',
  WAIT_FOR_STATIC_SLOTS_READY = 'WAIT_FOR_STATIC_SLOTS_READY',
  WAIT_FOR_STANDARD_ADS_ENABLED = 'WAIT_FOR_STANDARD_ADS_ENABLED',
  WAIT_FOR_INCREMENTAL_ADS_ENABLED = 'WAIT_FOR_INCREMENTAL_ADS_ENABLED',
  YIELDING_STATIC_AFFINITY_ADS = 'YIELDING_STATIC_AFFINITY_ADS',
  WATCHING_INCREMENTAL_SLOTS = 'WATCHING_INCREMENTAL_SLOTS',
  DONE = 'DONE',
}

export enum ACTIONS {
  REPORT_AD_AFFINITY_FAILED = 'REPORT_AD_AFFINITY_FAILED',
  ENABLE_INCREMENTAL_ADS = 'ENABLE_INCREMENTAL_ADS',
  ENABLE_STANDARD_ADS = 'ENABLE_STANDARD_ADS',
  POSITION_SLOT_ELEMENT = 'POSITION_SLOT_ELEMENT',
  ADD_SLOT = 'ADD_SLOT',
  CREATE_ADDITIONAL_SLOT_WATCHERS = 'CREATE_ADDITIONAL_SLOT_WATCHERS',
  CREATE_SLOT_WATCHER = 'CREATE_SLOT_WATCHER',
  REPORT_NEW_SLOT = 'REPORT_NEW_SLOT',
  REPORT_SLOT_HOOK_FAILED = 'REPORT_SLOT_HOOK_FAILED',

  MARK_INCREMENTALS_STARTED = 'MARK_INCREMENTALS_STARTED',
  CREATE_DYNAMIC_SLOT_GENERATOR = 'CREATE_DYNAMIC_SLOT_GENERATOR',
  WATCH_EXCLUSION_ZONES = 'WATCH_EXCLUSION_ZONES',
  CHECK_ROADBLOCK_STATUS = 'CHECK_ROADBLOCK_STATUS',
  RAISE_ENABLE_INCREMENTAL_ADS = 'RAISE_ENABLE_INCREMENTAL_ADS',
  UPDATE_ROADBLOCK_STATUS = 'UPDATE_ROADBLOCK_STATUS',
  RAISE_ENABLE_STANDARD_ADS = 'RAISE_ENABLE_STANDARD_ADS',
  REQUEST_AD_BATCH = 'REQUEST_AD_BATCH',
}

export enum EVENTS {
  SLOT_HOOK_FAILED = 'SLOT_HOOK_FAILED',
  AD_AFFINITY_FAILED = 'AD_AFFINITY_FAILED',

  SLOT_CREATED = 'SLOT_CREATED',
  ADS_MATCH = 'ADS_MATCH',

  STATIC_SLOTS_DONE = 'STATIC_SLOTS_DONE',
  GENERATED_SLOTS_DONE = 'GENERATED_SLOTS_DONE',
  AFFINITY_ADS_DONE = 'AFFINITY_ADS_DONE',
  SLOT_IN_VIEW = 'SLOT_IN_VIEW',

  INCREMENTAL_ADS_ENABLED = 'INCREMENTAL_ADS_ENABLED_INTERNAL',

  FIND_NEW_DYNAMIC_SLOTS = 'FIND_NEW_DYNAMIC_SLOTS',
}

export enum GUARDS {
  STANDARD_ADS_ENABLED = 'STANDARD_ADS_ENABLED',
  INCREMENTAL_ADS_ENABLED = 'INCREMENTAL_ADS_ENABLED',
}

export interface SlotHookFailedEvent extends EventObject {
  type: EVENTS.SLOT_HOOK_FAILED;
  data: SlotDefinition;
}

export interface SlotHookedEvent extends EventObject {
  type: EVENTS.SLOT_CREATED;
  data: DataObject<Slot>;
}

export interface SlotInViewEvent extends EventObject {
  type: EVENTS.SLOT_IN_VIEW;
  data: DataObject<Slot>;
}

export interface StaticSlotsDoneEvent extends EventObject {
  type: EVENTS.STATIC_SLOTS_DONE;
}
export interface GeneratedSlotsDoneEvent extends EventObject {
  type: EVENTS.GENERATED_SLOTS_DONE;
}

export interface AffinityAdsDoneEvent extends EventObject {
  type: EVENTS.AFFINITY_ADS_DONE;
}

export interface IncrementalAdsInternalEvent extends EventObject {
  type: EVENTS.INCREMENTAL_ADS_ENABLED;
}

export interface AdAffinityFailedEvent extends EventObject {
  type: EVENTS.AD_AFFINITY_FAILED;
  data: {
    adDefinition: AdDefinition;
    reason: AD_AFFINITY_FAILED_REASONS;
  };
}
export interface AdMatchEvent extends EventObject {
  type: EVENTS.ADS_MATCH;
  data: Array<{
    adDefinition: AdDefinition;
    slot?: DataObject<Slot>;
  }>;
}

export interface SlotifyMachineContext {
  slotDefinitions: SlotListGroup;
  adDefinitions: AdUnitList;
  pageStyleConstants: Record<PAGE_STYLE_CONSTANTS, string>;
  slots: Array<DataObject<Slot>>;
  features: Record<FEATURE, boolean>;
  pageAdUnitPath: string;
  slotBatch: Array<DataObject<Slot>>;
  automaticDynamic: boolean;
  slotGenerator: AnyActorRef;
  slotWatchers: Array<AnyActorRef>;
  activationDistance: number;
  avoidanceDistance: number;
  isRoadblock: boolean | null;
  incrementalsStarted: boolean;
}
export type AnySlotifyEvent =
  | SlotHookFailedEvent
  | SlotHookedEvent
  | StaticSlotsDoneEvent
  | StandardAdsInEvents
  | AdAffinityFailedEvent
  | AdMatchEvent
  | AffinityAdsDoneEvent
  | SlotInViewEvent
  | IncrementalAdsInternalEvent
  | ContextErrorEvent
  | IncrementalAdsRoadblockStatusEvent;
