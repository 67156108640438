import { getEnv } from 'utils/env';
import { Env } from 'utils/env.types';

import { __tcfapi, __uspapi, __gpp, TCFAPI, USPAPI, GPPAPI } from '../index.types';

export const CMP_TIMEOUT = 5000;

type APITypeMap = {
  __tcfapi: TCFAPI;
  __uspapi: USPAPI;
  __gpp: GPPAPI;
};

// Search the window's ancestors for an attached variable
export function findAPI<APIName extends keyof APITypeMap>(
  api: APIName,
): APITypeMap[APIName] | null {
  const env = getEnv();
  const { top } = env;
  let frame: Window | Env = env;

  do {
    try {
      if (api in frame) {
        break;
      }
    } catch (_e) {}

    frame = frame.parent;
  } while (frame && frame !== top);

  if (Boolean(frame) && api in frame) {
    return frame[api as string] as APITypeMap[APIName];
  }

  return null;
}
