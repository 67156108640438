import { AdUnitStatus, Ad, AdUnitMode } from 'ad-framework/ad/index.types';
import log from 'log';
import metrics from 'metrics';
import { ExtendedGoogletag } from 'utils/env.types';
import { hasUserOptOutCCPA } from 'utils/cmp';
import { getEnv } from 'utils/env';
import createAdLabel from 'ad-framework/ad/create-ad-label';
import { EVENTS } from 'state/types/events.names';
import { REPORT_AUCTION } from 'state/types/report.types';
import BordeauxMachineService from 'state/types/service.types';
import { BordeauxMachineContext } from 'state/types/context.types';
import { timeData } from 'utils/timestamp';
import replaceAmpersand from 'utils/replace-ampersand';
import DataObject from 'state/data-object';
import getTargeting from '../targeting';
import sentry from '../../sentry';

import preProcessAds from './pre-process-ads';

const gptSlots = new Map<string, googletag.Slot>();
const env = getEnv();

let auctionId = 0;

const handleSlotVisibilityChanged = (event: googletag.events.SlotVisibilityChangedEvent): void => {
  const slotElementId = event.slot.getSlotElementId();
  const { ads } = service.getSnapshot().context;
  const ad = ads.getValues().find(ad => ad.getProperty('id') === slotElementId);
  if (!ad) {
    return;
  }

  ad.update({ inView: event.inViewPercentage >= 50 });
  ad.update({ inViewport: event.inViewPercentage > 0 });
  ad.update({ inView75Percent: event.inViewPercentage > 75 });
};

const handleSlotRenderEnded = (event: googletag.events.SlotRenderEndedEvent): void => {
  const slotElementId = event.slot.getSlotElementId();
  const { ads } = service.getSnapshot().context;
  const ad = ads.getValues().find(ad => ad.getProperty('id') === slotElementId);
  if (!ad) {
    return;
  }

  sentry.breadcrumb({
    category: 'advert',
    message: `Ad slotRenderEnded - ${ad.getProperty('name')}`,
  });
  metrics.mark(`Ad slotRenderEnded - ${ad.getProperty('name')}`);

  if (!event.isEmpty) {
    const gptOutput = {
      isEmpty: event.isEmpty,
      size: event.size,
      advertiser: event.advertiserId || -1,
      campaign: event.campaignId || -1,
      lineItem: event.lineItemId || -1,
      creative: event.creativeId || -1,
      creativeTemplate: '',
      gptSlot: event.slot,
    };
    const loadTime = env.performance.now();
    ad.update({
      status: AdUnitStatus.DELIVERED,
      loadTime,
      gptOutput,
    });

    const adLabel = createAdLabel(ad);
    const adElement = ad.getProperty('element');

    if (adLabel && adElement) {
      adElement.insertBefore(adLabel, adElement.firstChild);
    }
  } else {
    const gptSlot = gptSlots.get(ad.getProperty('id'));
    if (gptSlot === undefined) {
      log.error('Error handling GPT slot render ended (undelivered), GPT slot is undefined.');
      return;
    }

    ad.update({ status: AdUnitStatus.UNDELIVERED });
  }

  service.send({
    type: EVENTS.CHECK_ROADBLOCK_STATUS,
  });
};

const handleSlotOnLoad = (event: googletag.events.SlotOnloadEvent): void => {
  const slotElementId = event.slot.getSlotElementId();
  const { ads } = service.getSnapshot().context;
  const ad = ads.getValues().find(ad => ad.getProperty('id') === slotElementId);
  if (!ad) {
    return;
  }

  sentry.breadcrumb({
    category: 'advert',
    message: `Ad slotOnLoad - ${ad.getProperty('name')}`,
  });
  metrics.mark(`Ad slotOnLoad - ${ad.getProperty('name')}`);

  const auctionId = ad.getProperty('auctionId');
  if (!auctionId) {
    log.error('Error handling GPT slot loaded, auctionId is undefined.');
    return;
  }
  service.send({
    type: REPORT_AUCTION.AD_LOAD,
    data: {
      time: timeData(),
      auction: auctionId,
    },
  });
};

const handleImpressionViewable = (event: googletag.events.ImpressionViewableEvent): void => {
  const slotElementId = event.slot.getSlotElementId();
  const { ads } = service.getSnapshot().context;
  const ad = ads.getValues().find(ad => ad.getProperty('id') === slotElementId);
  if (!ad) {
    return;
  }

  ad.update({ viewed: true, status: AdUnitStatus.VIEWED });
};

export const init = (context: BordeauxMachineContext): void => {
  const userHasOptOutCCPA = hasUserOptOutCCPA(context.uspConsent);

  const { googletag } = env;
  if (!googletag) {
    log.error('googletag is unavailable, unable to initialise.');
    return;
  }

  if (context.hybridId) {
    googletag.pubads().setPublisherProvidedId(context.hybridId);
  }

  googletag.pubads().enableAsyncRendering();
  googletag.pubads().collapseEmptyDivs(true);
  googletag.pubads().enableSingleRequest();
  googletag.pubads().disableInitialLoad();
  googletag.pubads().setCentering(true);

  if (userHasOptOutCCPA) {
    googletag.pubads().setPrivacySettings({ restrictDataProcessing: true });
  }

  googletag.pubads().addEventListener('slotVisibilityChanged', handleSlotVisibilityChanged);
  googletag.pubads().addEventListener('slotRenderEnded', handleSlotRenderEnded);
  googletag.pubads().addEventListener('slotOnload', handleSlotOnLoad);
  googletag.pubads().addEventListener('impressionViewable', handleImpressionViewable);

  googletag.setAdIframeTitle('Advertisement');

  googletag.enableServices();
};

export const destroy = async (advertIds: Array<string>): Promise<void> => {
  const { googletag } = env;
  if (!googletag) {
    log.error('googletag is unavailable, unable to destroy.');
    return Promise.resolve();
  }

  advertIds.forEach(advertId => {
    const slot = gptSlots.get(advertId);
    if (slot === undefined) {
      return;
    }

    // This should return true/false but the Index wrapped version returns undefined
    // Once this is fixed we can check if the destroy succeeded
    googletag.destroySlots([slot]);
  });

  return Promise.resolve();
};

const defineGPTSlot = (ad: DataObject<Ad>): googletag.Slot | null => {
  const { googletag } = env;
  if (!googletag) {
    log.error('googletag is unavailable, unable to define slot.');
    return null;
  }

  const id = ad.getProperty('id');
  const targeting = ad.getProperty('targeting');
  const mode = ad.getProperty('mode');
  const name = ad.getProperty('name');
  const adUnitPath = ad.getProperty('adUnitPath');
  const isOutOfPage = mode === AdUnitMode.OOP;
  const isInterstitial = mode === AdUnitMode.INTERSTITIAL;
  const sizes = isOutOfPage ? [[1, 1]] : ad.getProperty('sizes');

  let gptSlot: googletag.Slot | null = null;
  try {
    if (isOutOfPage) {
      gptSlot = googletag.defineOutOfPageSlot(adUnitPath, id);
    } else if (isInterstitial) {
      gptSlot = googletag.defineOutOfPageSlot(
        adUnitPath,
        (googletag as ExtendedGoogletag).enums.OutOfPageFormat.INTERSTITIAL,
      );
    } else {
      if (sizes === undefined) throw new Error('Error defining GPT slot, sizes are undefined.');
      gptSlot = googletag.defineSlot(adUnitPath, sizes, id);
    }
  } catch (error) {
    if (error instanceof Error) log.error(`Error defining GPT slot. ${error.toString()}`);
    return null;
  }

  if (gptSlot === null) {
    log.error(`Unable to define GPT slot. advert: ${name}, adUnitPath: ${adUnitPath}`);
    return null;
  }

  Object.keys(targeting).forEach(key => {
    if (gptSlot !== null) {
      gptSlot.setTargeting(key, targeting[key]);
    }
  });
  gptSlot.addService(googletag.pubads());

  if (!isInterstitial) {
    googletag.display(id);
  }

  const adID = ad.getProperty('id');

  gptSlot.setTargeting('auctionId', auctionId.toString());
  gptSlots.set(adID, gptSlot);

  return gptSlot;
};

export const fetch = async (ads: Array<DataObject<Ad>>): Promise<void> => {
  if (ads.length === 0) {
    log.error('Called GAM API fetch with no ads.');
    return Promise.resolve();
  }

  const { googletag } = env;
  if (!googletag) {
    log.error('googletag is unavailable, unable to fetch.');
    return Promise.resolve();
  }

  const adNames = ads.map(ad => ad.getProperty('name'));

  const id = ++auctionId;

  service.send({
    type: REPORT_AUCTION.START,
    data: {
      time: timeData(),
      auction: id,
      adNames,
    },
  });

  ads.forEach(ad => {
    ad.update({
      auctionId: id,
      fetchTime: env.performance.now(),
    });
    defineGPTSlot(ad);
  });

  const { context } = service.getSnapshot();
  const targeting = replaceAmpersand(await getTargeting(context));

  Object.keys(targeting).forEach(key => {
    googletag.pubads().setTargeting(key, targeting[key]);
  });

  return (
    context.gdprConsent.hasEnoughConsentForAuction
      ? preProcessAds(service, context, ads, id, targeting)
      : Promise.resolve()
  )
    .then(() => {
      service.send({
        type: REPORT_AUCTION.END,
        data: {
          time: timeData(),
          auction: id,
        },
      });
      const refreshSlots = ads
        .map(ad => {
          const adID = ad.getProperty('id');
          return gptSlots.get(adID);
        })
        .filter((value): value is googletag.Slot => value !== undefined);
      googletag.pubads().refresh(refreshSlots, { changeCorrelator: false });
    })
    .catch(error => {
      log.error('GAM API fetch error: ', error);
    });
};

export const refresh = (ads: Array<DataObject<Ad>>): Promise<void> => {
  if (ads.length === 0) {
    log.error('Called GAM API refresh with no ads.');
    return Promise.resolve();
  }

  const adNames = ads.map(ad => ad.getProperty('name'));

  sentry.breadcrumb({
    category: 'script',
    message: `GAM API refresh - ${adNames}`,
  });
  metrics.mark(`GAM API refresh - ${adNames}`);

  return destroy(ads.map(ad => ad.getProperty('id'))).then(() => fetch(ads));
};

let service: BordeauxMachineService;
export const setServiceReference = (newService: BordeauxMachineService): void => {
  service = newService;
};
