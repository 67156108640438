import { getEnv } from 'utils/env';
import { memoizeWith, identity } from 'ramda';

import { DeviceOptions } from './index.types';

const env = getEnv();

const MIN_TABLET_WIDTH = 700;
const MIN_DESKTOP_WIDTH = 1000;

const getEnvWidth = memoizeWith(identity as () => string, (): number => {
  return (
    env.document.documentElement.clientWidth || env.document.body.clientWidth || env.innerWidth
  );
});

const getEnvHeight = memoizeWith(identity as () => string, (): number => {
  return (
    env.document.documentElement.clientHeight || env.document.body.clientHeight || env.innerHeight
  );
});

export const getEnvSize = (): {
  width: number;
  height: number;
} => {
  return {
    width: getEnvWidth(),
    height: getEnvHeight(),
  };
};

const getDeviceSize = (): DeviceOptions => {
  const windowWidth = getEnvWidth();
  if (windowWidth >= MIN_TABLET_WIDTH && windowWidth < MIN_DESKTOP_WIDTH) {
    return DeviceOptions.TABLET;
  }
  if (windowWidth >= MIN_DESKTOP_WIDTH) {
    return DeviceOptions.DESKTOP;
  }
  return DeviceOptions.MOBILE;
};

export default getDeviceSize;
