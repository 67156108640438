import { SlotDefinition } from 'ad-framework/slot/index.types';
import querySelector from 'utils/query-selector';
import querySelectorAll from 'utils/query-selector-all';

const selectSlotHooks = (slotDefinition: SlotDefinition): Array<HTMLElement> => {
  if (slotDefinition.multiple) {
    const hookElements = querySelectorAll<HTMLElement>(slotDefinition.hook);
    return hookElements;
  }
  const hookElement = querySelector<HTMLElement>(slotDefinition.hook);
  if (hookElement) {
    return [hookElement];
  }
  return [];
};
export default selectSlotHooks;
