export default class DataObject<DataType extends object> {
  private value: DataType;

  private subscribers: Array<(value: DataType) => void> = [];

  constructor(initialValue: DataType) {
    this.value = initialValue;
  }

  public update(updates: Partial<DataType>): void {
    Object.assign(this.value, updates);
    this.subscribers.forEach(subscriber => subscriber({ ...this.value }));
  }

  public getProperty<Property extends keyof DataType>(property: Property): DataType[Property] {
    return this.value[property];
  }

  public subscribe(callback: (value: DataType) => void): void {
    this.subscribers.push(callback);
  }
}
