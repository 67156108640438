import { AdDefinition } from 'ad-framework/ad/index.types';
import { AdUnitListGroup } from './index.types';

const adaptAdUnitListGroupConfig = (adUnits: Array<AdDefinition>): AdUnitListGroup => ({
  standard: adUnits
    .filter(adUnit => !adUnit.incremental)
    .map(
      (adUnit, index): AdDefinition => ({
        ...adUnit,
        requestOrder: index,
      }),
    ),
  incremental: adUnits.filter(adUnit => adUnit.incremental),
});

export default adaptAdUnitListGroupConfig;
