import { API_EVENTS_OUT } from './events.names';
import { ApiMachineEventService } from './events.service';

let refreshPaused = false;

export default (service: ApiMachineEventService): boolean => {
  refreshPaused = !refreshPaused;
  service.sendEvent({
    type: API_EVENTS_OUT.SET_REFRESH_PAUSED,
    data: refreshPaused,
  });
  return refreshPaused;
};
