import log from 'log';
import TimeoutError from 'utils/error/timeout';
import getParam from 'utils/get-param';
import { getEnv } from 'utils/env';
import AbTest from 'utils/abtest';
import { HybridBaseRequest } from '../request.types';
import { SommelierResponse } from './sommelier-response.types';

const env = getEnv();

const HOST = 'https://sommelier.futurehybrid.tech';
const FORCE_ABTEST_PARAMETER = 'force_abtest';
const timeout = 5000;
const timeoutErrorMessage = `Took more than ${timeout}ms to resolve and timed out`;

export const getForceAbtests = (): string => {
  const abTestsFromLocation: Record<string, AbTest> = AbTest.splitAbTests(
    getParam(FORCE_ABTEST_PARAMETER),
  );
  const abTestFromSessionStorage: Record<string, AbTest> = AbTest.splitAbTests(
    env.sessionStorage.getItem(FORCE_ABTEST_PARAMETER),
  );

  const forceAbtests: string = AbTest.flattenAbTests({
    ...abTestsFromLocation,
    ...abTestFromSessionStorage,
  });
  if (forceAbtests && forceAbtests.length > 0) {
    env.sessionStorage.setItem(FORCE_ABTEST_PARAMETER, forceAbtests);
  }
  return forceAbtests;
};

class SommelierRequest implements HybridBaseRequest {
  templateName = '';

  location = '';

  screenWidth = '';

  setTemplateName(templateName: string): SommelierRequest {
    this.templateName = templateName;
    return this;
  }

  setLocation(location: string): SommelierRequest {
    this.location = location;
    return this;
  }

  setScreenWidth(screenWidth: string): SommelierRequest {
    this.screenWidth = screenWidth;
    return this;
  }

  async execute(): Promise<SommelierResponse> {
    const forcePlacementDb = getParam('force_plc_db');
    const forceAbtest = encodeURI(getForceAbtests());
    const queryParams: Array<Array<string | number | Array<string>>> = [
      ['r', Math.round(Math.random() * 1000)],
      ['tpl', this.templateName],
      ...(forcePlacementDb ? [['force_plc_db', forcePlacementDb]] : []),
      ...(forceAbtest ? [['fabt', forceAbtest]] : []),
      ['l', this.location],
      ['sw', this.screenWidth],
    ];

    const queryString: string = queryParams.map(pair => pair.join('=')).join('&');

    const url = `${HOST}/config/?${queryString}`;
    return Promise.race<SommelierResponse>([
      fetch(url, { credentials: 'omit', cache: 'no-store' })
        .then(response => response.json() as SommelierResponse)
        .catch(error => {
          log.warn(`AdServer /config request error: ${error}`);
          return Promise.reject(error);
        }),
      new Promise((_resolve, reject) => {
        setTimeout(() => reject(new TimeoutError(timeoutErrorMessage)), timeout);
      }),
    ]);
  }
}

export default (): SommelierRequest => new SommelierRequest();
