import DataObject from 'state/data-object';
import { getFetchTargetingValues } from 'utils/get-gpt-targeting';
import { mergeDeepRight } from 'ramda';
import { Ad, AdDefinition, AdUnitStatus, BATCH_ALONE } from './index.types';

const createAd = (
  adDefinition: AdDefinition,
  adOverrides: Pick<Ad, 'id' | 'adUnitPath'> & Partial<Ad>,
) =>
  new DataObject<Ad>(
    mergeDeepRight(
      {
        ...adDefinition,
        batch: BATCH_ALONE,
        inView: false,
        inView75Percent: false,
        viewed: false,
        inViewport: false,
        viewedTime: 0,
        fetchTime: 0,
        loadTime: 0,
        status: AdUnitStatus.PENDING,
        targeting: {
          ...adDefinition.targeting,
          ...getFetchTargetingValues(adDefinition),
        },
      },
      adOverrides,
    ) as Ad,
  );
export default createAd;
