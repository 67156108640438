import log, { api as logAPI } from 'log';
import metrics from 'metrics';
import sentry from 'sentry';
import { getEnv } from 'utils/env';
import generateSessionId from 'utils/group-generator';
import { PartnerRequest, RequestStatus, Script } from 'timing-collection/payload.types';
import { mergeListItem } from 'utils/object';
import { timeData } from 'utils/timestamp';
import sendBeacon from 'utils/send-beacon';
import { API_EVENTS_OUT } from 'api/events.names';
import sendPartnerBidsToFreyr from 'utils/freyr';
import { GUARDS } from './types/index.types';
import { PageLoadEvent, PageUnloadEvent } from './types/events.types';
import { EVENTS } from './types/events.names';
import {
  ReportEvent,
  AuctionPartnerReportEvent,
  AuctionReportEvent,
  Metrics,
  REPORT,
  REPORT_AUCTION,
  REPORT_AUCTION_PARTNER,
  REPORT_THIRD_PARTY_SCRIPT,
  ThirdPartyReportEvent,
  AuctionReportStartEvent,
} from './types/report.types';
import ActionArgs from './proxy/action-args.types';
import assign from './proxy/assign';
import enqueueActions from './proxy/enqueueActions';
import raise from './proxy/raise';

export const multipleScripts = () => {
  sentry.reportError(`More than one Bordeaux script has been loaded on this page.`);
};

export const frameworkRequest = (): void => {
  metrics.recordFrameworkRequest();
  metrics.mark('Bordeaux framework loaded');
  sentry.breadcrumb({
    category: 'script',
    message: 'Bordeaux framework loaded',
  });
};

export const contentLoad = ({ event }: ActionArgs<ReportEvent<REPORT.CONTENT_LOAD>>) => {
  metrics.mark({ message: 'DOMContentLoaded event', time: event.data.time.timeStamp });
};

export const initialiseSentry = ({ context }: ActionArgs) => {
  const env = getEnv();
  sentry.extraData({
    'device.size': context.pageParameters.device,
    'url.domain': env.location.hostname,
  });
  logAPI.onLogError(logEvent => {
    sentry.reportError(logEvent.logItem.args.toString());
  });
};

export const adBlocked = (): void => {
  metrics.recordAdsBlock();
};

export const pageLoad = ({ event }: ActionArgs<PageLoadEvent>) => {
  metrics.mark({ message: 'PageLoad event', time: event.data.timeStamp });
};

export const pageUnload = ({ context, event }: ActionArgs<PageUnloadEvent>) => {
  context.timing.unsentAuctions.forEach(id => {
    sendBeacon(context.timing.endpoint, {
      ...context.timing.payload,
      user: {
        ...context.timing.payload.user,
        unload_time: event.data.dateString,
      },
      auction: context.timing.auctionPayloads[id],
    });
  });
};

export const configCreate = assign({
  metrics: ({ context }) => ({
    ...context.metrics,
    [Metrics.CONFIG]: metrics.mark('Sommelier config requested'),
  }),
});

export const configRequest = (): void => {
  sentry.breadcrumb({
    category: 'script',
    message: 'Sommelier config requested',
  });
};

export const configError = ({
  context,
  event,
}: ActionArgs<ReportEvent<REPORT.CONFIG_FAILURE>>): void => {
  const requestConfig = context.metrics[Metrics.CONFIG];
  if (requestConfig === undefined)
    log.error(`Config request not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'config',
    message: 'Sommelier config request error',
  });
  metrics.mark('Sommelier config request error', requestConfig);
  log.error(`Sommelier config request failed: ${event.data.error?.message}`);
  sentry.disableReporting();
};

export const configFailure = (): void => {
  metrics.recordConfigLoadFail();
};

export const adsLoaded = (): void => {
  metrics.recordAdsLoad();
};

export const configTimeout = (): void => {
  metrics.recordConfigTimeout();
};

export const configLoad = (): void => {
  metrics.recordConfigLoad();
};

export const configEmpty = ({ context }: ActionArgs): void => {
  const requestConfig = context.metrics[Metrics.CONFIG];
  if (requestConfig === undefined)
    log.error(`Config request not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'config',
    message: 'Sommelier config empty',
  });
  metrics.mark('Sommelier config empty', requestConfig);
  log.error('Sommelier config response empty.');
  metrics.recordConfigEmpty();
  sentry.disableReporting();
};

export const configParseFailure = ({
  context,
  event: {
    data: { error },
  },
}: ActionArgs<ReportEvent<REPORT.CONFIG_PARSE_FAILURE>>): void => {
  const requestConfig = context.metrics[Metrics.CONFIG];
  if (requestConfig === undefined)
    log.error(`Config request not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'config',
    message: 'Sommelier config parse error',
  });
  metrics.mark('Sommelier config parse error', requestConfig);
  log.error(
    'Unable to parse the config response, encountered the following issues: ',
    error?.message,
  );
  metrics.recordConfigParseError();
  sentry.disableReporting();
};

export const configSuccess = ({ context }: ActionArgs): void => {
  const requestConfig = context.metrics[Metrics.CONFIG];
  if (requestConfig === undefined)
    log.error(`Config request not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'config',
    message: 'Sommelier config loaded',
  });
  metrics.mark('Sommelier config loaded', requestConfig);
  sentry.breadcrumb({
    category: 'config',
    message: 'Config retrieved',
  });
};

export const consentRequest = (): void => {
  sentry.breadcrumb({
    category: 'consent',
    message: 'Consent requested',
  });
};

export const consentFailure = (): void => {
  sentry.breadcrumb({
    category: 'consent',
    message: 'Consent error',
  });
};

export const consentSuccess = (): void => {
  sentry.breadcrumb({
    category: 'consent',
    message: 'Consent retrieved',
  });
};

export const thirdPartyCreate = assign({
  metrics: ({ context }) => ({
    ...context.metrics,
    [Metrics.THIRD_PARTY]: metrics.mark('Third parties requested'),
  }),
});

export const thirdPartyRequest = (): void => {
  sentry.breadcrumb({
    category: 'apis',
    message: 'Third parties requested',
  });
};

export const thirdPartySuccess = ({ context }: ActionArgs): void => {
  const requestThirdParty = context.metrics[Metrics.THIRD_PARTY];
  if (requestThirdParty === undefined)
    log.error(`Third Party request not measured, reporting may be incomplete.`);

  metrics.recordThirdPartiesLoad();
  sentry.breadcrumb({
    category: 'apis',
    message: 'Third parties loaded',
  });
  metrics.mark('Third parties loaded', requestThirdParty);
};

export const thirdPartyScriptCreate = assign({
  metrics: ({ context, event }) =>
    event.type === REPORT_THIRD_PARTY_SCRIPT.REQUEST
      ? {
          ...context.metrics,
          [Metrics.THIRD_PARTY_SCRIPTS]: {
            ...context.metrics[Metrics.THIRD_PARTY_SCRIPTS],
            [event.data.thirdParty]: metrics.mark(`${event.data.thirdParty} requested`),
          },
        }
      : context.metrics,
});

export const thirdPartyScriptRequest = ({
  event,
}: ActionArgs<ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.REQUEST>>): void => {
  sentry.breadcrumb({
    category: 'apis',
    message: `${event.data.thirdParty} requested`,
  });
};

export const thirdPartyScriptSuccess = ({
  context,
  event,
}: ActionArgs<ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.SUCCESS>>): void => {
  const requestThirdParty = context.metrics[Metrics.THIRD_PARTY_SCRIPTS]?.[event.data.thirdParty];
  if (requestThirdParty === undefined)
    log.error(
      `Third Party Script request (${event.data.thirdParty}) not measured, reporting may be incomplete.`,
    );

  sentry.breadcrumb({
    category: 'apis',
    message: `${event.data.thirdParty} loaded`,
  });
  metrics.mark(`${event.data.thirdParty} loaded`, requestThirdParty);
};

export const thirdPartyScriptTimeout = ({
  context,
  event,
}: ActionArgs<ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.TIMEOUT>>): void => {
  const requestThirdParty = context.metrics[Metrics.THIRD_PARTY_SCRIPTS]?.[event.data.thirdParty];
  if (requestThirdParty === undefined)
    log.error(
      `Third Party Script request (${event.data.thirdParty}) not measured, reporting may be incomplete.`,
    );

  sentry.breadcrumb({
    category: 'apis',
    message: `${event.data.thirdParty} timeout`,
  });
  metrics.mark(`${event.data.thirdParty} timeout`, requestThirdParty);
};

export const thirdPartyScriptFailure = ({
  context,
  event,
}: ActionArgs<ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.FAILURE>>): void => {
  const requestThirdParty = context.metrics[Metrics.THIRD_PARTY_SCRIPTS]?.[event.data.thirdParty];
  if (requestThirdParty === undefined)
    log.error(
      `Third Party Script request (${event.data.thirdParty}) not measured, reporting may be incomplete.`,
    );

  sentry.breadcrumb({
    category: 'apis',
    message: `${event.data.thirdParty} failed`,
  });
  metrics.mark(`${event.data.thirdParty} failed`, requestThirdParty);
};

export const auctionCreate = assign({
  auctions: ({ context, event }) =>
    event.type === REPORT_AUCTION.START
      ? {
          ...context.auctions,
          [event.data.auction]: {
            adNames: event.data.adNames,
          },
        }
      : context.auctions,
  metrics: ({ context, event }) =>
    event.type === REPORT_AUCTION.START
      ? {
          ...context.metrics,
          [Metrics.AUCTIONS]: {
            ...context.metrics[Metrics.AUCTIONS],
            [event.data.auction]: {
              mark: metrics.mark(`Ad third party requests - ${event.data.adNames}`),
              partners: {},
            },
          },
        }
      : context.metrics,
});

export const auctionStart = ({ event }: ActionArgs<AuctionReportStartEvent>) => {
  sentry.breadcrumb({
    category: 'script',
    message: `Ad fetch - ${event.data.adNames}`,
  });
  metrics.mark(`Ad fetch - ${event.data.adNames}`);
  sentry.breadcrumb({
    category: 'script',
    message: `Ad third party requests - ${event.data.adNames}`,
  });
};

export const auctionEnd = ({
  context,
  event,
}: ActionArgs<AuctionReportEvent<REPORT_AUCTION.END>>) => {
  const requestAuctionMetric = context.metrics[Metrics.AUCTIONS]?.[event.data.auction];
  const requestAuction = context.auctions[event.data.auction];
  if (!requestAuctionMetric || !requestAuction)
    log.error(`Auction request not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'script',
    message: `Ad third party responses - ${requestAuction.adNames}`,
  });
  metrics.mark(`Ad third party responses - ${requestAuction.adNames}`, requestAuctionMetric?.mark);
  sentry.breadcrumb({
    category: 'script',
    message: `Ad GAM request - ${requestAuction.adNames}`,
  });
  metrics.mark(`Ad GAM request - ${requestAuction.adNames}`);
};

export const auctionPartnerCreate = assign({
  metrics: ({ context, event }) =>
    event.type === REPORT_AUCTION_PARTNER.REQUEST
      ? {
          ...context.metrics,
          [Metrics.AUCTIONS]: {
            ...context.metrics[Metrics.AUCTIONS],
            [event.data.auction]: {
              mark: 0,
              ...context.metrics[Metrics.AUCTIONS][event.data.auction],
              partners: {
                ...context.metrics[Metrics.AUCTIONS][event.data.auction]?.partners,
                [event.data.partner]: metrics.mark(`${event.data.partner} requested`),
              },
            },
          },
        }
      : context.metrics,
});

export const auctionPartnerRequest = ({
  event,
}: ActionArgs<AuctionPartnerReportEvent<REPORT_AUCTION_PARTNER.REQUEST>>): void => {
  sentry.breadcrumb({
    category: 'script',
    message: `${event.data.partner} requested`,
  });
};

export const auctionPartnerSuccess = ({
  context,
  event,
}: ActionArgs<AuctionPartnerReportEvent<REPORT_AUCTION_PARTNER.SUCCESS>>): void => {
  const requestPartner =
    context.metrics[Metrics.AUCTIONS]?.[event.data.auction]?.partners[event.data.partner];
  if (requestPartner === undefined)
    log.error(`Auction partner {${event.data.partner}} not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'script',
    message: `${event.data.partner} response`,
  });
  metrics.mark(`${event.data.partner} response`, requestPartner);
};

export const auctionPartnerTimeout = ({
  context,
  event,
}: ActionArgs<AuctionPartnerReportEvent<REPORT_AUCTION_PARTNER.TIMEOUT>>): void => {
  const requestPartner =
    context.metrics[Metrics.AUCTIONS]?.[event.data.auction]?.partners[event.data.partner];
  if (requestPartner === undefined)
    log.error(`Auction partner {${event.data.partner}} not measured, reporting may be incomplete.`);

  sentry.breadcrumb({
    category: 'script',
    message: `${event.data.partner} timeout`,
  });
  metrics.mark(`${event.data.partner} timeout`, requestPartner);
};

const findMatchesName =
  (matchName: string) =>
  ({ name }: { name: string }): boolean =>
    matchName === name;

export enum ACTIONS_RECORD {
  EXPERIMENT_ID = 'RECORD_EXPERIMENT_ID',
  HYBRID_ABTEST_TARGETING = 'RECORD_HYBRID_ABTEST_TARGETING',
  HYBRID_ID = 'RECORD_HYBRID_ID',
  CFT_PARAMETERS = 'RECORD_CFT_PARAMETERS',

  INITIALISE_PAYLOAD = 'RECORD_INITIALISE_PAYLOAD',

  CONFIG_REQUEST = 'RECORD_CONFIG_REQUEST',
  CONFIG_TIMEOUT = 'RECORD_CONFIG_TIMEOUT',
  CONFIG_FAILURE = 'RECORD_CONFIG_FAILURE',
  CONFIG_SUCCESS = 'RECORD_CONFIG_SUCCESS',

  THIRD_PARTY_REQUEST = 'RECORD_THIRD_PARTY_REQUEST',
  THIRD_PARTY_SUCCESS = 'RECORD_THIRD_PARTY_SUCCESS',

  CONSENT_REQUEST = 'RECORD_CONSENT_REQUEST',
  CONSENT_PENDING = 'RECORD_CONSENT_PENDING',
  CONSENT_FAILURE = 'RECORD_CONSENT_FAILURE',
  CONSENT_SUCCESS = 'RECORD_CONSENT_SUCCESS',

  THIRD_PARTY_SCRIPT_REQUEST = 'RECORD_THIRD_PARTY_SCRIPT_REQUEST',
  THIRD_PARTY_SCRIPT_TIMEOUT = 'RECORD_THIRD_PARTY_SCRIPT_TIMEOUT',
  THIRD_PARTY_SCRIPT_FAILURE = 'RECORD_THIRD_PARTY_SCRIPT_FAILURE',
  THIRD_PARTY_SCRIPT_SUCCESS = 'RECORD_THIRD_PARTY_SCRIPT_SUCCESS',

  AUCTION_START = 'RECORD_AUCTION_START',
  AUCTION_PARTNER_REQUEST = 'RECORD_AUCTION_PARTNER_REQUEST',
  AUCTION_PARTNER_SUCCESS = 'RECORD_AUCTION_PARTNER_SUCCESS',
  AUCTION_PARTNER_TIMEOUT = 'RECORD_AUCTION_PARTNER_TIMEOUT',
  AUCTION_END = 'RECORD_AUCTION_END',
  AUCTION_AD_LOAD = 'RECORD_AUCTION_AD_LOAD',
}

export const recordActions = {
  [ACTIONS_RECORD.CONFIG_REQUEST]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT.CONFIG_REQUEST
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              config: {
                ...context.timing.payload.config,
                start_time: event.data.time.dateString,
                status: RequestStatus.PENDING,
              },
            },
          }
        : context.timing,
  }),
  [ACTIONS_RECORD.CONFIG_SUCCESS]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT.CONFIG_SUCCESS
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              config: {
                ...context.timing.payload.config,
                end_time: event.data.time.dateString,
                status: RequestStatus.SUCCESSFUL,
              },
            },
          }
        : context.timing,
  }),
  [ACTIONS_RECORD.CONFIG_TIMEOUT]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT.CONFIG_FAILURE
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              config: {
                ...context.timing.payload.config,
                end_time: event.data.time.dateString,
                status: RequestStatus.TIMEOUT,
              },
            },
          }
        : context.timing,
  }),
  [ACTIONS_RECORD.CONFIG_FAILURE]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT.CONFIG_FAILURE ||
      event.type === REPORT.CONFIG_EMPTY ||
      event.type === REPORT.CONFIG_PARSE_FAILURE
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              config: {
                ...context.timing.payload.config,
                end_time: event.data.time.dateString,
                status: RequestStatus.FAILED,
              },
            },
          }
        : context.timing,
  }),

  [ACTIONS_RECORD.THIRD_PARTY_REQUEST]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT.THIRD_PARTY_REQUEST
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              third_parties: {
                ...context.timing.payload.third_parties,
                start_time: event.data.time.dateString,
              },
            },
          }
        : context.timing,
  }),
  [ACTIONS_RECORD.THIRD_PARTY_SUCCESS]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT.THIRD_PARTY_SUCCESS
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              third_parties: {
                ...context.timing.payload.third_parties,
                end_time: event.data.time.dateString,
              },
            },
          }
        : context.timing,
  }),

  [ACTIONS_RECORD.THIRD_PARTY_SCRIPT_REQUEST]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT_THIRD_PARTY_SCRIPT.REQUEST
        ? mergeListItem(
            context.timing,
            ['payload', 'third_parties', 'scripts'],
            findMatchesName(event.data.thirdParty),
            (existing: Script | undefined) => ({
              name: event.data.thirdParty,
              end_time: '',
              ...(existing ?? {}),
              start_time: event.data.time.dateString,
              status: RequestStatus.PENDING,
            }),
          )
        : context.timing,
  }),
  [ACTIONS_RECORD.THIRD_PARTY_SCRIPT_SUCCESS]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT_THIRD_PARTY_SCRIPT.SUCCESS
        ? mergeListItem(
            context.timing,
            ['payload', 'third_parties', 'scripts'],
            findMatchesName(event.data.thirdParty),
            (existing: Script | undefined) => ({
              name: event.data.thirdParty,
              start_time: '',
              ...(existing ?? {}),
              end_time: event.data.time.dateString,
              status: RequestStatus.SUCCESSFUL,
            }),
          )
        : context.timing,
  }),
  [ACTIONS_RECORD.THIRD_PARTY_SCRIPT_TIMEOUT]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT_THIRD_PARTY_SCRIPT.TIMEOUT
        ? mergeListItem(
            context.timing,
            ['payload', 'third_parties', 'scripts'],
            findMatchesName(event.data.thirdParty),
            (existing: Script | undefined) => ({
              name: event.data.thirdParty,
              start_time: '',
              ...(existing ?? {}),
              end_time: event.data.time.dateString,
              status: RequestStatus.TIMEOUT,
            }),
          )
        : context.timing,
  }),
  [ACTIONS_RECORD.THIRD_PARTY_SCRIPT_FAILURE]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT_THIRD_PARTY_SCRIPT.FAILURE
        ? mergeListItem(
            context.timing,
            ['payload', 'third_parties', 'scripts'],
            findMatchesName(event.data.thirdParty),
            (existing: Script | undefined) => ({
              name: event.data.thirdParty,
              start_time: '',
              ...(existing ?? {}),
              end_time: event.data.time.dateString,
              status: RequestStatus.FAILED,
            }),
          )
        : context.timing,
  }),

  [ACTIONS_RECORD.CONSENT_REQUEST]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT.CONSENT_REQUEST
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              cmp: {
                ...context.timing.payload.cmp,
                start_time: context.timing.payload.cmp.start_time || event.data.time.dateString,
                status: RequestStatus.PENDING,
              },
            },
          }
        : context.timing,
  }),
  [ACTIONS_RECORD.CONSENT_PENDING]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT.CONSENT_PENDING
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              cmp: {
                ...context.timing.payload.cmp,
                pending_time: context.timing.payload.cmp.pending_time || event.data.time.dateString,
                status: RequestStatus.PENDING,
              },
            },
          }
        : context.timing,
  }),
  [ACTIONS_RECORD.CONSENT_SUCCESS]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT.CONSENT_LOADED
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              cmp: {
                ...context.timing.payload.cmp,
                load_time: context.timing.payload.cmp.load_time || event.data.time.dateString,
                status: RequestStatus.SUCCESSFUL,
              },
            },
          }
        : context.timing,
  }),
  [ACTIONS_RECORD.CONSENT_FAILURE]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT.CONSENT_FAILURE || event.type === REPORT.CONSENT_MOCKED
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              cmp: {
                ...context.timing.payload.cmp,
                fail_time: event.data.time.dateString,
                status: RequestStatus.FAILED,
              },
            },
          }
        : context.timing,
  }),

  [ACTIONS_RECORD.AUCTION_START]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT_AUCTION.START
        ? {
            ...context.timing,
            auctionPayloads: {
              ...context.timing.auctionPayloads,
              [event.data.auction]: {
                id: event.data.auction,
                start_time: event.data.time.dateString,
                end_time: null,
                adload_time: null,
                partner_requests: [],
              },
            },
            unsentAuctions: [...context.timing.unsentAuctions, event.data.auction],
          }
        : context.timing,
  }),
  [ACTIONS_RECORD.AUCTION_END]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT_AUCTION.END
        ? {
            ...context.timing,
            auctionPayloads: {
              ...context.timing.auctionPayloads,
              [event.data.auction]: {
                ...context.timing.auctionPayloads[event.data.auction],
                end_time: event.data.time.dateString,
              },
            },
          }
        : context.timing,
  }),
  [ACTIONS_RECORD.AUCTION_AD_LOAD]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT_AUCTION.AD_LOAD
        ? {
            ...context.timing,
            auctionPayloads: {
              ...context.timing.auctionPayloads,
              [event.data.auction]: {
                ...context.timing.auctionPayloads[event.data.auction],
                adload_time: event.data.time.dateString,
              },
            },
          }
        : context.timing,
  }),
  [ACTIONS_RECORD.AUCTION_PARTNER_REQUEST]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT_AUCTION_PARTNER.REQUEST
        ? mergeListItem(
            context.timing,
            ['auctionPayloads', event.data.auction, 'partner_requests'],
            findMatchesName(event.data.partner),
            (existing: PartnerRequest | undefined) => ({
              name: event.data.partner,
              end_time: null,
              ...(existing ?? {}),
              start_time: event.data.time.dateString,
              status: RequestStatus.PENDING,
            }),
          )
        : context.timing,
  }),
  [ACTIONS_RECORD.AUCTION_PARTNER_TIMEOUT]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT_AUCTION_PARTNER.TIMEOUT
        ? mergeListItem(
            context.timing,
            ['auctionPayloads', event.data.auction, 'partner_requests'],
            findMatchesName(event.data.partner),
            (existing: PartnerRequest | undefined) => ({
              name: event.data.partner,
              start_time: '',
              ...(existing ?? {}),
              end_time: event.data.time.dateString,
              status: RequestStatus.TIMEOUT,
            }),
          )
        : context.timing,
  }),
  [ACTIONS_RECORD.AUCTION_PARTNER_SUCCESS]: assign({
    timing: ({ context, event }) =>
      event.type === REPORT_AUCTION_PARTNER.SUCCESS
        ? mergeListItem(
            context.timing,
            ['auctionPayloads', event.data.auction, 'partner_requests'],
            findMatchesName(event.data.partner),
            (existing: PartnerRequest | undefined) => ({
              name: event.data.partner,
              start_time: '',
              ...(existing ?? {}),
              end_time: event.data.time.dateString,
              status: RequestStatus.SUCCESSFUL,
            }),
          )
        : context.timing,
  }),
  [ACTIONS_RECORD.INITIALISE_PAYLOAD]: assign({
    timing: ({ context }) => {
      const env = getEnv();
      return {
        ...context.timing,
        payload: {
          bordeaux: {
            version: process.env.npm_package_version || '',
            experiment_id: '',
          },
          hybrid_ab_test: [],
          browser: {
            type: context.pageParameters.browser,
            device_type: context.pageParameters.device,
            connection_type: env.navigator.connection?.effectiveType ?? '',
            cft_label_name: 'UNDECIDED',
          },
          user: {
            country: context.pageParameters.country,
            hybrid_id: null,
            unload_time: null,
          },
          cmp: {
            start_time: null,
            load_time: null,
            pending_time: null,
            fail_time: null,
            status: RequestStatus.UNSPECIFIED,
          },
          session: {
            url: env.location.href,
            id: generateSessionId(),
          },
          config: {
            start_time: null,
            end_time: null,
            status: RequestStatus.UNSPECIFIED,
          },
          third_parties: {
            start_time: null,
            end_time: null,
            scripts: [],
          },
        },
      };
    },
  }),
  [ACTIONS_RECORD.EXPERIMENT_ID]: assign({
    timing: ({ context, event }) =>
      event.type === API_EVENTS_OUT.SET_EXPERIMENT_ID
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              bordeaux: {
                ...context.timing.payload.bordeaux,
                experiment_id: event.data,
              },
            },
          }
        : context.timing,
  }),
  [ACTIONS_RECORD.HYBRID_ABTEST_TARGETING]: assign({
    timing: ({ context, event }) =>
      event.type === EVENTS.SET_HYBRID_ABTEST_TARGETING
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              hybrid_ab_test: event.data,
            },
          }
        : context.timing,
  }),
  [ACTIONS_RECORD.HYBRID_ID]: assign({
    timing: ({ context, event }) =>
      event.type === EVENTS.SET_HYBRID_ID
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              user: {
                ...context.timing.payload.user,
                hybrid_id: event.data,
              },
            },
          }
        : context.timing,
  }),
  [ACTIONS_RECORD.CFT_PARAMETERS]: assign({
    timing: ({ context, event }) =>
      event.type === EVENTS.SET_CFT_PARAMETERS
        ? {
            ...context.timing,
            payload: {
              ...context.timing.payload,
              browser: {
                ...context.timing.payload.browser,
                cft_label_name: event.data.labelName,
              },
            },
          }
        : context.timing,
  }),
};

export const reportActions = {
  [REPORT.MULTIPLE_SCRIPTS]: raise(
    (): ReportEvent<REPORT.MULTIPLE_SCRIPTS> => ({
      type: REPORT.MULTIPLE_SCRIPTS,
      data: { time: timeData() },
    }),
  ),
  [REPORT.FRAMEWORK_REQUEST]: raise(
    (): ReportEvent<REPORT.FRAMEWORK_REQUEST> => ({
      type: REPORT.FRAMEWORK_REQUEST,
      data: { time: timeData() },
    }),
  ),
  [REPORT.AD_BLOCKED]: raise(
    (): ReportEvent<REPORT.AD_BLOCKED> => ({
      type: REPORT.AD_BLOCKED,
      data: { time: timeData() },
    }),
  ),
  [REPORT.CONFIG_REQUEST]: raise(
    (): ReportEvent<REPORT.CONFIG_REQUEST> => ({
      type: REPORT.CONFIG_REQUEST,
      data: { time: timeData() },
    }),
  ),
  [REPORT.CONFIG_LOAD]: raise(
    (): ReportEvent<REPORT.CONFIG_LOAD> => ({
      type: REPORT.CONFIG_LOAD,
      data: { time: timeData() },
    }),
  ),
  [REPORT.CONFIG_EMPTY]: raise(
    (): ReportEvent<REPORT.CONFIG_EMPTY> => ({
      type: REPORT.CONFIG_EMPTY,
      data: { time: timeData() },
    }),
  ),
  [REPORT.CONFIG_PARSE_FAILURE]: raise(
    ({ event }): ReportEvent<REPORT.CONFIG_PARSE_FAILURE> => ({
      type: REPORT.CONFIG_PARSE_FAILURE,
      data: {
        time: timeData(),
        ...('error' in event ? { error: event.error as unknown as Error } : {}),
      },
    }),
  ),
  [REPORT.CONFIG_SUCCESS]: raise(
    (): ReportEvent<REPORT.CONFIG_SUCCESS> => ({
      type: REPORT.CONFIG_SUCCESS,
      data: { time: timeData() },
    }),
  ),

  [REPORT.THIRD_PARTY_REQUEST]: raise(
    (): ReportEvent<REPORT.THIRD_PARTY_REQUEST> => ({
      type: REPORT.THIRD_PARTY_REQUEST,
      data: { time: timeData() },
    }),
  ),
  [REPORT.THIRD_PARTY_SUCCESS]: raise(
    (): ReportEvent<REPORT.THIRD_PARTY_SUCCESS> => ({
      type: REPORT.THIRD_PARTY_SUCCESS,
      data: { time: timeData() },
    }),
  ),
  [REPORT.CONSENT_REQUEST]: raise(
    (): ReportEvent<REPORT.CONSENT_REQUEST> => ({
      type: REPORT.CONSENT_REQUEST,
      data: { time: timeData() },
    }),
  ),
  [REPORT.CONSENT_PENDING]: raise(
    (): ReportEvent<REPORT.CONSENT_PENDING> => ({
      type: REPORT.CONSENT_PENDING,
      data: { time: timeData() },
    }),
  ),
  [REPORT.CONSENT_FAILURE]: raise(
    (): ReportEvent<REPORT.CONSENT_FAILURE> => ({
      type: REPORT.CONSENT_FAILURE,
      data: { time: timeData() },
    }),
  ),
  [REPORT.CONSENT_SUCCESS]: raise(
    (): ReportEvent<REPORT.CONSENT_SUCCESS> => ({
      type: REPORT.CONSENT_SUCCESS,
      data: { time: timeData() },
    }),
  ),
  [REPORT.CONSENT_LOADED]: raise(
    (): ReportEvent<REPORT.CONSENT_LOADED> => ({
      type: REPORT.CONSENT_LOADED,
      data: { time: timeData() },
    }),
  ),
  [REPORT.CONSENT_MOCKED]: raise(
    (): ReportEvent<REPORT.CONSENT_MOCKED> => ({
      type: REPORT.CONSENT_MOCKED,
      data: { time: timeData() },
    }),
  ),
};

export const reportEvents = {
  [REPORT.MULTIPLE_SCRIPTS]: {
    actions: multipleScripts,
  },
  [REPORT.FRAMEWORK_REQUEST]: {
    actions: frameworkRequest,
  },
  [REPORT.AD_BLOCKED]: {
    actions: adBlocked,
  },
  [REPORT.CONTENT_LOAD]: {
    actions: contentLoad,
  },
  [REPORT.CONFIG_REQUEST]: {
    actions: [ACTIONS_RECORD.CONFIG_REQUEST, configCreate, configRequest],
  },
  [REPORT.CONFIG_FAILURE]: {
    actions: enqueueActions<ReportEvent<REPORT.CONFIG_FAILURE>>(({ check, enqueue }) => {
      if (check(GUARDS.ERROR_IS_TIMEOUT)) {
        enqueue(ACTIONS_RECORD.CONFIG_TIMEOUT);
        enqueue(configError);
        enqueue(configFailure);
      } else {
        enqueue(ACTIONS_RECORD.CONFIG_FAILURE);
        enqueue(configError);
        enqueue(configTimeout);
      }
    }),
  },
  [REPORT.CONFIG_LOAD]: {
    actions: configLoad,
  },
  [REPORT.CONFIG_EMPTY]: {
    actions: [ACTIONS_RECORD.CONFIG_FAILURE, configEmpty],
  },
  [REPORT.CONFIG_PARSE_FAILURE]: {
    actions: [ACTIONS_RECORD.CONFIG_FAILURE, configParseFailure],
  },
  [REPORT.CONFIG_SUCCESS]: {
    actions: [ACTIONS_RECORD.CONFIG_SUCCESS, configSuccess],
  },

  [REPORT.THIRD_PARTY_REQUEST]: {
    actions: [ACTIONS_RECORD.THIRD_PARTY_REQUEST, thirdPartyCreate, thirdPartyRequest],
  },
  [REPORT.THIRD_PARTY_SUCCESS]: {
    actions: [ACTIONS_RECORD.THIRD_PARTY_SUCCESS, thirdPartySuccess],
  },

  [REPORT_THIRD_PARTY_SCRIPT.REQUEST]: {
    actions: [
      ACTIONS_RECORD.THIRD_PARTY_SCRIPT_REQUEST,
      thirdPartyScriptCreate,
      thirdPartyScriptRequest,
    ],
  },
  [REPORT_THIRD_PARTY_SCRIPT.TIMEOUT]: {
    actions: [ACTIONS_RECORD.THIRD_PARTY_SCRIPT_TIMEOUT, thirdPartyScriptTimeout],
  },
  [REPORT_THIRD_PARTY_SCRIPT.SUCCESS]: {
    actions: [ACTIONS_RECORD.THIRD_PARTY_SCRIPT_SUCCESS, thirdPartyScriptSuccess],
  },
  [REPORT_THIRD_PARTY_SCRIPT.FAILURE]: {
    actions: [ACTIONS_RECORD.THIRD_PARTY_SCRIPT_FAILURE, thirdPartyScriptFailure],
  },

  [REPORT.CONSENT_REQUEST]: {
    actions: [consentRequest, ACTIONS_RECORD.CONSENT_REQUEST],
  },
  [REPORT.CONSENT_FAILURE]: {
    actions: consentFailure,
  },
  [REPORT.CONSENT_SUCCESS]: {
    actions: consentSuccess,
  },
  [REPORT.CONSENT_PENDING]: {
    actions: ACTIONS_RECORD.CONSENT_PENDING,
  },
  [REPORT.CONSENT_LOADED]: {
    actions: ACTIONS_RECORD.CONSENT_SUCCESS,
  },
  [REPORT.CONSENT_MOCKED]: {
    actions: ACTIONS_RECORD.CONSENT_FAILURE,
  },

  [REPORT_AUCTION.START]: {
    actions: [ACTIONS_RECORD.AUCTION_START, auctionCreate, auctionStart],
  },
  [REPORT_AUCTION.END]: {
    actions: [ACTIONS_RECORD.AUCTION_END, auctionEnd],
  },
  [REPORT_AUCTION.AD_LOAD]: {
    actions: [
      ACTIONS_RECORD.AUCTION_AD_LOAD,
      enqueueActions<AuctionReportEvent<REPORT_AUCTION.AD_LOAD>>(({ check, enqueue }) => {
        if (!check(GUARDS.TIMING_COLLECTION_ENABLED)) return;
        if (
          !check(({ context, event }) => context.timing.unsentAuctions.includes(event.data.auction))
        )
          return;

        enqueue(({ context, event }) => {
          sendBeacon(context.timing.endpoint, {
            ...context.timing.payload,
            auction: context.timing.auctionPayloads[event.data.auction],
          });
        });

        enqueue(
          assign<AuctionReportEvent<REPORT_AUCTION.AD_LOAD>>({
            timing: ({ context, event }) =>
              event.type === REPORT_AUCTION.AD_LOAD
                ? {
                    ...context.timing,
                    unsentAuctions: context.timing.unsentAuctions.filter(
                      auction => auction !== event.data.auction,
                    ),
                  }
                : context.timing,
          }),
        );
      }),
    ],
  },
  [REPORT_AUCTION_PARTNER.REQUEST]: {
    actions: [ACTIONS_RECORD.AUCTION_PARTNER_REQUEST, auctionPartnerCreate, auctionPartnerRequest],
  },
  [REPORT_AUCTION_PARTNER.SUCCESS]: {
    actions: [
      ACTIONS_RECORD.AUCTION_PARTNER_SUCCESS,
      auctionPartnerSuccess,
      sendPartnerBidsToFreyr,
    ],
  },
  [REPORT_AUCTION_PARTNER.TIMEOUT]: {
    actions: [ACTIONS_RECORD.AUCTION_PARTNER_TIMEOUT, auctionPartnerTimeout],
  },
};
