import log from 'log';
import { API_EVENTS_OUT } from './events.names';
import { ApiMachineEventService } from './events.service';

export const getPageTemplate = (service: ApiMachineEventService): Promise<undefined | string> =>
  service.getData<string | undefined>('pageTemplate');

export default (service: ApiMachineEventService, pageTemplate: string): void => {
  if (typeof pageTemplate !== 'string') {
    log.error(`Argument to setPageTemplate must be a string, ${typeof pageTemplate} given`);
    return;
  }

  service.sendEvent({
    type: API_EVENTS_OUT.SET_PAGE_TEMPLATE,
    data: pageTemplate.replace(/[^a-zA-Z0-9-_]/g, ''),
  });
};
