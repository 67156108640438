import { UserSyncElement } from 'user-sync/index.types';
import { getCacheBuster } from './utils';
import { UserSyncConfig } from '../../state/types/context.types';

const rubicon: UserSyncConfig = {
  id: '19',
  bidderName: 'rubicon',
  iabIdList: [52],
  element: UserSyncElement.IFRAME,
  url: 'https://secure-assets.rubiconproject.com/utils/xapi/multi-sync.html',
  params: {
    p: '11868',
    endpoint: 'us-east',
    r: getCacheBuster(),
  },
};
export default rubicon;
