import { exSeparator, normaliseTargetingArray } from 'ad-framework/targeting/adapt-config';
import { getEnv } from 'utils/env';

const env = getEnv();

const attachPageTargeting = (
  targeting: { [key: string]: string | Array<string> } | undefined,
): void => {
  const { googletag } = env;

  if (!targeting || !googletag) {
    return;
  }

  Object.entries(targeting).forEach(([key, value]) => {
    if (key === '_ex') {
      const uniqValues = [
        ...new Set([
          ...normaliseTargetingArray(googletag.pubads().getTargeting('_ex'), exSeparator),
          ...normaliseTargetingArray(value, exSeparator),
        ]),
      ];
      googletag.pubads().setTargeting(key, uniqValues);
    } else {
      googletag.pubads().setTargeting(key, value);
    }
  });
  googletag.pubads().setTargeting('_chnl', 'WEB');
};

export default attachPageTargeting;
