import { SommelierResponseAdUnit } from 'ad-framework/ad/sommelier-response.types';

export default {
  type: 'incremental',
  name: 'incr-leaderboard',
  sizes: [{ w: 728, h: 90 }],
  targeting: {
    pos: ['2'],
    placement: [`dfp_rs_desktop_leaderboard`],
  },
  properties: {
    refresh: 'true',
    centerVertically: 'true',
  },
} as SommelierResponseAdUnit;
