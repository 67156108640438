import { UserSyncElement } from 'user-sync/index.types';
import { getEnv } from 'utils/env';
import { getUserSyncURL } from './utils';
import { UserSyncConfig } from '../../state/types/context.types';

const id = '9';
const sonobi: UserSyncConfig = {
  element: UserSyncElement.IMAGE,
  bidderName: 'sonobi',
  id,
  iabIdList: [104],
  script: {
    src: 'https://sync.go.sonobi.com/uc.js',
  },
  condition: () => {
    const env = getEnv();
    if (env.gdprUser) {
      return false;
    }
    return true;
  },
  url: `https://purch-sync.go.sonobi.com/us?${getUserSyncURL({ id, uid: '[UID]' })}`,
};
export default sonobi;
