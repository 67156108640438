import { getEnv } from 'utils/env';

const env = getEnv();

const sendBeacon = (url: string, data: string | object): boolean => {
  const string: string = typeof data === 'string' ? data : JSON.stringify(data);
  const contentType = 'text/plain';
  return env.navigator.sendBeacon(url, new Blob([string], { type: contentType }));
};

export default sendBeacon;
