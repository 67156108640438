import log from 'log';
import { getEnv } from 'utils/env';

import {
  TCFAPIEventTypeMap,
  GDPRDataResponse,
  PingReturn,
  TCFAPI,
  NonIabVendorConsentsResponse,
} from '../index.types';
import { CMP_TIMEOUT, findAPI } from './utils';

const mockTCFAPI = (): TCFAPI => {
  return (command, version, callback): void => {
    const noConsent: GDPRDataResponse = {
      cmpId: 10,
      cmpVersion: 23,
      gdprApplies: true,
      tcfPolicyVersion: version,
      eventStatus: 'tcloaded',
      cmpStatus: 'loaded',
      tcString: '',
      isServiceSpecific: true,
      useNonStandardStacks: false,
      purposeOneTreatment: false,
      publisherCC: 'GB',
      outOfBand: { allowedVendors: {}, disclosedVendors: {} },
      purpose: { consents: {}, legitimateInterests: {} },
      vendor: { consents: {}, legitimateInterests: {} },
      specialFeatureOptins: {},
      publisher: {
        consents: {},
        legitimateInterests: {},
        customPurpose: { consents: {}, legitimateInterests: {} },
        restrictions: {},
      },
    };

    const ping: PingReturn = {
      cmpId: 10,
      cmpVersion: 23,
      apiVersion: '23',
      gdprApplies: true,
      tcfPolicyVersion: version,
      cmpLoaded: true,
      cmpStatus: 'loaded',
      displayStatus: 'hidden',
      mocked: true,
    };

    const noIABVendorConsent: NonIabVendorConsentsResponse = {
      gdprApplies: true,
      metadata: '',
      nonIabVendorConsents: {},
    };

    switch (command) {
      case 'getTCData':
        (callback as TCFAPIEventTypeMap['getTCData'])(noConsent, true);
        break;
      case 'ping':
        (callback as TCFAPIEventTypeMap['ping'])(ping);
        break;
      case 'addEventListener':
        (callback as TCFAPIEventTypeMap['addEventListener'])(noConsent, true);
        break;
      case 'removeEventListener':
        (callback as TCFAPIEventTypeMap['removeEventListener'])(true);
        break;
      case 'displayConsentUi':
        (callback as TCFAPIEventTypeMap['displayConsentUi'])();
        break;
      case 'getNonIABVendorConsents':
        (callback as TCFAPIEventTypeMap['getNonIABVendorConsents'])(noIABVendorConsent, true);
        break;
      default:
        log.error(`Unknown command for mocked __tcfapi: ${command}`);
    }
  };
};

export default async (): Promise<TCFAPI> => {
  const env = getEnv();
  const tcfapiPromise = new Promise<TCFAPI>((resolve, reject) => {
    const tcfapi = findAPI('__tcfapi');

    if (tcfapi === null) {
      reject(new Error('__tcfapi API does not exist in window frames'));
      return;
    }

    resolve(tcfapi);
  });

  const timeoutPromise = new Promise<TCFAPI>((_resolve, reject) => {
    env.setTimeout(
      () => reject(new Error(`Timeout retrieving __tcfapi API after ${CMP_TIMEOUT}ms`)),
      CMP_TIMEOUT,
    );
  });

  return Promise.race([tcfapiPromise, timeoutPromise]).catch(reason => {
    log.error(reason);

    return mockTCFAPI();
  });
};
