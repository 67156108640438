import { SiteConfig } from 'third-party-apis/config.types';

type DeepPartial<T> = Partial<{ [P in keyof T]: Partial<T[P]> }>;

export const mergeConfigs = (
  defaultConfig: SiteConfig,
  overrideConfig: DeepPartial<SiteConfig>,
): SiteConfig =>
  Object.entries(overrideConfig).reduce(
    (curr, [thirdParty, thirdPartyConfig]): SiteConfig => ({
      ...curr,
      [thirdParty]: {
        ...curr[thirdParty],
        ...thirdPartyConfig,
      },
    }),
    defaultConfig,
  );
