import { getEnv } from 'utils/env';

import { reportError } from '../../sentry';

const env = getEnv();

export default (message = 'Reported from the console'): void => {
  env.setTimeout(() => {
    const error: Error = new Error(message);
    reportError(error);
  });
};
