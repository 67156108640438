import { getEnv } from 'utils/env';
import { timeout, timeoutAdjustmentForPrebid } from 'third-party-apis/utils/timeout';
import {
  BidderParams,
  PreBidAdUnit,
  PrebidBidConfig,
  PrebidBidConfigList,
  PreBidBidderOptions,
} from 'third-party-apis/prebid/index.types';
import { ThirdParty } from 'third-party-apis/config.types';
import { DeviceOptions } from 'parameters/index.types';
import { FullAPISetupResults } from 'third-party-apis/index.types';
import { AdUnitSizeTuple } from 'ad-framework/ad/index.types';

export default async (
  device: DeviceOptions,
  siteConfig: FullAPISetupResults[ThirdParty.PREBID] | undefined,
  tag: string,
  playerSize: AdUnitSizeTuple = [640, 480],
  videoCode = 'video-0',
): Promise<string> => {
  if (siteConfig === undefined) return tag;
  if (!siteConfig.config.enabled) return tag;

  const { prebid } = getEnv();
  if (!prebid) return tag;

  const bids = ([] as Array<PreBidBidderOptions>).concat(
    ...Object.entries(siteConfig.config.video || {}).map(
      ([bidder, configList]: [string, PrebidBidConfigList<BidderParams>]) =>
        configList
          .filter(
            ({ sizes }: PrebidBidConfig) =>
              sizes === 'all' ||
              sizes.some(
                ([width, height]: AdUnitSizeTuple): boolean =>
                  playerSize[0] === width && playerSize[1] === height,
              ),
          )
          .map((bid: PrebidBidConfig) => ({
            bidder,
            params: {
              ...bid.params,
            },
          })),
    ),
  );

  const videoAdUnit: PreBidAdUnit = {
    code: videoCode,
    mediaTypes: {
      video: {
        pos: 3,
        playerSize: device === DeviceOptions.DESKTOP ? [640, 480] : [480, 270],
        context: 'instream',
        mimes: ['video/mp4', 'video/ogg', 'video/webm', 'application/javascript'],
        protocols: [3, 6, 7, 8],
        api: [2],
        startdelay: 0,
        linearity: 1,
        placement: 1,
        plcmt: 2,
        playbackmethod: [6],
        minduration: 3,
        maxduration: 30,
        w: 640,
        h: 460,
        skip: 0,
      },
    },
    bids,
  };

  prebid.addAdUnits(videoAdUnit);

  prebid.setConfig({
    cache: {
      url: 'https://prebid.adnxs.com/pbc/v1/cache',
    },
  });

  const timeoutForPrebid = timeout - timeoutAdjustmentForPrebid;
  await new Promise(resolve => {
    prebid.requestBids({
      timeout: timeoutForPrebid,
      adUnits: [videoAdUnit],
      bidsBackHandler: resolve,
    });
  });

  const newTag = prebid.adServers.dfp.buildVideoUrl({
    adUnit: videoAdUnit,
    url: tag,
  });
  return newTag;
};
