import { getEnv } from 'utils/env';
import findParam from 'utils/find-param';
import log from 'log';
import { BordeauxMachineContext } from 'state/types/context.types';

const env = getEnv();
const fallback = 'GB';

const getCookie = (): null | string => {
  try {
    return findParam('FTR_Country_Code', env.document.cookie, ';');
  } catch (error) {
    log.warn('Bordeaux country lookup failed, could not find FTR_Country_Code cookie', error);
    return null;
  }
};

export default (context: Pick<BordeauxMachineContext, 'queryParameters'>): string =>
  (context.queryParameters.country ?? getCookie() ?? fallback).toUpperCase();
