import log from 'log';
import fastdom from 'utils/fastdom';
import { getEnv } from 'utils/env';
import DataObject from 'state/data-object';
import { Ad } from '../index.types';

const createOutOfPageMarkup = (ad: DataObject<Ad>): Promise<void> =>
  fastdom
    .mutate(() => {
      const env = getEnv();

      const element = env.document.createElement('div');
      element.id = ad.getProperty('id');

      const innerElement = env.document.createElement('div');
      innerElement.style.display = 'none';
      innerElement.appendChild(element);

      env.document.body.appendChild(innerElement);
    })
    .catch(error => {
      if (error instanceof Error) {
        log.error(error.message);
      }
    });
export default createOutOfPageMarkup;
