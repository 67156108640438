import { Ad, AdUnitStatus } from 'ad-framework/ad/index.types';
import DataObject from 'state/data-object';
import { BordeauxMachineContext } from 'state/types/context.types';

const requestCompleted = (ad: DataObject<Ad>): boolean => {
  const adStatus = ad.getProperty('status');
  return (
    adStatus === AdUnitStatus.DELIVERED ||
    adStatus === AdUnitStatus.UNDELIVERED ||
    adStatus === AdUnitStatus.INVALID
  );
};
const extractLineItem = (ad: DataObject<Ad>): number => {
  const adGPTOutput = ad.getProperty('gptOutput');
  return adGPTOutput?.lineItem || -1;
};
const lineItemsMatch = (ads: Array<DataObject<Ad>>): boolean => {
  const lineItems = ads.map(extractLineItem);
  const firstLineItem = lineItems[0];
  if (firstLineItem === -1) {
    return false;
  }
  return lineItems.every((item: number): boolean => item === firstLineItem);
};

const getRoadblockStatus = (context: BordeauxMachineContext): boolean | null => {
  if (context.queryParameters.forceRoadblock !== null) {
    return context.queryParameters.forceRoadblock === 'true';
  }

  const { ads } = context;
  const roadblockMatchingAds = ads.getValues().filter(ad => ad.getProperty('inRoadblock'));
  if (roadblockMatchingAds.length === 0) {
    return false;
  }

  const allRequestsComplete = roadblockMatchingAds.every(requestCompleted);
  if (allRequestsComplete) {
    return lineItemsMatch(roadblockMatchingAds);
  }

  return null;
};

export default getRoadblockStatus;
