import { BordeauxMachineContext } from 'state/types/context.types';
import {
  ActorRef,
  NonReducibleUnknown,
  sendTo as genericSendTo,
  Snapshot,
  ParameterizedObject,
} from 'xstate';
import AnyBordeauxEvent from '../types/any-event.types';

const sendTo = <
  M extends ActorRef<Snapshot<unknown>, any>,
  E extends AnyBordeauxEvent = AnyBordeauxEvent,
>(
  ...args: Parameters<
    typeof genericSendTo<
      BordeauxMachineContext,
      E,
      NonReducibleUnknown,
      M,
      AnyBordeauxEvent,
      string
    >
  >
) =>
  genericSendTo<BordeauxMachineContext, E, ParameterizedObject['params'], M, AnyBordeauxEvent>(
    ...args,
  );

export default sendTo;
