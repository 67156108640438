import { CompanionBounds } from 'ad-framework/slot/index.types';
import { API_EVENTS_OUT } from './events.names';
import { ApiMachineEventService } from './events.service';

export default (service: ApiMachineEventService, newValue: CompanionBounds): void => {
  service.sendEvent({
    type: API_EVENTS_OUT.SET_COMPANION_BOUNDS,
    data: newValue,
  });
};
