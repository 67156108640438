import ActionArgs from 'state/proxy/action-args.types';
import SlotObserver from 'slotify/observer';
import log from 'log/index';
import fastdom from 'utils/fastdom';
import { PopOutAdFeatureEvent } from './events.types';
import { getFrames, onFrame } from './multi-frame';

const enabledSlots: Array<string> = [];

const popOut = ({ event: { data }, context }: ActionArgs<PopOutAdFeatureEvent>): void => {
  if (enabledSlots.includes(data.slotID)) return;

  const slot = context.slots
    .getValues()
    .find(searchSlot => searchSlot.getProperty('id') === data.slotID);
  if (!slot) {
    log.error(`Could not make ad pop out, slot with ID ${data.slotID} does not exist.`);
    return;
  }

  const ad = context.ads
    .getValues()
    .find(searchAd => searchAd.getProperty('id') === slot.getProperty('adID'));
  if (!ad) {
    log.error(`Could not make ad pop out, ad with ID ${slot.getProperty('adID')} does not exist.`);
    return;
  }

  if (data.expandedFrame && data.expandedHeight) {
    onFrame(data.creativeID, data.expandedFrame, frame => {
      const celtraContainer = frame.closest<HTMLElement>('.celtra-expanded-ad');
      fastdom.mutate(() => {
        frame.style.maxHeight = `${data.expandedHeight}px`;
        if (celtraContainer) {
          celtraContainer.style.maxHeight = `${data.expandedHeight}px`;
        }
      });
    });
  }
  enabledSlots.push(data.slotID);

  const slotName = slot.getProperty('name');
  const element = slot.getProperty('element');

  const bufferOffset = (data.edge === 'top' ? -slot.getProperty('height') : 0) - data.distance;

  const slotObserver = new SlotObserver(element, slotName, bufferOffset);

  slotObserver.observe(event => {
    if (event.type !== 'BUFFER') return;

    const iframes = Object.values(getFrames(data.creativeID));
    if (!iframes.length) {
      log.error(`Could not make ad pop out, ad iframe element not found.`);
      return;
    }
    iframes.forEach(iframeElement => {
      if (!iframeElement.contentWindow) {
        log.error(`Could not make ad pop out, ad iframe element has no content window.`);
        return;
      }
      iframeElement.contentWindow.postMessage(
        {
          type: 'bordeaux-ad-feature-response',
          bordeauxFeature: 'pop-out',
          out: !event.isIntersecting,
          creativeID: data.creativeID,
        },
        '*',
      );
    });
  });
};
export default popOut;
