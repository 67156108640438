import { BordeauxMachineContext } from 'state/types/context.types';
import { assign as genericAssign, NonReducibleUnknown } from 'xstate';
import AnyBordeauxEvent from '../types/any-event.types';

const assign = <R extends AnyBordeauxEvent>(
  ...args: Parameters<
    typeof genericAssign<BordeauxMachineContext, R, NonReducibleUnknown, AnyBordeauxEvent, any>
  >
) => genericAssign<BordeauxMachineContext, R, NonReducibleUnknown, AnyBordeauxEvent, any>(...args);

export default assign;

export const assignParams = <P = NonReducibleUnknown>(
  ...args: Parameters<
    typeof genericAssign<BordeauxMachineContext, AnyBordeauxEvent, P, AnyBordeauxEvent, any>
  >
) => genericAssign<BordeauxMachineContext, AnyBordeauxEvent, P, AnyBordeauxEvent, any>(...args);
