import { getEnv } from 'utils/env';
import getScriptLocation from 'utils/get-script-location';

const env = getEnv();
const bordeauxScript = env.document.currentScript as HTMLScriptElement;

const getBordeauxLocation = (): string => {
  if (bordeauxScript) {
    const srcURL = new URL(bordeauxScript.src);
    return getScriptLocation(srcURL);
  }

  return '';
};

export default getBordeauxLocation;
