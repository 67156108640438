import { getEnv } from 'utils/env';
import log from 'log';
import { ThirdParty } from 'third-party-apis/config.types';
import PartialPick from 'utils/partial-pick.types';
import { ThirdPartyAPIMachineConfigs } from 'state/types/context.types';

const config: PartialPick<ThirdPartyAPIMachineConfigs[ThirdParty.PREBID], 'thirdParty'> = {
  thirdParty: ThirdParty.PREBID,
  scriptLocation: './entry/index',
  methods: {
    loadScript: async (_scriptLocation, { data: { config }, bordeaux }): Promise<void> => {
      const { prebid, initialise } = await import(
        /* webpackChunkName: "[request]" */ './entry/index'
      );
      const bidderCodes = Object.keys(config.banner);
      const bidAdapters = await Promise.all(
        bidderCodes.map(bidderName =>
          import(/* webpackChunkName: "[request]" */ `prebid.js/modules/${bidderName}BidAdapter`)
            .then(value => ({
              input: bidderName,
              value,
              status: 'fulfilled',
            }))
            .catch(reason => ({
              input: bidderName,
              reason,
              status: 'rejected',
            })),
        ),
      );
      const brokenBidAdapters = bidAdapters.filter(({ status }) => status === 'rejected');
      if (brokenBidAdapters.length === bidAdapters.length) {
        // All bidders failed
        throw new Error(`None of the prebid bidders could be dynamically loaded`);
      }
      if (brokenBidAdapters.length !== 0) {
        // Some bidders failed
        log.warn(
          `Some of the prebid bidders failed to dynamically load: ${brokenBidAdapters
            .map(({ input }) => input)
            .join(', ')}`,
        );
      }

      initialise(bordeaux);
      const env = getEnv();
      env.prebid = prebid;
    },
  },
};
export default config;
