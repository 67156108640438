import { AdUnitCategory } from 'ad-framework/ad/index.types';
import { SlotPosition, SlotType } from 'ad-framework/slot/index.types';
import { SommelierResponseSlot } from 'ad-framework/slot/sommelier-response.types';

const createSponsoredSlots = (
  selector: string,
  name: string,
  position: SlotPosition,
): SommelierResponseSlot => ({
  name: `${name}`,
  hook: selector,
  type: SlotType.BLOCK,
  position,
  size: { w: 5, h: 5 },
  properties: {
    style: {
      fluid: 'true',
    },
    config: {
      ignoreExclusion: true,
      allowList: AdUnitCategory.SPONSORED_POST,
      multiple: true,
    },
  },
});

export const sponsoredPostSlots = createSponsoredSlots(
  '.sponsored-post',
  'sponsored',
  SlotPosition.PREPEND,
);

export const articleSponsoredPostSlots = createSponsoredSlots(
  '.article-sponsored-post',
  'article-sponsored',
  SlotPosition.APPEND,
);
