import { SommelierResponseAdUnit } from 'ad-framework/ad/sommelier-response.types';

export default {
  type: 'incremental',
  name: `incr-mpu`,
  sizes: [{ w: 300, h: 250 }],
  targeting: {
    pos: ['2'],
    placement: [`dfp_rs_tablet_mpu`],
    format: ['roadblock'],
  },
  properties: {
    refresh: 'true',
  },
} as SommelierResponseAdUnit;
