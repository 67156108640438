import { getEnv } from 'utils/env';
import fastdom from 'utils/fastdom';
import selector from 'utils/query-selector';
import log from 'log';
import { Ad } from 'ad-framework/ad/index.types';
import DataObject from 'state/data-object';

const createSkyscraperMarkup = (ad: DataObject<Ad>): Promise<void> =>
  fastdom
    .mutate(() => {
      const env = getEnv();
      const main = env.document.getElementById('main');
      if (!main) {
        throw new Error('Error in skyscraper setup, #main element not found');
      }
      if (!main.parentNode) {
        throw new Error('Error in skyscraper setup, #main element has no parent');
      }

      const skysPos = ad.getProperty('position');

      const element = env.document.createElement('div');
      element.classList.add(`skyscraper-gpt-${skysPos}`);
      const elementStyle = {
        width: '160px',
        height: '600px',
      };
      Object.assign(element.style, elementStyle);
      element.id = ad.getProperty('id');
      element.ariaHidden = 'true';

      const innerElement = env.document.createElement('div');
      innerElement.classList.add(`skyscraper-inner-${skysPos}`);
      const innerElementStyle = {
        width: '160px',
        height: '600px',
        pointerEvents: 'auto',
      };
      Object.assign(innerElement.style, innerElementStyle);
      innerElement.appendChild(element);

      const outerElement = env.document.createElement('div');
      outerElement.classList.add(`skyscraper-outer-${skysPos}`);
      const outerElementStyle = {
        width: '160px',
        height: '600px',
        position: 'absolute',
        top: '10px',
        ...(skysPos ? { [skysPos]: `${-(180 + 20)}px` } : {}),
        zIndex: 9995,
      };
      Object.assign(outerElement.style, outerElementStyle);
      outerElement.appendChild(innerElement);

      const { width: contentWidth } = main.getBoundingClientRect();
      const skyscraperContainer =
        selector<HTMLElement>('.skyscraper-container') || env.document.createElement('div');
      skyscraperContainer.classList.add('skyscraper-container');
      const skyscraperContainerStyle = {
        width: `${contentWidth}px`,
        height: 0,
        margin: `auto`,
        position: 'sticky',
        top: 0,
        'pointer-events': 'none',
      };
      Object.assign(skyscraperContainer.style, skyscraperContainerStyle);
      skyscraperContainer.appendChild(outerElement);

      main.parentNode.insertBefore(skyscraperContainer, main);
    })
    .catch(error => {
      if (error instanceof Error) {
        log.error(error.message);
      }
    });
export default createSkyscraperMarkup;
