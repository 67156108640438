import { UserSyncElement } from 'user-sync/index.types';
import { getGDPRData, getUserSyncURL } from './utils';
import { UserSyncConfig } from '../../state/types/context.types';

const id = '24';
const sovrn: UserSyncConfig = {
  element: UserSyncElement.IMAGE,
  bidderName: 'sovrn',
  id,
  iabIdList: [13],
  url: 'https://ap.lijit.com/pixel',
  params: {
    redir: getUserSyncURL({ id, uid: '$UID' }),
  },
  dynamicParams: context => {
    const gdprData = getGDPRData(context.gdprConsent);
    return {
      ...(gdprData
        ? {
            gdpr: '1',
            gdpr_consent: gdprData,
          }
        : {
            gdpr: '0',
            gdpr_consent: '',
          }),
    };
  },
};
export default sovrn;
