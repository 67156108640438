import isAnchoredHideable from 'ad-framework/ad/is-anchored-hidable';
import DataObjectStore from 'state/data-object-store';
import { Ad } from 'ad-framework/ad/index.types';
import { ApiMachineEventService } from './events.service';

export default async (service: ApiMachineEventService): Promise<boolean> => {
  const [anchoredLineItems, ads] = await Promise.all([
    service.getData<Array<number> | undefined>(
      'config',
      'features',
      'customActivations',
      'HIDEABLE_ANCHORED_ENABLED',
      'LINE_ITEM',
    ),
    service.getData<DataObjectStore<Ad>>('ads'),
  ]);
  return isAnchoredHideable(ads.getValues(), anchoredLineItems);
};
