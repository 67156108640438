import { getEnv } from 'utils/env';
import ActionArgs from 'state/proxy/action-args.types';

export default ({ context }: ActionArgs): void => {
  //   const env = getEnv();
  //   const script = env.document.createElement('script');
  //   script.src =
  //     context.adToolVersion === ''
  //       ? 'https://bordeaux.futurecdn.net/load-ad-tool.js'
  //       : `https://bdx.${context.adToolVersion}.public.rtb-qa-eks-euw1.futureplc.engineering/load-ad-tool.js`;
  //   script.async = true;
  //   script.id = 'hybrid-ad-tool';
  //   env.document.head.appendChild(script);
  // };

  const env = getEnv();
  const src =
    context.adToolVersion === ''
      ? 'https://bordeaux.futurecdn.net/ad-tool.js'
      : `https://bdx.${context.adToolVersion}.public.rtb-qa-eks-euw1.futureplc.engineering/ad-tool.js`;

  const frame = env.document.createElement('iframe');
  frame.addEventListener('load', () => {
    const script = document.createElement('script');
    script.src = src;
    frame.contentDocument?.body.appendChild(script);
  });

  frame.id = 'hybrid-ad-tool';
  frame.style.position = 'fixed';
  frame.style.top = '0px';
  frame.style.right = '0px';
  frame.style.height = '100%';
  frame.style.width = '100vw';
  frame.style.maxWidth = '600px';
  frame.style.zIndex = '1000000000';
  frame.style.border = 'none';
  frame.style.outline = 'none';
  frame.style.boxShadow = `
    0 1px 1px rgba(0,0,0,0.11),
    0 2px 2px rgba(0,0,0,0.11),
    0 4px 4px rgba(0,0,0,0.11),
    0 6px 8px rgba(0,0,0,0.11),
    0 8px 16px rgba(0,0,0,0.11)`;

  env.document.body.appendChild(frame);
};
