import {
  assign,
  setup,
  raise,
  sendParent,
  enqueueActions,
  DoneActorEvent,
  fromCallback,
  fromPromise,
} from 'xstate';
import { EVENTS } from 'state/types/events.names';

import {
  ACTIONS,
  AnyElementTapEvent,
  ELEMENT_TAP_EVENTS,
  GUARDS,
  STATES,
  TapMachineContext,
  ValidTouchEvent,
} from './index.types';

const machine = setup({
  types: {} as {
    context: TapMachineContext;
    events: AnyElementTapEvent;
    input: {
      element: Element;
      repetitions?: number;
      fingers?: number;
    };
  },
  actors: {
    passwordPrompt: fromPromise<
      boolean,
      {
        password: string;
      }
    >(async ({ input }): Promise<boolean> => {
      await new Promise(resolve => setTimeout(resolve, 10));
      const password = prompt('Tool');
      return password === input.password;
    }),
    touchListener: fromCallback<
      AnyElementTapEvent,
      {
        fingers: number;
        element: Element;
      }
    >(({ input, sendBack }) => {
      const listener = (event: Event) => {
        const touchEvent = event as TouchEvent;
        if (touchEvent.touches && touchEvent.touches.length === input.fingers) {
          sendBack({ type: ELEMENT_TAP_EVENTS.TOUCH, data: Date.now() } as ValidTouchEvent);
        }
      };
      input.element.addEventListener('touchstart', listener);
      return () => {
        input.element.removeEventListener('touchstart', listener);
      };
    }),
  },
  actions: {
    [ACTIONS.RESET_TAPS]: assign({
      taps: 1,
    }),
    [ACTIONS.INCREMENT_TAPS]: assign({
      taps: ({ context }) => context.taps + 1,
    }),
    [ACTIONS.RAISE_REPEATED_EVENT]: raise({
      type: ELEMENT_TAP_EVENTS.REPEATED,
    }),
    [ACTIONS.RECORD_TOUCH]: assign({
      lastTouch: ({ context, event }) =>
        event.type === ELEMENT_TAP_EVENTS.TOUCH ? event.data : context.lastTouch,
    }),
  },
  guards: {
    [GUARDS.EXCEEDED_REPETITIONS]: ({ context }): boolean => context.taps >= context.repetitions,
    [GUARDS.EVENT_WITHIN_TIMEOUT]: ({ context, event }): boolean =>
      event.type === ELEMENT_TAP_EVENTS.TOUCH && event.data - context.lastTouch < context.timeout,
  },
}).createMachine({
  initial: STATES.TOUCHING,
  context: ({ input }) => ({
    taps: 0,
    element: input.element,
    repetitions: input.repetitions || 1,
    fingers: input.fingers || 1,
    password: 'bordeaux',
    lastTouch: 0,
    timeout: 500,
  }),
  states: {
    [STATES.TOUCHING]: {
      invoke: {
        src: 'touchListener',
        input: ({ context }) => ({
          fingers: context.fingers,
          element: context.element,
        }),
      },
      on: {
        [ELEMENT_TAP_EVENTS.TOUCH]: {
          actions: [
            enqueueActions(({ enqueue, check }) => {
              if (check(GUARDS.EVENT_WITHIN_TIMEOUT)) enqueue(ACTIONS.INCREMENT_TAPS);
              else enqueue(ACTIONS.RESET_TAPS);

              enqueue(ACTIONS.RECORD_TOUCH);

              if (check(GUARDS.EXCEEDED_REPETITIONS)) enqueue(ACTIONS.RAISE_REPEATED_EVENT);
            }),
          ],
        },
        [ELEMENT_TAP_EVENTS.REPEATED]: STATES.CONFIRMING,
      },
    },
    [STATES.CONFIRMING]: {
      invoke: {
        src: 'passwordPrompt',
        input: ({ context }) => ({
          password: context.password,
        }),
        onDone: [
          {
            guard: ({ event }: { event: DoneActorEvent<boolean> }) => event.output,
            target: STATES.FINAL,
          },
          { target: STATES.TOUCHING },
        ],
      },
    },
    [STATES.FINAL]: {
      type: 'final',
      entry: sendParent({ type: EVENTS.OPEN_AD_TOOL }),
    },
  },
});
export default machine;
