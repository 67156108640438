import { ThirdParty } from 'third-party-apis/config.types';
import { PreBidResponseUnits } from 'third-party-apis/prebid/index.types';
import { TimeData } from 'utils/timestamp';
import { EventObject } from 'xstate';

export enum Metrics {
  CONFIG = 'CONFIG',
  AUCTIONS = 'AUCTIONS',
  THIRD_PARTY = 'THIRD_PARTY',
  THIRD_PARTY_SCRIPTS = 'THIRD_PARTY_SCRIPTS',
}
export type MetricsRefs = {
  [Metrics.CONFIG]?: number;
  [Metrics.THIRD_PARTY]?: number;
  [Metrics.THIRD_PARTY_SCRIPTS]: Partial<Record<ThirdParty, number>>;
  [Metrics.AUCTIONS]: Partial<
    Record<
      number,
      {
        mark: number;
        partners: Partial<Record<Partner, number>>;
      }
    >
  >;
};

export enum REPORT {
  MULTIPLE_SCRIPTS = 'MULTIPLE_SCRIPTS',
  FRAMEWORK_REQUEST = 'FRAMEWORK_REQUEST',
  AD_BLOCKED = 'AD_BLOCKED',
  CONTENT_LOAD = 'CONTENT_LOAD',

  CONFIG_REQUEST = 'REPORT_CONFIG_REQUEST',
  CONFIG_FAILURE = 'REPORT_CONFIG_FAILURE',
  CONFIG_LOAD = 'REPORT_CONFIG_LOAD',
  CONFIG_EMPTY = 'REPORT_CONFIG_EMPTY',
  CONFIG_PARSE_FAILURE = 'REPORT_CONFIG_PARSE_FAILURE',
  CONFIG_SUCCESS = 'REPORT_CONFIG_SUCCESS',
  // CONFIG_TIMEOUT = 'REPORT_CONFIG_TIMEOUT',

  THIRD_PARTY_REQUEST = 'REPORT_THIRD_PARTY_REQUEST',
  THIRD_PARTY_SUCCESS = 'REPORT_THIRD_PARTY_SUCCESS',

  CONSENT_REQUEST = 'REPORT_CONSENT_REQUEST',
  CONSENT_FAILURE = 'REPORT_CONSENT_FAILURE',
  CONSENT_SUCCESS = 'REPORT_CONSENT_SUCCESS',
  CONSENT_PENDING = 'REPORT_CONSENT_PENDING',
  CONSENT_LOADED = 'REPORT_CONSENT_LOADED',
  CONSENT_MOCKED = 'REPORT_CONSENT_MOCKED',
}
export enum REPORT_AUCTION {
  START = 'REPORT_AUCTION_START',
  END = 'REPORT_AUCTION_END',
  AD_LOAD = 'REPORT_AUCTION_AD_LOAD',
}
export enum REPORT_AUCTION_PARTNER {
  REQUEST = 'REPORT_AUCTION_PARTNER_REQUEST',
  SUCCESS = 'REPORT_AUCTION_PARTNER_SUCCESS',
  TIMEOUT = 'REPORT_AUCTION_PARTNER_TIMEOUT',
}
export enum REPORT_THIRD_PARTY_SCRIPT {
  REQUEST = 'REPORT_THIRD_PARTY_SCRIPT_REQUEST',
  TIMEOUT = 'REPORT_THIRD_PARTY_SCRIPT_TIMEOUT',
  SUCCESS = 'REPORT_THIRD_PARTY_SCRIPT_SUCCESS',
  FAILURE = 'REPORT_THIRD_PARTY_SCRIPT_FAILURE',
}
export type ALL_REPORT =
  | REPORT
  | REPORT_THIRD_PARTY_SCRIPT
  | REPORT_AUCTION
  | REPORT_AUCTION_PARTNER;

export enum Partner {
  IAS = 'ias',
  AD_SERVER = 'hybrid',
  AMAZON = 'amazon',
  PREBID = 'prebid',
}

export interface ReportEvent<T extends ALL_REPORT = ALL_REPORT> extends EventObject {
  type: T;
  data: {
    time: TimeData;
    error?: Error;
  };
}
export interface ThirdPartyReportEvent<
  T extends REPORT_THIRD_PARTY_SCRIPT = REPORT_THIRD_PARTY_SCRIPT,
  TP extends ThirdParty = ThirdParty,
> extends ReportEvent {
  type: T;
  data: {
    thirdParty: TP;
  } & ReportEvent['data'];
}
export interface AuctionReportEvent<T extends REPORT_AUCTION = REPORT_AUCTION> extends ReportEvent {
  type: T;
  data: {
    auction: number;
  } & ReportEvent['data'];
}
export interface AuctionReportStartEvent extends AuctionReportEvent<REPORT_AUCTION.START> {
  data: {
    adNames: Array<string>;
  } & AuctionReportEvent['data'];
}
export interface AuctionPartnerReportEvent<
  T extends REPORT_AUCTION_PARTNER = REPORT_AUCTION_PARTNER,
  P extends Partner = Partner,
> extends ReportEvent {
  type: T;
  data: {
    auction: number;
    partner: P;
    bids?: PreBidResponseUnits;
  } & ReportEvent['data'];
}

export type AnyNormalReportEvent =
  | ReportEvent<REPORT.MULTIPLE_SCRIPTS>
  | ReportEvent<REPORT.FRAMEWORK_REQUEST>
  | ReportEvent<REPORT.AD_BLOCKED>
  | ReportEvent<REPORT.CONTENT_LOAD>
  | ReportEvent<REPORT.CONFIG_REQUEST>
  | ReportEvent<REPORT.CONFIG_FAILURE>
  | ReportEvent<REPORT.CONFIG_LOAD>
  | ReportEvent<REPORT.CONFIG_EMPTY>
  | ReportEvent<REPORT.CONFIG_PARSE_FAILURE>
  | ReportEvent<REPORT.CONFIG_SUCCESS>
  | ReportEvent<REPORT.THIRD_PARTY_REQUEST>
  | ReportEvent<REPORT.THIRD_PARTY_SUCCESS>
  | ReportEvent<REPORT.CONSENT_REQUEST>
  | ReportEvent<REPORT.CONSENT_FAILURE>
  | ReportEvent<REPORT.CONSENT_SUCCESS>
  | ReportEvent<REPORT.CONSENT_PENDING>
  | ReportEvent<REPORT.CONSENT_LOADED>
  | ReportEvent<REPORT.CONSENT_MOCKED>;
export type AnyThirdPartyReportEvent =
  | ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.REQUEST>
  | ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.TIMEOUT>
  | ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.SUCCESS>
  | ThirdPartyReportEvent<REPORT_THIRD_PARTY_SCRIPT.FAILURE>;
export type AnyAuctionReportEvent =
  | AuctionReportStartEvent
  | AuctionReportEvent<REPORT_AUCTION.END>
  | AuctionReportEvent<REPORT_AUCTION.AD_LOAD>;
export type AnyAuctionPartnerReportEvent =
  | AuctionPartnerReportEvent<REPORT_AUCTION_PARTNER.REQUEST>
  | AuctionPartnerReportEvent<REPORT_AUCTION_PARTNER.SUCCESS>
  | AuctionPartnerReportEvent<REPORT_AUCTION_PARTNER.TIMEOUT>;
export type AnyReportEvent =
  | AnyNormalReportEvent
  | AnyThirdPartyReportEvent
  | AnyAuctionReportEvent
  | AnyAuctionPartnerReportEvent;
