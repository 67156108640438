import DataObject from 'state/data-object';
import PartialPick from 'utils/partial-pick.types';
import { Slot, SlotDefinition } from './index.types';
import createStyledDiv from './create-styled-div';

const slotStyles = {
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
};
const createSlotElement = (id: string): HTMLElement => {
  const element = createStyledDiv(slotStyles);
  element.id = id;
  element.classList.add('bordeaux-slot');
  return element;
};

const createSlot = (
  slotDefinition: SlotDefinition,
  slotOverrides: PartialPick<Slot, 'id' | 'hookElement'>,
): DataObject<Slot> => {
  const element = createSlotElement(slotOverrides.id);
  return new DataObject<Slot>({
    ...slotDefinition,
    genericName: slotDefinition.name,
    element,
    excluded: false,
    coordinates: { top: 0, bottom: 0, left: 0, right: 0 },
    ...slotOverrides,
  });
};
export default createSlot;
