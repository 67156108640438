import getCftParameters from 'ad-framework/targeting/cft-parameters';
import { BordeauxMachineContext } from 'state/types/context.types';
import { EVENTS } from 'state/types/events.names';
import getUserid from 'utils/get-userid';
import { AnyEventObject, fromCallback } from 'xstate';

const arbitraryEventEmitter = fromCallback<
  AnyEventObject,
  Pick<BordeauxMachineContext, 'pageParameters'>
>(({ sendBack, input: context }) => {
  getUserid(context).then(hybridId => {
    if (!hybridId) return;
    sendBack({
      type: EVENTS.SET_HYBRID_ID,
      data: hybridId,
    });
  });
  getCftParameters().then(cftParameters => {
    sendBack({
      type: EVENTS.SET_CFT_PARAMETERS,
      data: cftParameters,
    });
  });
});
export default arbitraryEventEmitter;
