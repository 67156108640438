import { addAllAdHighlights, addAdHighlight } from 'ad-framework/highlight/ads';
import { ApiMachineEventService } from 'api/events.service';
import { Ad } from 'ad-framework/ad/index.types';
import DataObjectStore from 'state/data-object-store';

export default async (service: ApiMachineEventService, names?: Array<string>): Promise<void> => {
  const ads = await service.getData<DataObjectStore<Ad>>('ads');
  if (names === undefined) {
    addAllAdHighlights(ads);
    return;
  }

  names.forEach(name => {
    addAdHighlight(ads, name);
  });
};
