import { timeData } from 'utils/timestamp';
import { API_EVENTS_OUT } from './events.names';
import { ApiMachineEventService } from './events.service';

export default (service: ApiMachineEventService): void => {
  service.sendEvent({
    type: API_EVENTS_OUT.INITIALISE,
    data: timeData(),
  });
};
